import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./admin-info.css";
import { useRecoilState } from "recoil";
import { userInfoState } from "@/recoil/userStore";
import useAxiosAuthFunction from "@/hooks/useAxiosAuth";

const UserInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosAuthFunction();

  const handleLogout = () => {
    setUserInfo({
      aToken: null,
      isLogin: false,
      userInfo: null,
    });
    navigate("/");
  };
  const gotoWrite = () => {
    navigate("/notice", {
      state: { editMode: "on", user_id: userInfo.userInfo },
      replace: true,
    });
  };

  let tokenTest = async () => {
    console.log("tokenTest > ");
    console.log("tokenTest > ", apiUrls.tokenCheck);
    let rsp = await axiosFetch({
      method: "POST",
      url: apiUrls.tokenCheck,
    });

    console.log("rsp > ", rsp);
  };

  return (
    <section id="admin">
      <div className="admin--wrapper">
        <div className="header">
          <h1>
            {location?.state?.message || `반갑습니다! ${userInfo.userInfo}님`}
          </h1>
          <button onClick={tokenTest}>토큰체크</button>
          <button className="logout-btn" onClick={handleLogout}>
            로그아웃
          </button>
        </div>
        <div className="body">
          <h2 className="gotoWrite" onClick={gotoWrite}>
            <span className="icon--container">
              <svg
                className="edit-icon"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </span>
            <span className="txt">공지사항 수정 및 작성하러 가기</span>
          </h2>
        </div>
      </div>
    </section>
  );
};

export default UserInfo;
