import React from "react";

const PageInfomation = ({ pageName, pageText, imgSource }) => {
  return (
    <div className="page-info">
      <div className="page-info-bg">
        <img src={imgSource} />
      </div>
      <div className="page-info-bg-opacity">
      </div>
      <div className="page-info-text">
        <p>
          {pageName}
        </p>
        <span>
          {pageText}
        </span>
      </div>
    </div>
  );
};

export default PageInfomation;
