export const apiUrl = {
  login: "admin/adminLogin",
  aTokenCheck: "token/aTokenCheck",
  rTokenCheck: "token/rTokenCheck",
  tokenCheck: "auth/tokenCheck",
  getNoticeLimit: "board/getNoticeLimit",
  getNoticeList: "board/getNoticeList",
  getNoticeDetail: (postId = 1) => `board/${postId}/noticeDetail`,
  deleteNotice: "board/deleteNotice",
  writeNotice: "board/writeNotice",
  updateNotice: "board/updateNotice",
  getHistory: "history/getHistory",
  saveWriteImg: "board/saveWriteImg",
};
