import React from "react";
import PageInfomation from "@/components/common/pageInfomation";
import QuickMenu from "@/components/common/quickMenu";
import "@/assets/css/operation.css";

function Operation() {
  return (
    <section id="sub">
      <PageInfomation
        pageName="재단소개"
        imgSource="../assets/images/intro/intro_bg.jpg"
      />

      <QuickMenu />

      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>운영 정관</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="operation">
          <div className="mobile_padding">
            {/* 제 1장 */}
            <div>
              <div className="general-rules">
                <span>제 1 장</span>
                <span style={{ marginLeft: "40px" }}>총칙</span>
              </div>
              <hr />
              <div className="general-article">제 1 조 (목 적)</div>
              <div className="article-content">
                이 법인은 공익법인의 설립운영에 관한 법률의 규정에 따라
                국가사회에 봉사할 수 있는 인재 양성과 학문의 창달에 기여하기
                위하여 학업성적이 우수하거나 특수한 재질이 있는 군산중.고등학교
                학생에게 장학금을 지급 하여 그 재능을 신장함을 목적으로 한다.
              </div>
              <div className="general-article">제 2 조 (명 칭)</div>
              <div className="article-content">
                이 법인은 재단법인 재경군산중고장학회라 한다.
              </div>
              <div className="general-article">제 3 조 (사무소의 소재지)</div>
              <div className="article-content">
                이 법인의 사무소의 소재지는 서울특별시 관악구 승방2길 2에 둔다.
              </div>
              <div className="general-article">제 4 조 (사 업)</div>
              <div className="article-content">
                (1) 이 법인은 제 1 조의 목적을 달성하기 위하여 다음의 목적사업을
                행한다.
                <br />
                &nbsp;&nbsp;1. 장학금의 지급
                <br />
                &nbsp;&nbsp;2. 학술연구비 지급
                <br />
                &nbsp;&nbsp;3. 재단의 목적달성에 필요한 사업
                <br />
                <br />
                (2) 제 1항 제 3 호의 수익사업을 경영하고자 하는 경우에는 미리
                감독관청의 승인을 받아야 한다.
                <br />
                <br />
                (3) 이 법인은 홈페이지를 통해 연간 기부금 모금액 및 활용실적을
                공개한다.
              </div>
              <div className="general-article">
                제 5 조 (법인공여 이익의 수혜자)
              </div>
              <div className="article-content">
                (1) 이 법인이 제 4 조 제 1 항 제 1 호, 제 2 호에 규정한
                목적사업을 수행 함에 있어서 그 수혜자에게 제공하는 이익은 이를
                무상으로 한다. 다만, 수혜자에게 그 댓가의 일부를 부담시킬 때에는
                미리 감독관청의 승인을 받아야 한다.
                <br />
                <br />
                (2) 이 법인의 목적사업 수행으로 인하여 제공되는 이익의 수혜자는
                군산중.고등학교 학생으로 한다.
              </div>
            </div>

            {/* 제 2장 */}
            <div>
              <div className="general-rules">제 2 장 재산과 회계</div>
              <hr />
              <div className="general-article">제 6 조 (재산의 구분)</div>
              <div className="article-content">
                (1) 이 법인의 재산은 기본재산과 보통재산으로 구분한다.
                <br />
                <br />
                (2) 다음 각호의 1 에 해당하는 재산은 이를 기본재산으로 하고
                기본재산 이외의 일체의 재산은 보통재산으로 한다.
                <br />
                &nbsp;&nbsp;1. 설립시 기본재산으로 출연한 재산
                <br />
                &nbsp;&nbsp;2. 기부에 의거하거나 기타 무상으로 취득한 재산, 다만
                기부목적에 비추어 기본재산으로 하기 곤란하여 감독청의 승인을
                얻은 것은 예외로 한다.
                <br />
                &nbsp;&nbsp;3. 보통재산중 이사회에서 기본재산으로 편입할 것을
                결의한 재산
                <br />
                &nbsp;&nbsp;4. 세계잉여금중 적립금
                <br />
                <br />
                (3) 이 법인의 기본재산은 다음과 같다.
                <br />
                &nbsp;&nbsp;1. 설립당시의 기본재산은 금이억원(200,000,000)이다.
                <br />
                &nbsp;&nbsp;2. 현재의 기본재산은 별지목록 1 와 같다.
                <br />
              </div>

              <div className="general-article">제 7 조 (재산의 단위)</div>
              <div className="article-content">
                (1) 기본재산을 매도. 증여, 임대, 교환하거나 담보에 제공하거나
                의무부담 또는 권리의 포기를 하고자 할 때는 이사회의 의결을 거쳐
                감독청의 허가를 받아야 한다.
                <br />
                <br />
                (2) 법인이 매수, 기부채납, 기타의 방법으로 재산을 취득할 때는
                지체없이 이를 법인의 재산으로 편입조치 하여야 한다.
                <br />
                <br />
                (3) 기본재산 및 보통재산의 유지, 보존, 기타관리(제 1 항 및 2
                항의 경우를 제외 한다)에 관하여는 이사회가 정하는 바에 의한다.
                <br />
                <br />
                (4) 기본재산의 목록이나 평가액에 변동이 있을 때에는 지체없이
                별지목록을 변경하여 정관변경 절차를 밟아야 한다.
              </div>

              <div className="general-article">제 8 조 (재산의 평가) </div>
              <div className="article-content">
                이 법인의 모든 재산의 평가는 취득당시의 시가에 의한다. 다만,
                재평가를 실시한 재산은 재평가액으로 한다.
              </div>

              <div className="general-article">
                제 9 조 (경비의 조달방법등){" "}
              </div>
              <div className="article-content">
                이 법인의 유지 및 운영에 필요한 경비는 기본재산의 과실, 사업수익
                및 기타의 수입으로 조달한다.
              </div>

              <div className="general-article">제 10 조 (회계의 구분)</div>
              <div className="article-content">
                (1) 이 법인의 회계는 목적사업 회계와 수익사업 회계로 구분한다.
                <br />
                <br />
                (2) 제 1 항의 경우에 법인세법의 규정에 의한 법인세 과세대상이
                되는 수익과 이에 대응하는 비용은 수익사업 회계로 계리하고 기타의
                수익과 비용은 목적사업회계로 계리한다.
                <br />
                <br />
                (3) 제 2 항의 경우에 목적사업회계와 수익사업회계로 구분하기
                곤란한 비용은 공동비용 배분에 관한 법령의 규정을 준용하여
                배분한다.
                <br />
                <br />
              </div>

              <div className="general-article">제 11 조 (회계원칙)</div>
              <div className="article-content">
                이 법인의 회계는 사업의 경영성과와 수지상태를 정확하게 파악하기
                위하여 모든 회계거래를 발생의 사실에 의하여 기업회계의 원칙에
                따라 처리한다.
              </div>

              <div className="general-article">제 12 조 (회계년도)</div>
              <div className="article-content">
                이 법인의 회계년도는 정부의 회계년도에 따른다.
              </div>

              <div className="general-article">
                제 13 조 (예산외의 채무부담등)
              </div>
              <div className="article-content">
                예산외의 채무부담 또는 채권의 포기는 이사회의 의결을 거쳐
                감독청의 허가를 받아야 한다. 다만, 당해회계년도 수입금으로
                상환할 수 없는 자금을 차입(이하&quot;장기 차입금&quot;이라
                한다.)하는 경우 차입하고자 하는 장기 차입금액이 기본재산
                총액에서 차입당시의 부채총액을 공제한 금액의 100분의 5에
                상당하는 금액 미만으로서 차입하고자 하는 금액을 포함한 장기
                차입금액의 총액이 100만원 미만인 때에는 그러하지 아니한다.
              </div>

              <div className="general-article">
                제 14 조 (임원의 보수제한등)
              </div>
              <div className="article-content">
                임원에 대하여는 보수를 지급하지 아니한다. 다만 실비의 보상은
                예외로 한다.
              </div>

              <div className="general-article">
                제 15 조 (임원등에 대한 자산 대여 금지)
              </div>
              <div className="article-content">
                (1) 이 법인의 재산은 이 법인과 다음 각호의 1 에 해당하는 관계가
                있는 자에 대하여서는 정당한 댓가없이 이를 대여하거나 사용할 수
                없다.
                <br />
                &nbsp;&nbsp;1. 이 법인 설립자
                <br />
                &nbsp;&nbsp;2. 이 법인의 임원
                <br />
                &nbsp;&nbsp;3. 제 1 호 및 제 2 호에 해당하는 자와 민법 제 777
                조의 규정에 의한 친족관계에 있는 자 또는 이에 해당하는 자가
                임원으로 있는 다른 법인
                <br />
                &nbsp;&nbsp;4. 이 법인과 재산상 긴밀한 관계가 있는 자<br />
                <br />
                (2) 제 1 항 각호의 규정에 해당되지 아니하는 자의 경우에도 법인의
                목적에 비추 어 정당한 사유가 없는한 정당한 댓가없이 대여하거나
                사용하게 할 수 없다.
              </div>
            </div>

            {/* 제 3장 */}
            <div>
              {/* 제 3장 */}
              <div className="general-rules">제 3 장 임원</div>
              <hr />
              <div className="general-article">
                제 16 조 (임원의 종류와 정수)
              </div>
              <div className="article-content">
                (1) 이 법인에 두는 임원의 종류와 정수는 다음과 같다.
                <br />
                &nbsp;&nbsp;1. 이 사 5 인<br />
                &nbsp;&nbsp;2. 감 사 2 인<br />
                <br />
                (2) 제 1 항 제 1 호의 이사에는 이사장, 상임이사를 포함한다.
                <br />
                <br />
                (3) 본 회의 운영에 대한 자문과 원활한 목적달성을 위하여 본
                이사회의 결의에 따라 약간명의 고문과 발전위원을 둘 수 있다.
              </div>

              <div className="general-article">제 17 조 (상임이사)</div>
              <div className="article-content">
                (1) 제 4 조에 규정한 사업을 전담하게 하기 위하여 상임이사 1 인을
                둘 수 있다.
                <br />
                <br />
                (2) 상임이사의 업무분장에 관하여는 이사장이 정한다.
              </div>

              <div className="general-article">제 18 조 (임원의 임기)</div>
              <div className="article-content">
                (1) 이사의 임기는 4년, 감사의 임기는 2년으로 하되 연임할 수
                있다.
                <br />
                <br />
                (2) 보선에 의하여 취임하는 임원의 임기는 전임 잔여 기간으로
                한다.
              </div>

              <div className="general-article">제 19 조 (임원의 선임방법)</div>
              <div className="article-content">
                (1) 이 법인의 임원은 이 법인의 목적사업을 수행하는데 기여한
                공헌도를 기준으 로 이사회에서 선임하여 감독청의 인가를 받아
                취임한다. <br />
                <br />
                (2) 임기 전의 임원의 해임은 이사회의 의결을 거쳐 감독청의 승인을
                받아야 한다.
                <br />
                <br />
                (3) 이사 또는 감사중에 결원이 생긴때에는 2개월 이내에 이를
                보선하여야 한다.
              </div>

              <div className="general-article">제 20 조 (임원선임의 제한)</div>
              <div className="article-content">
                (1) 이사회의 구성에 있어서 이사 상호간에 공익법인의 설립운영에
                관한 법률 시행령 제 12 조에 규정한 특수관계에 해당하는 이사의
                수는 제 16 조의 이사 정수의 5 분의 1 을 초과하지 못한다. <br />
                <br />
                (2) 감사는 감사 상호간 또는 이사와 제 1 항에 규정한 특수관계에
                해당하는 자가 아니어야 한다.
              </div>

              <div className="general-article">
                제 21 조 (이사장 및 이사의 직무)
              </div>
              <div className="article-content">
                (1) 이사장은 이 법인을 대표하고 법인의 업무를 통리한다.
                <br />
                <br />
                (2) 이사는 이사회에 출석하여 이 법인의 업무에 관한 사항을 심의
                의결하며 이사회 또는 이사장으로부터 위임받은 사항(상임이사에게
                위임한 사항을 제외한다)을 처리한다.
              </div>

              <div className="general-article">
                제 22 조 (이사장의 직무대행)
              </div>
              <div className="article-content">
                이사장의 유고시 또는 궐위되었을 때에는 이사회에서 선출된 이사가
                직무를 대행한다.
              </div>

              <div className="general-article">
                제 23 조 (감사의 직무 및 시기)
              </div>
              <div className="article-content">
                (1) 감사는 다음의 직무를 행한다.
                <br />
                &nbsp;&nbsp;1. 법인의 재산상황을 감사하는 일 <br />
                &nbsp;&nbsp;2. 이사회 운영과 그 업무에 관한 사항을 감사하는 일
                <br />
                &nbsp;&nbsp;3. 제 1 호 및 제 2 호의 감사결과 부정 또는 부당한
                점이 있음을 발견할 때에는 이사회에 그 시정을 요구하거나 감독청에
                보고하는 일<br />
                &nbsp;&nbsp;4. 제 3 호의 보고를 하기 위하여 필요할 때에는
                이사회의 소집을 요구하는 일<br />
                &nbsp;&nbsp;5. 이사회에 출석하여 의견을 전송하는 일<br />
                &nbsp;&nbsp;6. 이사회 회의록에 기명 날인하는 일<br />
                <br />
                (2) 감사는 정기감사와 수시감사로 구분하여 실시하되 정기감사는 년
                1 회 결산일 후에 실시하고 수시감사는 수시로 행한다.
              </div>
            </div>

            {/* 제 4장 */}
            <div>
              <div className="general-rules">제 4 장 이사회</div>
              <hr />
              <div className="general-article">제 24 조 (이사회의 기능)</div>
              <div className="article-content">
                이사회는 다음 사항을 심의하여 결정한다.
                <br />
                <br />
                (1) 이 법인의 예산, 결산, 차입금 및 자산의 취득처분과 관리에
                관한 사항
                <br />
                <br />
                (2) 정관의 개정에 관한 사항
                <br />
                <br />
                (3) 법인의 해산에 관한 사항 <br />
                <br />
                (4) 임원의 임명에 관하여 제 19 조에서 정한 사항
                <br />
                <br />
                (5) 사업에 관한 사항
                <br />
                <br />
                (6) 이 정관의 규정에 의하여 그 권한에 속하는 사항 <br />
                <br />
                (7) 기타 이 법인의 운영상 중요하다고 이사장이 부의하는 사항
                <br />
                <br />
              </div>

              <div className="general-article">제 25 조 (의결 정족수)</div>
              <div className="article-content">
                (1) 이사회는 과반수의 출석으로 개최한다.
                <br />
                <br />
                (2) 이사회의 의사는 본 정관에 특별한 규정이 있는 것을 제외하고는
                출석이사 과반수의 찬성으로 의결한다. 다만, 가.부 동수인 경우에는
                의장이 결정한다.
              </div>

              <div className="general-article">제 26 조 (의결제적 사유)</div>
              <div className="article-content">
                이사장 또는 이사가 다음 각 호의 해당하는 때에는 그 의결에
                참여하지 못한다.
                <br />
                <br />
                &nbsp;&nbsp;1. 임원의 취임 및 해임에 있어 자신에 관한 사항을
                의결할 때<br />
                &nbsp;&nbsp;2. 금전 및 재산의 수수를 수반하는 사항으로서 자신과
                법인의 이해가 상반될 때
              </div>

              <div className="general-article">제 27 조 (회 기) </div>
              <div className="article-content">
                이사회는 정기이사회를 매년 1 회, 2 월중에 개최하고 필요가 있을
                때에는 수시 이를 개최한다.
              </div>

              <div className="general-article">제 28 조 (이사회의 소집)</div>
              <div className="article-content">
                (1) 이사회는 이사장이 소집하고 그 의장이 된다.
                <br />
                <br />
                (2) 이사회를 소집하고자 할 때는 적어도 회의 7 일전에 회의 목적을
                통지하여야 한다. 다만, 이사 전원이 집회하고 또 그 전원이
                이사회의 소집을 요구할 때에는 그러하지 아니한다.
              </div>

              <div className="general-article">
                제 29 조 (이사회 소집의 특례)
              </div>
              <div className="article-content">
                (1) 이사장은 다음 각호의 1 에 해당하는 때에는 그 소집
                요구일로부터 20일이내에 이사회를 소집하여야 한다.
                <br />
                &nbsp;&nbsp;1. 재적이사 과반수로부터 회의의 목적을 제시하여
                소집을 요구할 때 <br />
                &nbsp;&nbsp;2. 제 23 조 제 4 호의 규정에 의하여 감사가 소집을
                요구할 때<br />
                <br />
                (2) 이사회 소집권자가 궐위되거나 또는 이를 기피함으로써 7 일
                이상 이사회 소집이 불가능 할 때에는 재적이사 과반수의 찬성으로
                감독청의 승인을 받아 이사회를 소집할 수 있다.
              </div>

              <div className="general-article">제 30 조 (서면 결의 금지) </div>
              <div className="article-content">
                이사회의 의사는 서면 결의에 의할 수 없다.
              </div>
            </div>

            {/* 제 5장 */}
            <div>
              <div className="general-rules">제 5 장 보칙</div>
              <hr />
              <div className="general-article">제 31 조 (법인 해산) </div>
              <div className="article-content">
                이 법인을 해산하고자 할 때에는 재적이사의 3 분의 2 이상의
                찬성으로 감독청의 허가를 얻어야 한다.
              </div>

              <div className="general-article">
                제 32 조 (잔여 재산의 귀속){" "}
              </div>
              <div className="article-content">
                이 법인을 해산하였을 때의 잔여 재산은 서울시 교육청에 귀속한다.
              </div>

              <div className="general-article">제 33 조 (시행 세칙) </div>
              <div className="article-content">
                이 정관의 시행에 관하여 필요한 사항은 이사회 의결을 거쳐 세칙
                으로 정한다.
              </div>

              <div className="general-article">
                제 34 조 (공고사항 및 방법){" "}
              </div>
              <div className="article-content">
                법령의 규정에 의한 사항과 다음 각호의 사항은 이를 전라북도내에서
                발행하는 일간신문에 공고하여 행한다.
                <br />
                <br />
                &nbsp;&nbsp;1. 법인의 명칭 및 사무소의 소재지 변경 <br />
                &nbsp;&nbsp;2. 이사회의 의결로 공고하기로 결정한 사항
                <br />
              </div>

              <div className="general-article">
                제 35 조 (설립 당초의 임원 및 임기){" "}
              </div>
              <div className="article-content">
                이 법인 설립당초의 임원 및 임기는 법인등기부등본에 기재된 내용과
                같다.
              </div>

              <div className="general-article">제 36 조 (존립시기) </div>
              <div className="article-content">
                이 법인은 다음의 사유가 발생할 때 까지 존립하고 해산한다.
                <br />
                <br />
                (1) 법인을 해산하고자 재적이사의 3 분의 2 이상의 찬성으로
                감독청의 허가를 얻을시
                <br />
                <br />
                (2) 이 법인의 기본재산이 소멸되어 설립목적을 수행할 수 없을시
                <br />
                <br />
                (3) 법인이 파산되거나 감독관청으로 부터 설립허가가 취소된 때
                <br />
                <br />
              </div>
            </div>

            {/* 제 6장 */}
            <div>
              <div className="general-rules">제 6 장 부칙</div>
              <hr />
              <div className="general-article">제 1 조 </div>
              <div className="article-content">
                이 정관은 감독청의 인가를 받은 날로부터 시행한다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Operation;
