import React from "react";
import PageInfomation from "@/components/common/pageInfomation";
import QuickMenu from "@/components/common/quickMenu";
import "@/assets/css/chairman.css";

function Chairman() {
  return (
    <section id="sub">
      <PageInfomation
        pageName="역대 이사장"
        imgSource="../assets/images/intro/intro_bg.jpg"
      />

      <QuickMenu />

      <div className="golfgogo">
        <div className="chairmanP">
          <div className="chairmanP-text">
            재단법인 법사랑군산익산지역 장학회 역대 이사장입니다.
          </div>

          <div className="chairmanP-box">
            <ul>
              <li>1대 임윤택(24회)</li>
              <li>2대 강현욱(29회)</li>
              <li>3대 박영준(32회)</li>
              <li>4대 강현욱(29회)</li>
              <li>5대 문정일(36회)</li>
              <li>6대 문준영(34회)</li>
              <li>7대 강임준(45회) 현재</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Chairman;
