import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoState } from "@/recoil/userStore";
import Select from "react-select";

const Footer = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: "https://school.jbedu.kr/gunsan", label: "군산중학교홈페이지" },
    { value: "https://school.jbedu.kr/kunsan", label: "군산고등학교홈페이지" },
    {
      value: "https://www.sen.go.kr/sen/index.do",
      label: "서울시교육청홈페이지",
    },
  ];

  const selectChange = (data) => {
    window.open(data.value, "_blank");
    setSelectedOption(null);
  };

  const handleLogOut = () => {
    setUserInfo({
      aToken: null,
      isLogin: false,
      userInfo: null,
    });
    localStorage.removeItem("rToken");
    navigate("/");
  };
  const LoginBtn = () => {
    if (userInfo.isLogin)
      return (
        <li>
          <Link to="/" onClick={handleLogOut}>
            LOG OUT
          </Link>
        </li>
      );
    else
      return (
        <li>
          <Link to="/login">ADMIN</Link>
        </li>
      );
  };
  return (
    <footer id="footer">
      <div className="inner">
        <div className="topLine">
          <div className="footLogo">재단법인재경군산중고장학회</div>
          <div className="footer-family">
            <h2 hidden>family site</h2>
            <Select
              onChange={selectChange}
              options={options}
              placeholder="패밀리사이트 바로가기"
              value={selectedOption}
            />
          </div>
        </div>

        <ul className="footMenu">
          <li>
            <Link to="/intro">재단소개</Link>
          </li>
          <li>
            <Link to="/history">연혁 및 활동내역</Link>
          </li>
          <li>
            <Link to="/sponsor">후원안내</Link>
          </li>
          <li>
            <Link to="/notice">공지사항</Link>
          </li>
          <LoginBtn />
          {/*<li> <Link to="/login">LOG IN</Link> </li>*/}
        </ul>

        <div className="copy">
          재단법인 : 재경군산중고장학회
          <br />
          대표전화 : 02-598-0814 E-mail : wyk01@naver.com
          <br />
          주소 : 서울시 관악구 승방2길2, 5층(남현동,한일빌딩)
          <br />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
