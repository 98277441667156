import React, { useEffect } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import router from "./route/router";
import Layout from "@/components/layout";

const Routing = () => {
  let routes = useRoutes(router);
  return routes;
};

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout>
        <Routing />
      </Layout>
    </Router>
  );
};

export default App;
