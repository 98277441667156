import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import PageInfomation from "@/components/common/pageInfomation";
import NoticeList from "@/pages/notice/noticeList";
import "./notice.css";

function Notice() {
  const location = useLocation();

  return (
    <section id="sub">
      <PageInfomation
        pageName="공지사항"
        pageText=""
        imgSource="../assets/images/notice/notice_bg.jpg"
      />

      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>공지사항</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="bbCont">
        <div className="boardListArea">
          {location.pathname.includes("detail") ? (
            <Outlet />
          ) : location.pathname.includes("write") ? (
            <Outlet />
          ) : location.pathname.includes("edit") ? (
            <Outlet />
          ) : (
            <NoticeList />
          )}
        </div>
      </div>
    </section>
  );
}

export default Notice;
