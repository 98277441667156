import React from "react";
import { Link } from "react-router-dom";
import Slick from "react-slick";
import Layout from "../../components/layout";
import { subJs } from "../../assets/js/common";
import PageInfomation from "../../components/common/pageInfomation";
import PageInnerTitle from "@/components/common/pageInnerTitle";
import "@/assets/css/guide.css";

function Guide() {
  return (
    <section id="sub">
      <PageInfomation
        pageName="후원 안내"
        pageText=""
        imgSource="../assets/images/sponsor/sponsor_bg.jpg"
      />
      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>후원 안내</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="sponsor">
          <div className="sponsor-box">
            <div className="sponsor-img">
              <img src="../assets/images/guide/donation.png" />
            </div>

            <div className="sponsor-text">
              <p>
                <span className="sponsor-text-span">
                  재단의 장학사업은 지역의 올바른 가치관과 우수한 실력을 갖춘
                  인재들을 발굴 육성함으로서 국가와 지역사회 발전에 기여할 수
                  있는 학생들을 지원합니다.
                  <br />
                  <br />
                  국가의 동량으로 성장 할 수 있는 양질의 밑거름이 될 수 있도록
                  고귀한 정성과 따듯한 성원 부탁드립니다.
                </span>
              </p>
            </div>
          </div>

          <div className="sponsor-box-sec clear-both">
            <div className="sponsor-cont-bfir">
              <div className="sponsor-tel">
                <span className="title">장학금의 용도</span>
              </div>
              <p>
                *장학재단에 납부하는 기부금은 법률에 의해 ‘장학사업’으로만
                사용됩니다.
              </p>

              <div className="purpose-box">
                <div className="purpose-element">
                  <img src="../assets/images/guide/el_01.png" />
                  <p>
                    인재육성장학사업
                    <br />
                    (성적 및 우수학생 장학금 지급)
                  </p>
                </div>
                <div className="purpose-element el-margin-top">
                  <img src="../assets/images/guide/el_02.png" />
                  <p>
                    관내 학업 우수자(재학생), 예체능 특기자, 효행자 및 저소득층
                    가정의 학업 우수학생 장학금 지급
                  </p>
                </div>
                <div className="purpose-element el-margin-top">
                  <img src="../assets/images/guide/el_03.png" />
                  <p>
                    양질의 교육환경 제공을 위한 <br />
                    우수교사의 연구활동 지원
                  </p>
                </div>
              </div>
              {/* <ul className="sponsor_purpose">
                  <li>
                    관내 학업 우수자(재학생), 예체능 특기자, 효행자 및 저소득층
                    가정의 학업 우수학생 장학금 지급
                  </li>
                  <li>양질의 교육환경 제공을 위한 우수교사의 연구활동 지원</li>
                </ul> */}
            </div>

            {/* <div className="sponsor-cont-bsec">
                <div className="subj">○ 장학금의 용도</div>
                  <br />
                  
                  <br /> 
                  <br /> 
                  <br /> 
              </div> */}
          </div>

          <div className="sponsor-box-thir">
            <div className="sponsor-tel">
              {/* <img src="../assets/images/direction/direction_tel_b.png"/> */}
              <span className="title">후원문의</span>
            </div>
            <div className="sub">
              <span>장학재단 : 02-598-0814</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Guide;
