const SponsorContent = [
  {
    year: "1993",
    year_total: "153,880,000",
    person: "129,200,000",
    head_count: "20",
    alumni: "24,680,000",
    alumni_count: "19",
    total: "153,880,000",
  },
  {
    year: "1994",
    year_total: "20,810,000",
    person: "20,810,000",
    head_count: "8",
    alumni: "",
    alumni_count: "",
    total: "174,690,000",
  },
  {
    year: "1995",
    year_total: "39,800,000",
    person: "39,800,000",
    head_count: "21",
    alumni: "",
    alumni_count: "",
    total: "214,490,000",
  },
  {
    year: "1996",
    year_total: "30,010,000",
    person: "30,010,000",
    head_count: "4",
    alumni: "",
    alumni_count: "",
    total: "244,500,000",
  },
  {
    year: "1997",
    year_total: "5,010,000",
    person: "5,010,000",
    head_count: "5",
    alumni: "",
    alumni_count: "",
    total: "249,510,000",
  },
  {
    year: "1998",
    year_total: "37,780,000",
    person: "33,220,000",
    head_count: "6",
    alumni: "4,560,000",
    alumni_count: "35",
    total: "287,290,000",
  },
  {
    year: "1999",
    year_total: "59,850,000",
    person: "57,900,000",
    head_count: "21",
    alumni: "1,950,000",
    alumni_count: "25",
    total: "347,140,000",
  },
  {
    year: "2000",
    year_total: "14,020,000",
    person: "13,260,000",
    head_count: "18",
    alumni: "760,000",
    alumni_count: "17",
    total: "361,160,000",
  },
  {
    year: "2001",
    year_total: "24,500,000",
    person: "23,850,000",
    head_count: "27",
    alumni: "650,000",
    alumni_count: "6",
    total: "385,660,000",
  },
  {
    year: "2002",
    year_total: "25,610,000",
    person: "25,350,000",
    head_count: "26",
    alumni: "260,000",
    alumni_count: "9",
    total: "411,270,000",
  },
  {
    year: "2003",
    year_total: "12,760,000",
    person: "12,660,000",
    head_count: "26",
    alumni: "100,000",
    alumni_count: "4",
    total: "424,030,000",
  },
  {
    year: "2004",
    year_total: "14,350,000",
    person: "14,190,000",
    head_count: "17",
    alumni: "160,000",
    alumni_count: "5",
    total: "438,380,000",
  },
  {
    year: "2005",
    year_total: "31,010,000",
    person: "30,880,000",
    head_count: "21",
    alumni: "130,000",
    alumni_count: "5",
    total: "469,390,000",
  },
  {
    year: "2006",
    year_total: "31,470,888",
    person: "20,850,000",
    head_count: "23",
    alumni: "10,620,888",
    alumni_count: "8",
    total: "500,860,888",
  },
  {
    year: "2007",
    year_total: "23,320,000",
    person: "17,250,000",
    head_count: "15",
    alumni: "6,070,000",
    alumni_count: "4",
    total: "524,180,888",
  },
  {
    year: "2008",
    year_total: "31,810,000",
    person: "23,700,000",
    head_count: "19",
    alumni: "8,110,000",
    alumni_count: "6",
    total: "555,990,888",
  },
  {
    year: "2009",
    year_total: "36,090,000",
    person: "30,890,000",
    head_count: "25",
    alumni: "5,200,000",
    alumni_count: "5",
    total: "592,080,888",
  },
  {
    year: "2010",
    year_total: "39,050,000",
    person: "34,050,000",
    head_count: "33",
    alumni: "5,000,000",
    alumni_count: "1",
    total: "631,130,888",
  },
  {
    year: "2011",
    year_total: "49,220,000",
    person: "41,190,000",
    head_count: "30",
    alumni: "8,030,000",
    alumni_count: "3",
    total: "680,350,888",
  },
  {
    year: "2012",
    year_total: "140,890,000",
    person: "133,850,000",
    head_count: "30",
    alumni: "7,040,000",
    alumni_count: "3",
    total: "821,240,888",
  },
  {
    year: "2013",
    year_total: "44,000,000",
    person: "39,000,000",
    head_count: "20",
    alumni: "5,000,000",
    alumni_count: "3",
    total: "865,240,888",
  },
  {
    year: "2014",
    year_total: "40,070,000",
    person: "33,750,000",
    head_count: "15",
    alumni: "6,320,000",
    alumni_count: "5",
    total: "905,310,888",
  },
  {
    year: "2015",
    year_total: "47,300,000",
    person: "37,300,000",
    head_count: "17",
    alumni: "10,000,000",
    alumni_count: "2",
    total: "952,610,888",
  },
  {
    year: "2016",
    year_total: "39,570,000",
    person: "34,470,000",
    head_count: "16",
    alumni: "5,100,000",
    alumni_count: "2",
    total: "992,180,888",
  },
  {
    year: "2017",
    year_total: "42,450,000",
    person: "37,450,000",
    head_count: "18",
    alumni: "5,000,000",
    alumni_count: "1",
    total: "1,034,630,888",
  },
  {
    year: "2018",
    year_total: "50,760,000",
    person: "42,730,000",
    head_count: "16",
    alumni: "8,030,000",
    alumni_count: "3",
    total: "1,085,390,888",
  },
  {
    year: "2019",
    year_total: "131,830,000",
    person: "126,800,000",
    head_count: "13",
    alumni: "5,030,000",
    alumni_count: "2",
    total: "1,217,220,888",
  },
  {
    year: "2020",
    year_total: "33,230,000",
    person: "28,230,000",
    head_count: "18",
    alumni: "5,000,000",
    alumni_count: "1",
    total: "1,250,450,888",
  },
  {
    year: "2021",
    year_total: "27,060,000",
    person: "27,000,000",
    head_count: "11",
    alumni: "60,000",
    alumni_count: "2",
    total: "1,277,510,888",
  },
  {
    year: "2022",
    year_total: "46,730,000",
    person: "36,730,000",
    head_count: "15",
    alumni: "10,000,000",
    alumni_count: "1",
    total: "1,324,240,888",
  },
];

export default SponsorContent;
