const DonationContent = [
  {
    year: "1994",
    high_school: "26",
    middle_schoole: "",
    head_count: "26",
    donation: "5,000,000",
  },
  {
    year: "1995",
    high_school: "169",
    middle_schoole: "",
    head_count: "169",
    donation: "19,947,650",
  },
  {
    year: "1996",
    high_school: "183",
    middle_schoole: "",
    head_count: "183",
    donation: "21,408,000",
  },
  {
    year: "1997",
    high_school: "184",
    middle_schoole: "",
    head_count: "184",
    donation: "22,943,700",
  },
  {
    year: "1998",
    high_school: "167",
    middle_schoole: "",
    head_count: "167",
    donation: "21,806,850",
  },
  {
    year: "1999",
    high_school: "154",
    middle_schoole: "",
    head_count: "154",
    donation: "21,283,200",
  },
  {
    year: "2000",
    high_school: "120",
    middle_schoole: "",
    head_count: "120",
    donation: "21,330,000",
  },
  {
    year: "2001",
    high_school: "98",
    middle_schoole: "",
    head_count: "98",
    donation: "18,236,700",
  },
  {
    year: "2002",
    high_school: "40",
    middle_schoole: "44",
    head_count: "84",
    donation: "18,076,000",
  },
  {
    year: "2003",
    high_school: "40",
    middle_schoole: "40",
    head_count: "80",
    donation: "18,744,000",
  },
  {
    year: "2004",
    high_school: "40",
    middle_schoole: "48",
    head_count: "88",
    donation: "16,000,000",
  },
  {
    year: "2005",
    high_school: "40",
    middle_schoole: "80",
    head_count: "120",
    donation: "16,000,000",
  },
  {
    year: "2006",
    high_school: "40",
    middle_schoole: "88",
    head_count: "128",
    donation: "16,000,000",
  },
  {
    year: "2007",
    high_school: "12",
    middle_schoole: "88",
    head_count: "100",
    donation: "16,800,000",
  },
  {
    year: "2008",
    high_school: "12",
    middle_schoole: "88",
    head_count: "100",
    donation: "16,800,000",
  },
  {
    year: "2009",
    high_school: "12",
    middle_schoole: "80",
    head_count: "92",
    donation: "15,400,000",
  },
  {
    year: "2010",
    high_school: "11",
    middle_schoole: "80",
    head_count: "91",
    donation: "14,000,000",
  },
  {
    year: "2011",
    high_school: "12",
    middle_schoole: "40",
    head_count: "52",
    donation: "14,000,000",
  },
  {
    year: "2012",
    high_school: "12",
    middle_schoole: "40",
    head_count: "52",
    donation: "14,000,000",
  },
  {
    year: "2013",
    high_school: "12",
    middle_schoole: "40",
    head_count: "52",
    donation: "14,000,000",
  },
  {
    year: "2014",
    high_school: "12",
    middle_schoole: "40",
    head_count: "52",
    donation: "14,000,000",
  },
  {
    year: "2015",
    high_school: "24",
    middle_schoole: "40",
    head_count: "64",
    donation: "14,000,000",
  },
  {
    year: "2016",
    high_school: "24",
    middle_schoole: "40",
    head_count: "64",
    donation: "14,000,000",
  },
  {
    year: "2017",
    high_school: "20",
    middle_schoole: "30",
    head_count: "50",
    donation: "10,000,000",
  },
  {
    year: "2018",
    high_school: "28",
    middle_schoole: "30",
    head_count: "58",
    donation: "10,000,000",
  },
  {
    year: "2019",
    high_school: "33",
    middle_schoole: "60",
    head_count: "93",
    donation: "16,000,000",
  },
  {
    year: "2020",
    high_school: "40",
    middle_schoole: "20",
    head_count: "60",
    donation: "12,000,000",
  },
  {
    year: "2021",
    high_school: "40",
    middle_schoole: "20",
    head_count: "60",
    donation: "12,000,000",
  },
  {
    year: "2022",
    high_school: "40",
    middle_schoole: "20",
    head_count: "60",
    donation: "14,000,000",
  },
];

const HighSchoolTotal = () => {
  let high_total = 0;
  DonationContent.forEach((item) => {
    if (item.high_school != "") {
      high_total = high_total + parseInt(item.high_school);
    }
  });

  high_total = high_total.toLocaleString();

  return high_total;
};

const MiddleSchoolTotal = () => {
  let middle_total = 0;
  DonationContent.forEach((item) => {
    if (item.middle_schoole != "") {
      middle_total = middle_total + parseInt(item.middle_schoole);
    }
  });

  middle_total = middle_total.toLocaleString();

  return middle_total;
};

const HeadTotal = () => {
  let head_total = 0;
  DonationContent.forEach((item) => {
    if (item.head_count != "") {
      head_total = head_total + parseInt(item.head_count);
    }
  });

  head_total = head_total.toLocaleString();

  return head_total;
};

const DonationTotal = () => {
  let donation_total = 0;
  DonationContent.forEach((item) => {
    if (item.donation != "") {
      donation_total =
        donation_total + parseInt(item.donation.replace(/,/gi, ""));
    }
  });

  donation_total = donation_total.toLocaleString();

  return donation_total;
};

export {
  DonationContent,
  HighSchoolTotal,
  MiddleSchoolTotal,
  HeadTotal,
  DonationTotal,
};
