import React from "react";
import { Link } from "react-router-dom";

const MainPackageBanner = () => {
  return (
    <div className="package">
      <div className="inner">
        {/* <div className="mainTitle">재단소개</div> */}
        <ul className="packageList">
          <li>
            <Link to="/intro">
              <div className="thum">
                <img src="assets/images/main/2st.png" alt="" />
              </div>
              <div className="textArea">
                <div className="subj">이사장인사말</div>
                <div className="subs">재경군산중고장학회를 소개합니다.</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/member">
              <div className="thum">
                <img src="assets/images/main/1st.png" alt="" />
              </div>
              <div className="textArea">
                <div className="subj">임원진소개</div>
                <div className="subs">임원진을 소개합니다.</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/notice">
              <div className="thum">
                <img src="assets/images/main/3st.png" alt="" />
              </div>
              <div className="textArea">
                <div className="subj">공지사항</div>
                <div className="subs">재경군산중고장학회에서 알립니다.</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/direction">
              <div className="thum">
                <img src="assets/images/main/4st.png" alt="" />
              </div>
              <div className="textArea">
                <div className="subj">오시는길</div>
                <div className="subs">오시는길을 안내합니다.</div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MainPackageBanner;
