import React, { useEffect } from "react";
import PageInfomation from "@/components/common/pageInfomation";
import QuickMenu from "@/components/common/quickMenu";
import KakaoMapScript from "@/api/kakaoMap";
import "@/assets/css/direction.css";

function Direction() {
  useEffect(() => {
    KakaoMapScript();
  }, []);

  const mapStyle = {
    width: "100%",
    height: "500px",
  };

  return (
    <section id="sub">
      <PageInfomation
        pageName="재단소개"
        imgSource="../assets/images/intro/intro_bg.jpg"
      />
      <QuickMenu />

      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>오시는길</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="direction">
          <div className="direction-box">
            <div className="direction-map">
              {/* 다음 지도 들어가는 공간 */}
              <div id="myMap" style={mapStyle}></div>
            </div>

            <div className="direction-text">
              <div className="direction-text-section">
                <div className="direction-icon">
                  <img
                    src="../assets/images/direction/direction_location.png"
                    className="icon-red"
                  />
                </div>

                <div className="direction-content">
                  <p className="title">주소</p>
                  <p className="content">
                    서울시 관악구 승방2길2, 5층 (남현동,한일빌딩)
                  </p>
                </div>
              </div>

              <div className="direction-text-section">
                <div className="direction-icon">
                  <img
                    src="../assets/images/direction/direction_tel.png"
                    className="icon-green"
                  />
                </div>

                <div className="direction-content">
                  <p className="title">TEL</p>
                  <p className="content">02-598-0814</p>
                </div>
              </div>

              <div className="direction-text-section">
                <div className="direction-icon">
                  <img
                    src="../assets/images/direction/direction_email.png"
                    className="icon-blue"
                  />
                </div>

                <div className="direction-content">
                  <p className="title">EMAIL</p>
                  <p className="content">wyk01@naver.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Direction;
