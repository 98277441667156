import React, { useEffect, useState } from "react";
import PageInfomation from "@/components/common/pageInfomation";
import useAxiosFunction from "@/hooks/useAxios";
import moment from "moment";
import _ from "lodash";
import QuickMenu from "@/components/common/quickMenu";
import "@/assets/css/history.css";
import "@/assets/css/reset.css";

function History() {
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const [historyAllList, setHistoryAllList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [mList, setMList] = useState(0);

  const getHistoryList = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getHistory,
      requestConfig: {},
    })
      .then((res) => {
        if (res.success) {
          res.historyList.forEach((element) => {
            element.year = moment(element.td).format("yyyy");
            element.tenyear =
              moment(element.td).format("yyyy").slice(0, 3) + "0";
            element.content = element.td_last.split("\n");
          });
          let list = _.groupBy(res.historyList, "tenyear");
          let sortList = Object.entries(list).reverse();

          sortList.forEach((element) => {
            element[1] = _.groupBy(element[1], "year");
          });

          setHistoryAllList(sortList);
          setHistoryList(Object.values(sortList)[0][1]);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  const menuClick = (e, number) => {
    e.preventDefault();
    setMList(number);
    setHistoryList(Object.values(historyAllList)[number][1]);
  };

  useEffect(() => {
    getHistoryList(null);
  }, []);

  return (
    <section id="sub">
      <PageInfomation
        pageName="재단소개"
        pageText=""
        imgSource="../assets/images/intro/intro_bg.jpg"
      />

      <QuickMenu />

      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>연혁</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="history">
          <div
            className={`tabMenuWrap row tabMenu-${
              Object.keys(historyAllList).length
            }`}
          >
            <ul id="tabs">
              {Object.values(historyAllList).map((item, index) => (
                <li key={index}>
                  <a
                    className={mList == index ? "current" : ""}
                    onClick={(event) => menuClick(event, index)}
                  >
                    <span className="tabMenu btnAni btnAniLine">
                      {item[0]}년대
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="history-table">
            <table>
              <tbody>
                {Object.values(historyList)?.map((item, index) =>
                  item.map((ele, index) => (
                    <tr key={index}>
                      {index == 0 ? <th>{ele.year}</th> : <th></th>}
                      <td className="table_date">
                        {moment(ele.td).format("YYYY.MM.DD")}
                      </td>
                      <td>
                        {ele.content.map((e, index1) =>
                          index1 == 0 ? e + "\n" : "- " + e + "\n",
                        )}
                      </td>
                    </tr>
                  )),
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default History;
