import "../assets/css/reset.css";
import "../assets/css/include.css";
import "../assets/css/mo_include.css";
import "../assets/css/main.css";
import "../assets/css/mo_main.css";
import "../assets/css/sub.css";
import "../assets/css/mo_sub.css";
import "../assets/css/slick.css";
import "../assets/css/slick-theme.css";

import React from "react";
import Footer from "./common/footer";
import TopNavigation from "./common/topNavigation";

const Layout = ({ title, children }) => {
  //let js1, js2;
  const isSub = title === "home" ? null : "subWrap";
  /* console.log("title > ", title);
  console.log("isSub > ", isSub); */

  return (
    <div className={isSub}>
      <TopNavigation title={title} />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
