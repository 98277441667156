import React from "react";
import PageInfomation from "@/components/common/pageInfomation";
import QuickMenu from "@/components/common/quickMenu";
import "@/assets/css/member.css";

function Member() {
  const members = [
    {
      position: "이사장",
      name: "고황윤",
      company: "한일 대표",
      term: "2020.10.30~2024.10.29",
    },
    {
      position: "이사",
      name: "김창수",
      company: "(주)덕산메카시스",
      term: "2020.10.30~2024.10.29",
    },
    {
      position: "이사",
      name: "송석상",
      company: "보라정보통신",
      term: "2020.10.30~2024.10.29",
    },
    {
      position: "이사",
      name: "김연흥",
      company: "킨더슐레 대표",
      term: "2020.10.30~2024.10.29",
    },
    {
      position: "이사",
      name: "김경오",
      company: "(주)전일산업피팅 대표",
      term: "2023.07.20~2024.10.29",
    },

    {
      position: "감사",
      name: "채규정",
      company: "큰열매여성의원장",
      term: "2023.07.20~2025.07.19",
    },
    {
      position: "감사",
      name: "노덕기",
      company: "노덕기 법률사무소 대표",
      term: "2023.07.20~2025.07.19",
    },
  ];

  return (
    <section id="sub">
      <PageInfomation
        pageName="재단소개"
        pageText=""
        imgSource="../assets/images/intro/intro_bg.jpg"
      />

      <QuickMenu />

      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>임원 명단</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="member">
          {/* <div className="member-info">이사 명단</div>
            <hr /> */}
          <table>
            <th>직위</th>
            <th>성명</th>
            <th>직장명</th>
            <th>임기</th>
            {members.map((item, index) => (
              <tr key={index}>
                <td>{item.position}</td>
                <td>{item.name}</td>
                <td>{item.company}</td>
                <td>{item.term}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </section>
  );
}

export default Member;
