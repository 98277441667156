import React from "react";
import PageInfomation from "@/components/common/pageInfomation";
import QuickMenuSpon from "@/components/common/quickMenuSpon";
import SponsorContent from "@/data/sponsorContent";
import "@/assets/css/sponsor.css";

function Sponsor() {
  return (
    <section id="sub">
      <PageInfomation
        pageName="기부금출연&장학금지급 활동"
        pageText=""
        imgSource="../assets/images/donation/donation_bg.jpg"
      />

      <QuickMenuSpon />

      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>기부금출연 내역</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="sponsor">
          {/* <div className="member-info">이사 명단</div>
            <hr /> */}
          <table>
            <thead>
              <tr>
                <th>년도</th>
                <th>연도합계(원)</th>
                <th>개인(원)</th>
                <th>인원(명)</th>
                <th>동문(원)</th>
                <th>동문회수(갯수)</th>
                <th>총누적합계(원)</th>
              </tr>
            </thead>
            <tbody>
              {SponsorContent.map((item, index) => (
                <tr key={index}>
                  <td>{item.year}</td>
                  <td>{item.year_total}</td>
                  <td>{item.person}</td>
                  <td>{item.head_count}</td>
                  <td>{item.alumni}</td>
                  <td>{item.alumni_count}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
              <tr className="last-tr">
                <td>기부금출연 총액</td>
                <td colSpan={6}>
                  {SponsorContent[SponsorContent.length - 1].total}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Sponsor;
