import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputUnit from "@/components/InputUnit";
import "./admin.css";
import JoinIn from "./join-in";
import LogIn from "@/pages/admin/login";

const Enter = () => {
  const [tabState, setTabState] = useState(false);
  /*   const {
    setFocus,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" }); */

  const onValid = (data) => {
    console.log("valid data >> ", data);
  };

  const onTabChangeClick = (e) => {
    e.preventDefault();
    setTabState((prev) => (prev = !prev));
    //reset();
  };

  return (
    <div className="admin-enter--wrapper">
      <div className="admin-enter--container">
        <h1>{`Admin ${tabState ? " JoinIn" : " LogIn"}`}</h1>
        <div className="admin-enter">
          {tabState ? <JoinIn /> : <LogIn />}
          <div className="tab-area">
            <button
              className="tab-change_button btn"
              onClick={onTabChangeClick}
            >
              {tabState ? "로그인" : "회원가입"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enter;
