import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import useAxiosFunction from "@/hooks/useAxios";

const MainNewsPreviewer = () => {
  const [noticeList, setNoticeList] = useState([]);
  const { apiUrls, axiosFetch, loading } = useAxiosFunction();
  useEffect(() => {
    axiosFetch({
      method: "post",
      url: apiUrls.getNoticeLimit,
      requestConfig: null,
    })
      .then((res) => {
        if (res.success) {
          setNoticeList(res.noticeList);
        } else {
          //alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  }, []);

  return (
    <div className="news">
      <div className="titleLine">
        <div className="titleArea">
          <div className="en">장학재단 NEWS</div>
          <div className="title">공지사항</div>
        </div>

        <div className="more">
          <Link to="/notice">
            <div className="text">전체보기</div>
            <div className="ico">
              <img src="../assets/images/newsMoreIco.png" alt="" />
            </div>
          </Link>
        </div>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="newsCont">
          <div className="notice">
            <Link to={`/notice/detail/` + noticeList[0]?.board_id}>
              <div className="cont">
                <div className="bat">필독</div>
                <div className="subject">{noticeList[0]?.title}</div>
                <div className="substance">{noticeList[0]?.content}</div>
                <div className="date">
                  {moment(noticeList[0]?.board_date).format("YYYY.MM.DD")}
                </div>
              </div>
              <div className="bg">
                <img src="../assets/images/newsThum.png" alt="" />
              </div>
            </Link>
          </div>

          <ul className="newsList">
            {noticeList.map((item, index) =>
              index == 0 ? null : (
                <li key={index}>
                  <Link to={`/notice/detail/` + item.board_id}>
                    <div className="subject">{item.title}</div>
                    <div className="date">
                      {moment(item.board_date).format("YYYY.MM.DD")}
                    </div>
                  </Link>
                </li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MainNewsPreviewer;
