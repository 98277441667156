import { cls } from "@/helpers/helper";
import React from "react";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";

const CustomLink = ({ children, to, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  const location = useLocation();

  return (
    <>
      <Link
        to={to}
        {...props}
        className={cls(location.pathname.includes(to) || match ? "active" : "")}
      >
        {children}
      </Link>
    </>
  );
};

export default CustomLink;
