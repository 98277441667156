import React from "react";
import PageInfomation from "@/components/common/pageInfomation";
import "@/assets/css/donation.css";
import QuickMenuSpon from "@/components/common/quickMenuSpon";
import {
  DonationContent,
  HighSchoolTotal,
  MiddleSchoolTotal,
  HeadTotal,
  DonationTotal,
} from "@/data/donationContent";

function Donation() {
  return (
    <section id="sub">
      <PageInfomation
        pageName="기부금출연&장학금지급 활동"
        pageText=""
        imgSource="../assets/images/donation/donation_bg.jpg"
      />

      <QuickMenuSpon />

      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>장학금지급 내역</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="donation">
          {/* <div className="member-info">이사 명단</div>
            <hr /> */}
          <table>
            <thead>
              <tr>
                <th>년도</th>
                <th>고등학교(명)</th>
                <th>중학교(명)</th>
                <th>합계(명)</th>
                <th>장학금지급액(원)</th>
              </tr>
            </thead>
            <tbody>
              {DonationContent.map((item, index) => (
                <tr key={index}>
                  <td>{item.year}</td>
                  <td>{item.high_school}</td>
                  <td>{item.middle_schoole}</td>
                  <td>{item.head_count}</td>
                  <td>{item.donation}</td>
                </tr>
              ))}
              <tr className="last-tr">
                <td>장학금지급액 총계</td>
                <td>{HighSchoolTotal()}</td>
                <td>{MiddleSchoolTotal()}</td>
                <td>{HeadTotal()}</td>
                <td>{DonationTotal()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Donation;
