import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const QuickMenuSpon = () => {
  const [qmenuList, setQmenuList] = useState([
    {
      url: "/sponsor",
      imgSrc: "../assets/images/sub/quickIco11_n.png",
      urlText: "기부금출연 내역",
      active: "quick-list-on",
    },
    {
      url: "/donation",
      imgSrc: "../assets/images/sub/quickIco12_n.png",
      urlText: "장학금지급 내역",
      active: "",
    },
  ]);

  const location = useLocation();

  useEffect(() => {
    const copyArr = [...qmenuList];

    copyArr.map((item) => {
      if (item.url == location.pathname) {
        item.active = "quick-list-on";
      } else {
        item.active = "";
      }
    });

    setQmenuList(copyArr);
  }, [location.pathname]);

  return (
    <div className="subQTline">
      <ul className="quickMenu">
        {qmenuList.map((item, index) => (
          <li key={index} className={item.active}>
            <Link to={item.url}>
              <div className="ico">
                <img src={item.imgSrc} />
              </div>
              <div className="text">{item.urlText}</div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuickMenuSpon;
