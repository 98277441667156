import React from "react";
import Home from "@/pages/main/home";
import Intro from "@/pages/info/intro";
import Chairman from "@/pages/info/chairman";
import Operation from "@/pages/info/operation";
import Member from "@/pages/info/member";
import Direction from "@/pages/info/direction";
import History from "@/pages/info/history";
import Sponsor from "@/pages/sponsor/sponsor";
import Donation from "@/pages/sponsor/donation";
import Guide from "@/pages/guide/guide";
import Notice from "@/pages/notice/notice";
import NoticeDetail from "@/pages/notice/notice-detail";
import NoticeWrite from "@/pages/notice/noticeWrite";
import NoticeEdit from "@/pages/notice/noticeEdit";
import ErrorPage from "@/pages/errorPage";
import Enter from "@/pages/admin/enter";
import AdminInfo from "@/pages/admin/admin-info";
import { RequiredAuth, RequiredLogin } from "@/components/common/requiredAuth";
import Login from "@/pages/admin/login";
import UserInfo from "@/pages/admin/user-info";
import JoinIn from "@/pages/admin/join-in";

const router = [
  { path: "/", element: <Home /> },
  { path: "/intro", element: <Intro /> },
  { path: "/chairman", element: <Chairman /> },
  { path: "/history", element: <History /> },
  { path: "/operation", element: <Operation /> },
  { path: "/member", element: <Member /> },
  { path: "/direction", element: <Direction /> },
  { path: "/sponsor", element: <Sponsor /> },
  { path: "/donation", element: <Donation /> },
  { path: "/guide", element: <Guide /> },
  {
    path: "/notice",
    element: <Notice />,
    children: [
      {
        path: `write`,
        element: (
          <RequiredAuth>
            <NoticeWrite />
          </RequiredAuth>
        ),
      },
      {
        path: `edit/:postId`,
        element: (
          <RequiredAuth>
            <NoticeEdit />
          </RequiredAuth>
        ),
      },
      {
        path: `detail/:postId`,
        element: <NoticeDetail />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/user-info",
    element: (
      <RequiredAuth>
        <UserInfo />
      </RequiredAuth>
    ),
  },
  { path: "/admin", element: <Enter /> },
  { path: "/join", element: <JoinIn /> },
  {
    path: "/admin-info",
    element: (
      <RequiredAuth>
        <AdminInfo />
      </RequiredAuth>
    ),
  },
  { path: "*", element: <ErrorPage /> },
];

export default router;
