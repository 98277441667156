import React from "react";
import MainVisualSlider from "@/pages/main/main-visual-slider";
import MainNewsPreviewer from "@/pages/main/main-news-previewer";
import MainPackageBanner from "@/pages/main/main-package-banner";
import MainPromotionSlider from "@/pages/main/main-promotion-slider";

const Home = () => {
  return (
    <section id="main">
      <MainVisualSlider />

      <MainPackageBanner />

      <MainPromotionSlider />

      <MainNewsPreviewer />
    </section>
  );
};

export default Home;
