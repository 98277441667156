import "./login.css";
import React from "react";
import InputUnit from "@/components/InputUnit";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { userInfoState } from "@/recoil/userStore";
import useAxiosFunction from "@/hooks/useAxios";

const Login = () => {
  const setUserInfo = useSetRecoilState(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const login = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.login,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setUserInfo({
            aToken: res.auth_info.access_token,
            isLogin: true,
            userInfo: res.user_id,
          });
          localStorage.setItem("rToken", res.auth_info.refresh_token);
          navigate("/");
        } else {
          setUserInfo({
            aToken: null,
            isLogin: false,
            userInfo: null,
          });
          localStorage.removeItem("rToken");
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  return (
    <div className="accounts--wrapper">
      <form className="hook_form log_in" onSubmit={handleSubmit(login)}>
        <InputUnit
          label="ID"
          holder="아이디를 입력해주세요"
          register={register("user_id", {
            required: "아이디는 필수 입력 값 입니다.",
            minLength: {
              value: 3,
              message: "최소 3자 이상 입력 해 주세요",
            },
            maxLength: {
              value: 22,
              message: "최대 22자 이하로 입력 해 주세요",
            },
            pattern: {
              value: /^[a-z0-9]+[a-z0-9]+$/i,
              message: "한글 및 특수문자는 아이디에 사용할 수 없어요",
            },
          })}
          errors={formErrors}
        />
        <InputUnit
          label="PW"
          type="password"
          holder="패스워드를 입력해주세요."
          register={register("user_pw", {
            required: "패스워드는 필수 입력 값 입니다.",
          })}
          errors={formErrors}
        />
        {/*   {errors.username && <p>{errors.username.message}</p>} */}
        <button className="admin-enter__submit_btn" type="submit">
          로그인
        </button>
      </form>
    </div>
  );
};

export default Login;
