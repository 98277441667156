//import { aToken, storeAToken } from "@/recoil/userStore";
import React, { useState, createContext } from "react";
import { useContext } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

export const AuthContext = createContext({
  user: null,
  isLogin: false,
  aToken: null,
  setUser: () => {},
  setToken: () => {},
  setAToken: () => {},
});

const AuthProvider = ({ children }) => {
  //const storeAToken = useSetRecoilState(aToken);

  const [data, setData] = useState({
    user: null,
    isLogin: false,
    aToken: null,
  });

  const setUser = (prop) =>
    setData((prev) => ({ ...prev, isLogin: true, user: prop }));

  const setToken = (prop) => {
    //storeAToken(prop.access_token);
    setData((prev) => ({ ...prev, aToken: prop.access_token }));
    /* setData((prev) => ({ ...prev, a_token: aToken(prop.access_token) })); */
    localStorage.setItem("rToken", prop.refresh_token);
  };

  const setAToken = (prop) => {
    //storeAToken(prop.access_token);
    setData((prev) => ({ ...prev, a_token: prop.access_token }));
  };

  const logout = () => {
    setData((prev) => ({ ...prev, isLogin: false, user: null, a_token: null }));
    localStorage.removeItem("rToken");
  };

  return (
    <AuthContext.Provider
      value={{ ...data, setToken, setAToken, setUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useUserAuth = () => {
  return useContext(AuthContext);
};

export { useUserAuth };
export default AuthProvider;
