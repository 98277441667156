import React from "react";
import { Link } from "react-router-dom";
import * as topMenu from "@/assets/js/common";
import { topNavMenuList, dropMenuList } from "@/data/contents";
import CustomLink from "./customLink";

const TopNavigation = ({ title }) => {
  const menuList = topNavMenuList;

  const Logo = () => {
    return (
      <div className="logo">
        <Link to="/">
          <img src={"/assets/images/logo.png"} className="logo_w" />
          <img src="/assets/images/logo.png" className="logo_c" />
        </Link>
      </div>
    );
  };

  const Menu = () => {
    const list = menuList.map((element, index) => {
      return (
        <li
          key={index}
          className={`menu0${element.id}`}
          onMouseOver={topMenu[`menu0${element.id}Mouseover`]}
        >
          <CustomLink to={element.path} menu={element.menu}>
            {element.name}
          </CustomLink>
        </li>
      );
    });

    return <ul className="menu">{list}</ul>;
  };

  const RtMenu = () => {
    return (
      <div className="rtMenu">
        <div className="allMenu" onClick={topMenu.allMenuMouseclick}>
          <img src="/assets/images/allMenu_c.png" className="menu_w" />
          <img src="/assets/images/allMenu_c.png" className="menu_c" />
        </div>

        <div className="moMenu" onClick={topMenu.moMenuClick}>
          <img src="/assets/images/allMenu_c.png" className="menu_w" />
          <img src="/assets/images/allMenu_c.png" className="menu_c" />
        </div>
      </div>
    );
  };

  const MenuList = () => {
    const list = menuList.map((element, index) => {
      return (
        <dd key={index}>
          <div className="depth01Box">
            <CustomLink to={element.path} menu={element.menu}>
              {element.name}
              <span>
                <img src="/assets/images/menu_l_arrow.png" />
              </span>
            </CustomLink>
          </div>
          <ul className="depth02List">
            <li style={{ listStyle: "none" }}>
              <Link to="/" />
            </li>
          </ul>
        </dd>
      );
    });

    return (
      <div
        className="dropMenu allDropMenu"
        onClick={topMenu.allMenuMouseclick}
        onMouseLeave={topMenu.allDropMenuMouseLeave}
      >
        <div className="titArea">
          <div className="tit">전체메뉴</div>
          <div className="subTit">장학재단 Sitemap</div>
        </div>
        <div className="menuCont">
          <ul className="menuList">{list}</ul>
        </div>
        <div className="dropmenuBG">
          <img src="../assets/images/donation/donation_bg.jpg" />
        </div>
      </div>
    );
  };

  const DropMenuList = () => {
    const list = dropMenuList.map((element, index) => {
      const {
        subMenu: [{ name, menu, path }],
      } = element;
      return (
        <li key={index}>
          <div
            className={`dropMenu dropMenu0${element.id}`}
            onMouseLeave={topMenu[`dropMenu0${element.id}Mouseleave`]}
          >
            <div className="titArea">
              <div className="tit">{element.title}</div>
              <div className="subTit">{element.subTitle}</div>
            </div>

            <div className="menuCont">
              <dl className="menuList">
                <dd>
                  <div className="depth01Box">
                    <CustomLink to={path} menu={menu}>
                      {name}
                      <span>
                        <img src="/assets/images/menu_l_arrow.png" />
                      </span>
                    </CustomLink>
                  </div>
                  <ul className="depth02List">
                    {element.subMenu.map((ele, index) => {
                      if (index == 0) return;
                      return (
                        <li key={index}>
                          <CustomLink to={ele.path}>{ele.name}</CustomLink>
                        </li>
                      );
                    })}
                  </ul>
                </dd>
              </dl>
            </div>

            <div className="dropmenuBG">
              <img src="../assets/images/graduation.jpg" />
            </div>
          </div>
        </li>
      );
    });
    return <ul>{list}</ul>;
  };

  return (
    <header
      id="header"
      onMouseOver={topMenu.headerMouseover}
      onMouseLeave={() => topMenu.headerMouseleave(title)}
    >
      <Logo />
      <Menu />
      <RtMenu />
      <MenuList />
      <DropMenuList />

      <div className="moSlideMenu">
        <div className="topLine">
          <div className="logo">
            <Link to="/">
              <img src="/assets/images/logo.png" />
            </Link>
          </div>
          <div className="menuClose" onClick={topMenu.menuCloseClick}>
            <img src="/assets/images/close_w.png" />
          </div>
        </div>

        <dl className="menuList">
          <dd className="pm">
            <CustomLink to="/intro" onClick={() => topMenu.mm(1)} menu="intro">
              <div className="text">재단소개</div>
            </CustomLink>
          </dd>
          <dd>
            <CustomLink to="/sponsor" menu="sponsor">
              기부금출연&장학금지급 활동
            </CustomLink>
          </dd>
          <dd>
            <CustomLink to="/guide" menu="guide">
              후원안내
            </CustomLink>
          </dd>
          <dd>
            <CustomLink to="/notice" menu="notice">
              공지사항
            </CustomLink>
          </dd>
        </dl>
      </div>
    </header>
  );
};

export default TopNavigation;
