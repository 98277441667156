import React from "react";
import PageInfomation from "@/components/common/pageInfomation";
import QuickMenu from "@/components/common/quickMenu";
import "@/assets/css/intro.css";

function Intro() {
  return (
    <section id="sub">
      <PageInfomation
        pageName="재단소개"
        imgSource="../assets/images/intro/intro_bg.jpg"
      />

      <QuickMenu />

      <div className="subCont">
        <div className="subTitleArea">
          <div className="tit">
            <h1>이사장 인사말</h1>
            <div className="hrH1"></div>
          </div>
        </div>
      </div>

      <div className="golfgogo">
        <div className="introduce">
          <div className="intro-text">
            국가 백년대계를 위한 길에
            <span> (재)재경군산중고장학회</span>가 함께 하겠습니다
          </div>
          <div className="chairman-box">
            <div className="intro-chairman">
              <img src="../assets/images/intro/chairman.png" />
            </div>

            <div className="chairman-text">
              <p>
                <span className="chairman-text-span">
                  (재)재경군산중고장학회를 찾아주신 여러분 환영합니다.
                </span>
                <br />
                <br />
                본 장학회는 재경군산중고등학교 선배님들이 후배를 사랑하는 마음을
                담아 1994년도 6월 10일에 설립되었습니다. <br />
                <br />
                본 장학회는 국가사회에 봉사할 수 있는 인재양성과 학문의 창달에
                기여하기 위하여 학업성적이 우수하거나 특수한 재질이 있는
                군산중고등학교 학생들에게 장학금을 지급하여 그 재능의 신장함을
                목적으로 하기 위하여
                <br />
                <br />
                <span className="chairman-text-obj">
                  &nbsp;1. 장학금의 지급
                </span>
                <br />
                <span className="chairman-text-obj">
                  &nbsp;2. 학술연구비 지급
                </span>
                <br />
                <span className="chairman-text-obj">
                  &nbsp;3. 기타 재단의 목적달성에 필요한 사업
                </span>
                을 목표로 하고 있습니다.
                <br />
                <br />
                녹록치 않은 상황에서도 한결같은 성원과 나눔의 가치를 보여주신
                선후배 여러분 덕분에 저희 장학회는 오늘도 보다 나은 희망을 향해
                한걸음 더 나아갈 수 있었습니다.
                <br />
                저를 비롯한 임원진은 앞으로도 군산중고등학교 후배들의 모교사랑과
                훗날 국가사회발전에 이바지할 수 있도록 한마음 한뜻으로 최선을
                다하겠습니다. <br />
                이러한 뜻이 있는 일에 선후배님들의 관심과 참여를 부탁드립니다.
                <br />
                <br />
                감사합니다.
              </p>
              <div className="chairman-text-sign">
                <p>재단법인 재경군산중고장학회 이사장</p>
                <span>고 황 윤</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;
