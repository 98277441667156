import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { userInfoState } from "@/recoil/userStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAxiosFunction from "@/hooks/useAxios";
import { apiUrl } from "@/data/apiUrl";

const useAxiosAuth = () => {
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const axiosInstance = axios.create();
  const baseURL =
    process.env.NODE_ENV == "development"
      ? "http://localhost:8911/api"
      : `/api`;
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const navigate = useNavigate();

  const aToken = userInfo.aToken;

  const authFail = () => {
    setUserInfo({
      aToken: null,
      isLogin: false,
      userInfo: null,
    });

    localStorage.removeItem("rToken");
    navigate("/login");
  };

  const authSuccess = (data) => {
    setUserInfo({
      isLogin: true,
      aToken: data.access_token,
      userInfo: data.user_id,
    });
  };

  // Rquest
  axiosInstance.interceptors.request.use(
    (config) => {
      config.baseURL = baseURL;
      config.headers["Content-Type"] = "application/json; charset=utf-8";
      config.headers["Accpet"] = "*/*";
      config.headers["Authorization"] = `Bearer ${aToken}`;

      return config;
    },
    (error) => {
      console.log("interceptors.request err  >", JSON.stringify(error));
      return Promise.reject(error);
    },
  );

  // Response
  axiosInstance.interceptors.response.use(
    function (response) {
      console.log("interceptors.response >", JSON.stringify(response));

      return response.data.data;
    },
    async function (error) {
      console.log("interceptors.response err  >", JSON.stringify(error));
      const originalRequest = error.config;
      const rToken = localStorage.getItem("rToken");

      if (error.response.status === 401) {
        if (rToken) {
          await axiosFetch({
            method: "POST",
            url: apiUrls.rTokenCheck,
            requestConfig: { refresh_token: rToken },
          })
            .then((res) => {
              authSuccess(res);
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${res.access_token}`;
            })
            .catch((err) => {
              authFail();
            });

          return axios(originalRequest);
        } else {
          authFail();
        }
      }

      Promise.reject(error);
    },
  );

  return axiosInstance;
};

const useAxiosAuthFunction = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); //different!
  const [controller, setController] = useState();
  const axiosInstance = useAxiosAuth();
  const apiUrls = apiUrl;

  const axiosFetch = async (configObj) => {
    const { method, url, requestConfig = {} } = configObj;

    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosInstance[method.toLowerCase()](url, {
        ...requestConfig,
        signal: ctrl.signal,
      });
      console.log("await axiosInstance > ", res);
      setResponse(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      console.log("await axiosInstance > ", err.message);
      setError(err.message);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return { response, error, loading, apiUrls, axiosFetch };
};

export default useAxiosAuthFunction;

// export const checkToken = async (config) => {
//   const accessToken = useRecoilValue(userInfoState);
//   const decode = jwt.decode(accessToken);
//   const nowDate = new Date().getTime() / 1000;

//   // 토큰 만료시간이 지났다면
//   if (decode.exp < nowDate) {
//     const { data } = await axios.post(
//       `/token`,
//       { accessToken },
//       {
//         headers: {
//           access_token: getCookie("access_token"),
//         },
//       },
//     );
//     // 리프레쉬 토큰 발급 서버 요청

//     const { refreshToken } = data.data;

//     //accessToken = refreshToken;
//   }

//   config.headers["access_token"] = accessToken;
//   return config;
// };

//httpAuth.interceptors.request.use(checkToken);

// // RESPONSE
// httpAuth.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     console.log("httpAuth 에러잡음");
//     if (
//       error.message.includes("status code 403") ||
//       error.message.includes("status code 401")
//     ) {
//       console.log("403 401 에러잡음");

//       // store
//       //   .dispatch("authentication/refresh_token", {
//       //     access_token: VueCookies.get("access_token"),
//       //     refresh_token: VueCookies.get("refresh_token"),
//       //   })
//       //   .then((res) => {
//       //     console.log("refresh_token");
//       //   })
//       //   .catch(() => {
//       //     console.log("refresh_token err");
//       //     router.push("/login");
//       //   });
//     } else if (error.message.includes("status code 404")) {
//       // store
//       //   .dispatch("authentication/logout", {})
//       //   .then(() => {
//       //     router.push("/login");
//       //   })
//       //   .catch(() => {
//       //     router.push("/login");
//       //   });
//     }
//     return Promise.reject(error);
//   },
// );

// client.interceptors.response.use(
//   function (response) {
//       return response
//   },
//   async function (error) {
//     if (error.response && error.response.status === 403) {
//         try {
//             const originalRequest = error.config;
//             const data = await client.get('auth/refreshtoken')
//             if (data) {
//                 const {accessToken, refreshToken} = data.data
//                 localStorage.removeItem('user')
//                 localStorage.setItem('user', JSON.stringify(data.data, ['accessToken', 'refreshToken']))
//                 originalRequest.headers['accessToken'] = accessToken;
//                 originalRequest.headers['refreshToken'] = refreshToken;
//                 return await client.request(originalRequest);
//                 }
//         } catch (error){
//             localStorage.removeItem('user');
//             console.log(error);
//         }
//         return Promise.reject(error)
//     }
//     return Promise.reject(error)
//   }
// )
