const { kakao } = window;

function KakaoMapScript() {
  const container = document.getElementById("myMap");
  const options = {
    center: new kakao.maps.LatLng(37.473935, 126.981319),
    level: 3,
  };
  const map = new kakao.maps.Map(container, options);

  var markerPosition = new kakao.maps.LatLng(37.473935, 126.981319);

  //마커생성
  var marker = new kakao.maps.Marker({
    position: markerPosition,
  });

  marker.setMap(map);
}

export default KakaoMapScript;
