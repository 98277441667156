import React from "react";
import Slick from "react-slick";
import { mainJs } from "@/assets/js/common";

const MainVisualSlider = () => {
  return (
    <div className="visual">
      <Slick {...mainJs.settingMainVisual} className="mainVisual">
        <li>
          <div className="bg">
            <div className="img-bg-opacity"></div>
            <img src="assets/images/main/mainVisual01.jpg" alt="" />
            <div className="slide-substance">
              <div className="substance">
                <p>
                  재단법인{" "}
                  <span className="dream-span">재경군산중고장학회는</span>
                  <br />
                  많은{" "}
                  <span className="dream-span-two">
                    <ruby>
                      인
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                      재
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                    </ruby>
                    들의
                    <ruby className="">
                      꿈
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                    </ruby>
                  </span>
                  을 응원합니다.
                </p>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div className="bg">
            <div className="img-bg-opacity"></div>
            <img src="assets/images/main/mainVisual02.jpg" alt="" />
            <div className="slide-substance">
              <div className="substance">
                <p>
                  재단법인{" "}
                  <span className="dream-span">재경군산중고장학회는</span>
                  <br />
                  많은{" "}
                  <span className="dream-span-two">
                    <ruby>
                      인
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                      재
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                    </ruby>
                    들의
                    <ruby className="">
                      꿈
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                    </ruby>
                  </span>
                  을 응원합니다.
                </p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="bg">
            <div className="img-bg-opacity"></div>
            <img src="assets/images/main/mainVisual03.jpg" alt="" />
            <div className="slide-substance">
              <div className="substance">
                <p>
                  재단법인{" "}
                  <span className="dream-span">재경군산중고장학회는</span>
                  <br />
                  많은{" "}
                  <span className="dream-span-two">
                    <ruby>
                      인
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                      재
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                    </ruby>
                    들의
                    <ruby className="">
                      꿈
                      <rt>
                        <span className="font-bold">.</span>
                      </rt>
                    </ruby>
                  </span>
                  을 응원합니다.
                </p>
              </div>
            </div>
          </div>
        </li>
      </Slick>
    </div>
  );
};

export default MainVisualSlider;
