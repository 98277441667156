import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { cls } from "@/helpers/helper";
import { MenuIcon, SaveIcon, TrashIcon, TrashIconB,EditIcon } from "@/assets/images/icons/icons";
import useAxiosFunction from "@/hooks/useAxios";
import { useRecoilValue } from "recoil";
import { userInfoState } from "@/recoil/userStore";

const NoticeDetail = () => {
  const userInfo = useRecoilValue(userInfoState);
  const navigate = useNavigate();
  const [noticeData, setNoticeData] = useState([]);
  const { apiUrls, axiosFetch, loading } = useAxiosFunction();
  const { postId } = useParams();

  const onDetailArticle = (e) => {
    e.preventDefault();
    navigate("/notice");
  };

  const onEditHandler = (e) => {
    e.preventDefault();
    navigate(`/notice/edit/${postId}`);
  };

  const onDeleteHandler = (e) => {
    e.preventDefault();

    axiosFetch({
      method: "post",
      url: apiUrls.deleteNotice,
      requestConfig: { postId: postId },
    })
      .then((res) => {
        if (res.success) {
          navigate("/notice");
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    axiosFetch({
      method: "get",
      url: apiUrls.getNoticeDetail(postId),
      requestConfig: null,
    })
      .then((res) => {
        if (res.success) {
          setNoticeData(res.result[0]);
        }
      })
      .catch((err) => {
        console.log("[ERROR] get notice info : ", err);
      });
  }, []);

  function createMarkup() {
    return { __html: noticeData.content };
  }

  return (
    <div className={cls("boardViewArea")}>
      <form>
        <div className="diArea">
          <div className="subjA">
            <div className="noticeBat">공지</div>
            <div className="subj">{noticeData.title}</div>
          </div>

          <ul className="iList">
            <li>작성자 : 관리자</li>
            <li>{moment(noticeData.board_date).format("YYYY/MM/DD")}</li>
          </ul>
        </div>

        <div className="viewBox">
          <div
            className="viewCont"
            dangerouslySetInnerHTML={createMarkup()}
          ></div>
          <ul className="btnArea">
            {userInfo.userInfo && (
              <>
                <li>
                  <a onClick={onEditHandler}>
                    <div className="ico">
                      <EditIcon />
                    </div>
                    <div className="tx">수정</div>
                  </a>
                </li>
                <li>
                  <a onClick={onDeleteHandler}>
                    <div className="ico">
                      <TrashIconB />
                    </div>
                    <div className="tx">삭제</div>
                  </a>
                </li>
              </>
            )}
            <li>
              <a onClick={onDetailArticle}>
                <div className="ico">
                  <MenuIcon />
                </div>
                <div className="tx">목록</div>
              </a>
            </li>
          </ul>
        </div>
      </form>
      <div className="boardListArea"></div>
    </div>
  );
};

export default NoticeDetail;
