import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoState } from "@/recoil/userStore";
import useAxiosFunction from "@/hooks/useAxios";

const RequiredAuth = ({ children }) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const aToken = userInfo.aToken;
  const rToken = localStorage.getItem("rToken");

  const authFail = () => {
    setUserInfo({
      aToken: null,
      isLogin: false,
      userInfo: null,
    });

    localStorage.removeItem("rToken");
    navigate("/login");
  };

  const authSuccess = (data) => {
    setUserInfo({
      isLogin: true,
      aToken: data.access_token,
      userInfo: data.user_id,
    });
  };

  useEffect(() => {
    // A토큰 R토큰 둘다 없을때
    if (aToken == null && rToken == null) {
      authFail();
    }
    // A토큰 있고 R토큰 없을때
    else if (aToken != null && rToken == null) {
      authFail();
    }
    // A토큰 없고 R토큰 있을때
    else if (aToken == null && rToken != null) {
      axiosFetch({
        method: "POST",
        url: apiUrls.rTokenCheck,
        requestConfig: { refresh_token: rToken },
      })
        .then((res) => {
          authSuccess(res);
        })
        .catch((err) => {
          authFail();
        });
    }
    // A토큰 R토큰 둘다 있을때
    else {
      axiosFetch({
        method: "POST",
        url: apiUrls.aTokenCheck,
        requestConfig: { access_token: aToken },
      })
        .then(() => {})
        .catch(() => {
          axiosFetch({
            method: "POST",
            url: apiUrls.rTokenCheck,
            requestConfig: { refresh_token: rToken },
          })
            .then((res) => {
              authSuccess(res);
            })
            .catch(() => {
              console.log("[ERROR] All TokenCheck Error.");
              authFail();
            });
        });
    }
  }, []);

  return children;
};

export { RequiredAuth };
