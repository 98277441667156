import React from "react";
import InputUnit from "@/components/InputUnit";
import { useForm } from "react-hook-form";
import { useMutationData } from "@/api/hooks";
import { useMatch, useNavigate } from "react-router-dom";
import http from "@/hooks/useAxios";

const JoinIn = () => {
  const isJoin = useMatch("/join");
  /* console.log(isJoin?.pattern?.end); */
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onValid = (_data) => {
    console.log("valid Joinin data >> ", _data);
    if (loading) return;
    adminJoin({ user_id: _data.id, user_pw: _data.pw, email: _data.email });
    //enter({ user_id: _data.id, user_pw: _data.pw, email: _data.email });
  };

  let pw = watch("pw");
  const [enter, { loading, data, err }] = useMutationData(
    "/jointest/simpleJoinIn",
  );

  const navigate = useNavigate();

  const adminJoin = (data) => {
    http
      .post("admin/adminJoin", data)
      .then((res) => {
        navigate("/login");
      })
      .catch((err) => {});
  };

  return isJoin ? (
    <>
      {data?.success ? (
        data.message
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <form
            className="hook_form log_in"
            onSubmit={handleSubmit(onValid)}
            style={{ width: "100%", maxWidth: "850px" }}
          >
            <InputUnit
              label="ID"
              holder="아이디를 입력해주세요"
              register={register("id", {
                required: "아이디는 필수 입력 값 입니다.",
                minLength: {
                  value: 3,
                  message: "최소 3자 이상 입력 해 주세요",
                },
                maxLength: {
                  value: 22,
                  message: "최대 22자 이하로 입력 해 주세요",
                },
                pattern: {
                  value: /^[a-z0-9]+[a-z0-9]+$/i,
                  message: "한글 및 특수문자는 아이디에 사용할 수 없어요",
                },
              })}
              errors={errors}
            />
            <InputUnit
              label="Email"
              register={register("email", {
                required: "이메일은 필수 입력 값 입니다.",
                pattern: {
                  value:
                    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                  message: "올바른 이메일을 입력해 주세요",
                },
              })}
              errors={errors}
            />
            <InputUnit
              label="PW 설정"
              type="password"
              holder="패스워드를 입력해주세요."
              register={register("pw", {
                required: "패스워드는 필수 입력 값 입니다.",
              })}
              errors={errors}
            />
            <InputUnit
              label="PW 확인"
              type="password"
              holder="패스워드를 다시 한번 입력해주세요."
              register={register("repw", {
                required: "패스워드 확인은 필수 입력 값 입니다.",
                validate: {
                  value: (value) =>
                    value === pw || "입력하신 패스워드와 맞지 않아요",
                },
              })}
              errors={errors}
            />
            <button className="admin-enter__submit_btn" type="submit">
              로그인
            </button>
            {data?.success == false && (
              <span className="error-message">{data?.message}</span>
            )}
          </form>
        </div>
      )}
    </>
  ) : (
    <>
      {data?.success ? (
        data.message
      ) : (
        <form className="hook_form log_in" onSubmit={handleSubmit(onValid)}>
          <InputUnit
            label="ID"
            holder="아이디를 입력해주세요"
            register={register("id", {
              required: "아이디는 필수 입력 값 입니다.",
              minLength: {
                value: 3,
                message: "최소 3자 이상 입력 해 주세요",
              },
              maxLength: {
                value: 22,
                message: "최대 22자 이하로 입력 해 주세요",
              },
              pattern: {
                value: /^[a-z0-9]+[a-z0-9]+$/i,
                message: "한글 및 특수문자는 아이디에 사용할 수 없어요",
              },
            })}
            errors={errors}
          />
          <InputUnit
            label="Email"
            register={register("email", {
              required: "이메일은 필수 입력 값 입니다.",
              pattern: {
                value:
                  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
                message: "올바른 이메일을 입력해 주세요",
              },
            })}
            errors={errors}
          />
          <InputUnit
            label="PW 설정"
            type="password"
            holder="패스워드를 입력해주세요."
            register={register("pw", {
              required: "패스워드는 필수 입력 값 입니다.",
            })}
            errors={errors}
          />
          <InputUnit
            label="PW 확인"
            type="password"
            holder="패스워드를 다시 한번 입력해주세요."
            register={register("repw", {
              required: "패스워드 확인은 필수 입력 값 입니다.",
              validate: {
                value: (value) =>
                  value === pw || "입력하신 패스워드와 맞지 않아요",
              },
            })}
            errors={errors}
          />
          <button className="admin-enter__submit_btn" type="submit">
            로그인
          </button>
          {data?.success == false && (
            <span className="error-message">{data?.message}</span>
          )}
        </form>
      )}
    </>
  );
};

export default JoinIn;
