const countryMap = {
  philippines: {
    name: "philippines",
    title: "필리핀",
    subTitle1: "자연이 품어낸 동남아의 보물, 필리핀",
    subTitle2: "이 가격에 필리핀 골프를 예약할 수 있는 골프고고",
    package: [
      {
        id: 0,
        title: "풀빌라 패키지",
        subTitle: "+ 54홀 패키지 (골프장 선택 가능)",
        price: "600000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "전용 수영장 풀빌라, FA 코리아 CC 36홀 선택 1 골프장, 총 54홀 그린피, 카트, 캐디피, 전용 차량, 전용 가이드, 조식",
        img: "package_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "풀빌라 패키지 3박 5일 + 매일 18홀 선택",
            subTitle:
              "썬밸리/프라데라/루이시타/뉴아시아/안바야/수빅/비버리 중 자유선택 3일 매일 18홀 선택 (총 54홀) 그린피",
            images: [
              "package_0_1.jpg",
              "package_0_2.jpg",
              "package_0_3.jpg",
              "package_0_4.jpg",
              "package_0_5.jpg",
            ],
            price: "600000",
            unit: "원",
            optionTitle1: "풀빌라 + 매일 18홀 선택 3박 5일",
            optionTitle2:
              "1인 요금(4인기준) 선택 C.C - 썬밸리/프라데라/루이시타/뉴아시아/안바야/수빅/비버리",
            include:
              "전용 수영장 풀빌라, FA 코리아 CC 36홀 선택 1 골프장, 총 54홀 그린피, 카트, 캐디피, 전용 차량, 전용 가이드, 조식",
            exclude:
              "왕복항공권, 캐디팁 및 기타 팁, 중식, 석식, 기타 개인 경비",
            itineraryDate: "3박 5일",
            itinerary: [
              "[인천공항] 저녁9시 출발 > [클락공항] 저녁 11시 55분 도착(비행시간 3시간 30분) 가이드 미팅 및 숙소 이동, 호텔 체크인(공항에서 15분 이동)",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식 > 공항 이동 (공항까지 15분)",
              "[클락공항] 새벽 1시 10분 출발 > [인천공항] 오전 6시 도착 (비행시간 3시간 30분)",
            ],
            warnText: [
              "골프장 사정이나 골프장 변경시 금액 변동 발생합니다.<br />",
              "그린피 캐디피 카트피 포함 (홀추가 비용 9홀당 70~90불)<br />",
              "전일정 전용 차량(그랜드 스타랙스 + 기사 유류비 3천페소 한도 포함)",
            ],
          },
        ],
      },
      {
        id: 1,
        title: "게스트 하우스 패키지",
        subTitle: "+ 54홀 패키지 (골프장 선택 가능)",
        price: "550000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "2인실 게스트 하우스, FA 코리아 CC 36홀 선택 1 골프장, 총 54홀 그린피, 카트, 캐디피, 전용 차량, 전용 가이드",
        img: "package_01.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "게스트 하우스 3박 5일 + 매일 18홀 선택",
            subTitle:
              "썬밸리/프라데라/루이시타/뉴아시아/안바야/수빅/비버리 중 자유선택 3일 매일 18홀 선택 (총 54홀) 그린피",
            images: [
              "package_1_1.jpg",
              "package_1_2.jpg",
              "package_1_3.jpg",
              "package_1_4.jpg",
              "package_1_5.jpg",
              "package_1_6.jpg",
              "package_1_7.jpg",
            ],
            price: "550000",
            unit: "원",
            optionTitle1: "2인실 게스트 하우스 + 매일 18홀 선택 3박 5일",
            optionTitle2:
              "1인 요금(4인기준) 선택 C.C - 썬밸리/프라데라/루이시타/뉴아시아/안바야/수빅/비버리",
            include:
              "2인실 게스트 하우스, FA 코리아 CC 36홀 선택 1 골프장, 총 54홀 그린피, 카트, 캐디피, 전용 차량, 전용 가이드",
            exclude:
              "왕복항공권, 캐디팁 및 기타 팁, 전일정 식사, 기타 개인 경비",
            itineraryDate: "3박 5일",
            itinerary: [
              "[인천공항] 저녁9시 출발 > [클락공항] 저녁 11시 55분 도착(비행시간 3시간 30분) 가이드 미팅 및 숙소 이동, 호텔 체크인(공항에서 15분 이동)",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식 > 공항 이동 (공항까지 15분)",
              "[클락공항] 새벽 1시 10분 출발 > [인천공항] 오전 6시 도착 (비행시간 3시간 30분)",
            ],
            warnText: [
              "골프장 사정이나 골프장 변경시 금액 변동 발생합니다.<br />",
              "그린피 캐디피 카트피 포함 (홀추가 비용 9홀당 70~90불)<br />",
              "전일정 전용 차량(그랜드 스타랙스 + 기사 유류비 3천페소 한도 포함)",
            ],
          },
        ],
      },
      {
        id: 2,
        title: "호텔 패키지",
        subTitle: "+ 54홀 패키지 (골프장 선택 가능)",
        price: "750000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary: "5성급 호텔, FA 코리아 CC 36홀 선택 1 골프장, 총 54홀 그린피",
        img: "package_002.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "호텔 3박 5일 + 매일 18홀 선택",
            subTitle:
              "썬밸리/프라데라/루이시타/뉴아시아/안바야/수빅/비버리 중 자유선택 3일 매일 18홀 선택 (총 54홀) 그린피",
            images: [
              "hilton3.jpg",
              "hilton4.jpg",
              "hilton5.jpg",
              "hilton6.jpg",
              "marriott2.jpg",
              "marriott3.jpg",
              "marriott4.jpg",
              "marriott5.jpg",
              "swissotel2.jpg",
              "swissotel3.jpg",
              "swissotel4.jpg",
              "swissotel5.jpg",
            ],
            price: "750000",
            unit: "원",
            optionTitle1: "5성급 호텔 + 매일 18홀 선택 3박 5일",
            optionTitle2:
              "1인 요금(4인기준) 선택 C.C - 썬밸리/프라데라/루이시타/뉴아시아/안바야/수빅/비버리",
            include:
              "5성급 호텔, FA 코리아 CC 36홀 선택 1 골프장, 총 54홀 그린피",
            exclude:
              "왕복항공권, 캐디팁 및 기타 팁, 중식, 석식, 기타 개인 경비",
            itineraryDate: "3박 5일",
            itinerary: [
              "[인천공항] 저녁9시 출발 > [클락공항] 저녁 11시 55분 도착(비행시간 3시간 30분) 가이드 미팅 및 숙소 이동,<br /> 호텔 체크인(공항에서 15분 이동)",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식",
              "호텔 조식 후 골프장 이동/ 선택 C.C 라운딩/ 석식 후 휴식 > 공항 이동 (공항까지 15분)",
              "[클락공항] 새벽 1시 10분 출발 > [인천공항] 오전 6시 도착 (비행시간 3시간 30분)",
            ],
            warnText: [
              "골프장 사정이나 골프장 변경시 금액 변동 발생합니다.<br />",
              "그린피 캐디피 카트피 포함 (홀추가 비용 9홀당 70~90불)<br />",
              "전일정 전용 차량(그랜드 스타랙스 + 기사 유류비 3천페소 한도 포함)<br />",
            ],
          },
        ],
      },
    ],
    golf: [
      {
        id: 0,
        name: "안바야 코브 CC",
        location: "수빅만공항에서 차량 30분거리, 클락공항에서 1시간 30분거리",
        holeNumbers: "18H/ Par 72/ 7,200y",
        facility:
          "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 프라이빗 다이닝",
        summary:
          "온 가족이 즐길 수 있는 가족골프를 제공하기 위해 설계되었지만 토너먼트 조건에서 강력한 골프 테스트로 쉽게 변형될 수 있다. 시각적으로 놀랍고 위협적인 이 골프코스는 모든 사람에게 도전적이고 자극적인 경험을 제공할 것이다.",
        images: [
          "anvayacove/anvayacove1.jpg",
          "anvayacove/anvayacove2.jpg",
          "anvayacove/anvayacove3.jpg",
          "anvayacove/anvayacove4.jpg",
          "anvayacove/anvayacove5.jpg",
          "anvayacove/anvayacove6.jpg",
          "anvayacove/anvayacove7.jpg",
          "anvayacove/anvayacove8.jpg",
        ],
      },
      {
        id: 1,
        name: "프라데라 베르데 CC",
        location: "클락공항에서 1시간 거리",
        holeNumbers: "36H/ Par 72/ 7,214y",
        facility: "",
        summary:
          "1991년 피나투보 화산 폭발의 잿더미에서 골프장으로 탈바꿈하였다. 여성들은 5,394 야드 레이디 티를 즐길 수 있고, 시니어 팀은 5,992 야드, 블루스는 6,395 야드, 블랙 티는 7,214 야드까지 확장할 수 있다. 주말에 여유롭게 라운딩을 하거나 전문적인 행사를 위한 펌프업 코스로 좋다.",
        images: [
          "pradera/pradera1.jpg",
          "pradera/pradera2.jpg",
          "pradera/pradera3.jpg",
          "pradera/pradera4.jpg",
          "pradera/pradera5.jpg",
          "pradera/pradera6.jpg",
          "pradera/pradera7.jpg",
        ],
      },
      {
        id: 2,
        name: "수빅 CC",
        location: "클락공항에서 1시간 거리",
        holeNumbers: "18H/ Par 72/ 7,200y",
        facility: "",
        summary:
          "골프장 건축계의 살바도르 달리'라는 찬사를 받는 영국 태생의 미국 골프장 건축가 데스몬드 뮤어헤드(Desmond Muirhead, 1924~2002)가 설계해 필리핀의 독립 100주년 기념식에서 영감을 받아 리듬, 밸런스, 시퀀스 등 좋은 골프 코스의 모든 요소를 갖춘 컨트리클럽. 캐디의 채용과 훈련에도 힘을 쏟는 곳.",
        images: [
          "subiccc/subiccc1.jpg",
          "subiccc/subiccc2.jpg",
          "subiccc/subiccc3.jpg",
          "subiccc/subiccc4.jpg",
          "subiccc/subiccc5.jpg",
          "subiccc/subiccc6.jpg",
          "subiccc/subiccc7.jpg",
        ],
      },
      {
        id: 3,
        name: "미모사 컨트리클럽",
        location: "클락공항에서 차량 5분거리",
        holeNumbers:
          "36H/ Par 144/ 13,849y (18H 마운틴뷰/9H 레이크뷰/9H 아카시아)",
        facility:
          "수영장, 농구장, 배구장, 승마장, 드라이빙 레인지, 퍼팅그린, 클럽하우스, 헬스클럽, 스포츠 마사지",
        summary:
          "필리핀의 명문 골프장으로 예전 미군 공군기지로 유명한 클락 최고의 명문 골프장",
        images: [
          "mimosa/mimosa1.jpg",
          "mimosa/mimosa2.jpg",
          "mimosa/mimosa3.jpg",
          "mimosa/mimosa4.jpg",
          "mimosa/mimosa5.jpg",
        ],
      },
      {
        id: 4,
        name: "클락 썬벨리 컨트리클럽",
        location: "클락공항에서 차량 5분거리",
        holeNumbers: "36H/ Par 72/ 7,127y",
        facility: "호텔, 카지노, 고급연회장, 비즈니스 센터, 워터파크",
        summary:
          "국내 썬밸리 그룹이 설계한 골프클럽. 클락 지역 전체와 아라얏 국립공원이 한 눈에 보이는 최고의 경관. 리조트 내 워터파크 특별 할인 및 설악 썬밸리 콘도 이용 시 회원가 혜택",
        images: [
          "sunvalley/sunvalley1.jpg",
          "sunvalley/sunvalley2.jpg",
          "sunvalley/sunvalley3.jpg",
        ],
      },
      {
        id: 5,
        name: "FA코리아 컨트리 클럽",
        location: "클락공항에서 차량 10분 거리",
        holeNumbers: "36H/ Par 108/ 7,428y",
        facility: "클럽하우스, 락커룸, 사우나, 레스토랑, 커피숍, 라운지, 샵",
        summary:
          "클락 내 한국인이 지은 최초의 골프장. 한국 스타일로 만들어진 FA코리아 CC는 해외에서도 국내에서 라운딩 하는 것 처럼 편안한 느낌을 줌. 한국 골퍼들이 가장 선호하는 산악형 골프장으로 업다운이 심한편. 한반도 지도를 형상화 한 16번 홀과 태극기를 그린 18번 홀이 인상적",
        images: [
          "korea/korea1.jpg",
          "korea/korea2.jpg",
          "korea/korea3.jpg",
          "korea/korea4.jpg",
          "korea/korea5.jpg",
          "korea/korea6.jpg",
        ],
      },
      {
        id: 6,
        name: "루이시따 컨트리클럽",
        location: "클락공항에서 차량 40분 거리",
        holeNumbers: "18H/ Par 72/ 7,042y",
        facility: "클럽하우스, 락커룸, 사우나, 샵, 레스토랑",
        summary:
          "필리핀에서 세 번째로 오랜 역사를 자랑, 골프 다이제스트 세계 78위 골프장. 예전 필리핀 전 여성 대통령인 코라손 아카노 집안의 전용 골프장으로 '대통령 골프장'으로 불림. 싱글 실력의 남자 캐디로만 구성되어 있어 원포인트 레슨이 가능함",
        images: [
          "luisita/luisita1.jpg",
          "luisita/luisita2.jpg",
          "luisita/luisita3.jpg",
          "luisita/luisita4.jpg",
          "luisita/luisita5.jpg",
        ],
      },
      /*  {
        id: 6,
        name: "로얄가든 컨트리 클럽",
        location: "클락공항에서 차량 15분 거리",
        holeNumbers: "18H/ Par 72/ 7,252y",
        facility:
          "클럽하우스, 락커룸, 사우나, 레스토랑, 커피숍, 라운지, 샵, 연회장, 드라이빙레인지",
        summary:
          "한국인 경영 컨트리클럽, 고대 르네상스 풍의 양식물이 코스마다 설치되어있어 신선한 재미와 즐거움 선사. 초보들이 즐길 수 있는 코스가 대부분이지만 대다수 홀이 도그레그홀로 되어 있어 홀 공략에 전반적인 난이도가 있음",
        images: [
          "royalgarden_/royalgarden_1.jpg",
          "royalgarden_/royalgarden_2.jpg",
        ],
      }, */

      /*       {
        id: 8,
        name: "뉴아시아 컨트리클럽",
        location: "클락공항에서 차량 50분 거리",
        holeNumbers: "27H/ Par 108/ 11,042y",
        facility:
          "클럽하우스, 락커룸, 사우나, 샵, 레스토랑, 마사지, 온천, 당구장, 라운지, 커피숍",
        summary:
          "골프장 코스가 단순하지 않고 곳곳에 해저드 및 벙커들이 산재해 있어 중상의 코스, 피나투보 화산의 유황 온천을 즐길 수 있음. 클럽 내 고급 호텔식 빌라 숙박시설이 있어 라운딩에 최적",
        images: ["newasia_/newasia_1.jpg", "newasia_/newasia_2.jpg"],
      },
      {
        id: 9,
        name: "레이크우드 컨트리클럽",
        location: "클락공항에서 차량 1시간 거리",
        holeNumbers: "18H/ Par 72/ 6,687y",
        facility: "휴게실, 식당, 샵, 락커룸, 사우나, 수영장, 드라이빙레인지",
        summary:
          "16개의 워터 해저드를 곳곳에 그림처럼 배치하여 환상적인 레이아웃을 자랑. 사계절 전천후 라운딩이 가능한 세계 최고 수준의 배수시설을 갖춤",
        images: ["lakewood_/lakewood_1.jpg", "lakewood_/lakewood_2.jpg"],
      },
      {
        id: 10,
        name: "로얄노스우드 컨트리클럽",
        location: "클락공항에서 차량 1시간 거리",
        holeNumbers: "18H/ Par 72/ 7,032y",
        facility:
          "클럽하우스, 드라이빙 레인지, 퍼팅그린, 락커룸, 샤워룸, 사우나, 레스토랑, 자쿠지, 수영장, 연회장",
        summary:
          "난이도가 높지 않아 비기너와 일반 플레이어에게 적합하며, 철저한 티업 타임 관리, 페어웨이가 넓어서 로스트볼 거의 없음",
        images: ["royalsnow_/royalsnow_1.jpg", "royalsnow_/royalsnow_2.jpg"],
      }, */
    ],
    hotel: [
      {
        id: 0,
        mainTitle: "힐튼 클락 선 밸리 리조트 (Hilton Clark Sun Valley Resort)",
        localeTitle: "Jose Abad Santos Ave",
        subTitle: "클락 자유 무역항 지역, 앙헬레스 / 클락, 필리핀, 2023",
        summary: "친절한 직원과 수영장이 넓고 좋으며 시설이 깨끗하다.",
        mainImage: "hilton/hilton1.jpg",
        images: [
          "hilton/hilton3.jpg",
          "hilton/hilton4.jpg",
          "hilton/hilton5.jpg",
          "hilton/hilton6.jpg",
        ],
      },
      {
        id: 1,
        mainTitle: "스위소텔 클락 (Swissôtel Clark Philippines)",
        localeTitle:
          "LOT2A, LOT2B,LOT2E1 MANUEL A. ROXAS HIGHWAY, CORNER CLARO M. RECTO",
        subTitle: "클락 자유 무역항 지역, 앙헬레스 / 클락, 필리핀, 2023",
        summary: "2022년 신축호텔로 별 5개를 받은 시설.",
        mainImage: "swissotel/swissotel1.jpg",
        images: [
          "swissotel/swissotel2.jpg",
          "swissotel/swissotel3.jpg",
          "swissotel/swissotel4.jpg",
          "swissotel/swissotel5.jpg",
        ],
      },
      {
        id: 2,
        mainTitle: "클락 메리어트 호텔 (Clark Marriott Hotel)",
        localeTitle: "5398 Manuel A Roxas Highway",
        subTitle: "클락 자유 무역항 지역, 앙헬레스 / 클락, 필리핀, 2023",
        summary: "아늑한 객실과 직원의 응대가 빠른 곳",
        mainImage: "marriott/marriott1.jpg",
        images: [
          "marriott/marriott2.jpg",
          "marriott/marriott3.jpg",
          "marriott/marriott4.jpg",
          "marriott/marriott5.jpg",
        ],
      },
      {
        id: 3,
        mainTitle: "Pool Villa",
        localeTitle: "",
        subTitle: "",
        summary: "유틸리티와 풀이 겸비되고 관리가 잘 되어있는 편리한 숙소",
        mainImage: "poolvilla/poolvilla01.jpg",
        images: [
          "poolvilla/poolvilla02.jpg",
          "poolvilla/poolvilla04.jpg",
          "poolvilla/poolvilla05.jpg",
          "poolvilla/poolvilla07.jpg",
        ],
      },
      {
        id: 4,
        mainTitle: "Guest House",
        localeTitle: "",
        subTitle: "",
        summary: "주방과 샤워시설이 잘 갖춰진 깔끔한 숙소",
        mainImage: "guesthouse1/guesthouse01.jpg",
        images: [
          "guesthouse1/guesthouse02.jpg",
          "guesthouse1/guesthouse03.jpg",
          "guesthouse1/guesthouse04.jpg",
          "guesthouse1/guesthouse05.jpg",
        ],
      },

      /*   {
        id: 3,
        mainTitle: "로얄 호텔",
        localeTitle:
          "Royal Garden Estate, Friendship Circumference Road, Cut-cut,",
        subTitle: "클락 페리미터(앤젤레스 시티), 앙헬레스 / 클락, 필리핀",
        summary:
          "비즈니스 여행과 레저 여행을 모두 겸비한 로얄 호텔 숙박시설은 도시에서 가장 유명한 지역인 클락 페리미터(앤젤레스 시티)에 위치해 있습니다. 도심에서 3.0km 거리에 있어도심의 명소들과 볼거리를 쉽게 만나 보실 수 있습니다. 편리한 위치와 함께, 호텔은 도심의 필수 관광지에서 손쉽게 접근 할 수 있는 곳에 있습니다.",
        mainImage: "royal_/royal1.jpg",
        images: [
          "royal_/royal2.jpg",
          "royal_/royal3.jpg",
          "royal_/royal4.jpg",
          "royal_/royal5.jpg",
        ],
      },
      {
        id: 4,
        mainTitle: "르네상스 호텔",
        localeTitle:
          "Lot 7, Block 21 Amazon St., Riverside, Barangay Anunas, Angeles City,",
        subTitle: "클락 페리미터(앤젤레스 시티), 앙헬레스 / 클락, 필리핀",
        summary:
          "따듯한 마음을 가진 클락 페리미터 (앤젤레스 스티)에 있는 클락 르네상스 호텔 숙박시설은 앙헬레스 / 클락 여행 중 둘러보기에 가장 알맞은 곳에 있습니다. 이 곳에서 생생한 도시의 모든 것을 쉽게 즐기실 수 있습니다. 지역의 관광 명소와 경치를 구경하고, 호텔에서 멀지 않은 디오스다도 마카파갈 국제 공항, 오스피탈닝 앤젤레스, 필드 에비뉴에서 즐거운 시간을 보낼 수 있습니다.",
        mainImage: "rnss_/rnss1.jpg",
        images: [
          "rnss_/rnss2.jpg",
          "rnss_/rnss3.jpg",
          "rnss_/rnss4.jpg",
          "rnss_/rnss5.jpg",
        ],
      },
      {
        id: 5,
        mainTitle: "프린스 호텔",
        localeTitle: "Volga St. Barangay Anonas, Angeles,",
        subTitle: "클락 페리미터(앤젤레스 시티), 앙헬레스 / 클락, 필리핀",
        summary:
          "클락 페리미터 (앤젤레스 시티)에 위치해 있는 프린스 호텔 숙박시설은 앙헬레스 / 클락 여행을 시작하기에 적합합니다. 이 곳에서는 생생한 도시의 모든 것을 쉽게 즐기실 수 있습니다. 호텔은 디오스다도 마카파갈 국제공항, 오스피탈닝 앤젤레스, 필드 에비뉴 같이 셀 수 없이 많은 관광지에서 한 걸음 움직이면 편안한 휴식처가 되어 주는 곳입니다.",
        mainImage: "prince_/prince1.jpg",
        images: [
          "prince_/prince2.jpg",
          "prince_/prince3.jpg",
          "prince_/prince4.jpg",
          "prince_/prince5.jpg",
        ],
      },
      {
        id: 6,
        mainTitle: "가든 호텔",
        localeTitle: "Lot 25 & 26, Blk 23 Yantze St., River Side Subdivision,",
        subTitle: "클락 페리미터(앤젤레스 시티), 앙헬레스 / 클락, 필리핀",
        summary:
          "클락 페리미터 (앤젤레스 시티)에 위치해 있는 가든 호텔 숙박시설은 앙헬레스 / 클락 여행을 시작하기에 적합합니다. 이곳에서 생생한 도시의 모든 것을 쉽게 즐기실 수 있습니다. 편한 위치와 함께, 호텔은 도심의 필수 관광지에 손쉽게 접근할 수 있는 곳에 있습니다.",
        mainImage: "garden_/garden1.jpg",
        images: [
          "garden_/garden2.jpg",
          "garden_/garden3.jpg",
          "garden_/garden4.jpg",
          "garden_/garden5.jpg",
        ],
      }, */
    ],
    enjoy: [
      {
        id: 0,
        title: "럭셔리 요트 체험",
        subTitle:
          "헐리우드 영화에서 볼 수 있는 초호화 고급 요트를 타고 여행 중 피로를 풀 수 있는 수빅 럭셔리요트체험 상품. / 2~4시간 코스",
        summary:
          "수빅의 바다는 <b>우리의 바다와는 달리 무척이나 잔잔해</b> 혹 배멀미에 대해 걱정하는 분들도 안심하고 이용할 수 있습니다.<br /> 또한 요트의 내부사진에서 확인 할 수 있듯이 넓은 공간으로 최소 1명부터 최대 30여명까지 동승이 가능합니다. 하지만 선상에서 다양한 체험을 위한 최적의 인원은 약 8명 내외이니 이 점 참고하시기 바랍니다.<br /><br /> 요트 상품에는 추가적으로 <b>선상에서 즐기는 바다낚시 상품도 포함</b>되어있습니다. 낚시 장비는 배 안에 모두 비치되어 있으니 출발 전, 선상낚시 진행 여부만 체크해 주시면 됩니다.<br /><br /> 럭셔리 요트 상품에는 <b>선상에서 맛있는 해산물을 즐길 수 있습니다.</b> 해질녘 노을을 바라보며 사랑하는 사람들과 잔잔한 바다 위에서 시원한 맥주와 와인을 신선한 해산물과 즐길 수 있으니 럭셔리 요트체험 적극 추천 드립니다.",
        images: [
          "yacht/yacht1.jpg",
          "yacht/yacht2.jpg",
          "yacht/yacht3.jpg",
          "yacht/yacht4.jpg",
          "yacht/yacht5.jpg",
        ],
      },
      {
        id: 1,
        title: "무인도 호핑투어",
        subTitle:
          "필리핀 전통 나무배를 타고 약 30분 정도 이동하면 호핑투어를 즐길 수 있는 무인도로 들어갑니다. / 약 4시간",
        summary:
          "무인도에 작은 해변에 위치한 바닷물은 <b>수심이 얇고 아주 맑고 깨끗하여 스노클링을 즐기기엔 최적의 장소</b>입니다. 또한 무인도라는 특성으로 상품 신청한 사람들끼리 이용할 수 있어 다른 휴양지와 달리 아주 조용하게 스노클링을 즐길 수 있습니다.<br /><br /> 해변가에 뜨거운 태양으로부터 피부를 보호할 수 있고, 쉬면서 음식을 즐길 수 있는 <b>방갈로에서 휴양을 즐기실 수 있습니다.</b><br /><br /> 스노클링 장비는 현장에서 직접 전달하며, 물안경으로 깨끗하고 맑은 바다 속을 눈으로 확인할 수 있습니다. 또한 <b>수심이 얇아 수영을 못하는 사람도 즐기기에는 최적의 스노클링 장소</b>입니다.<br /><br />스노클링으로 허기진 배를 채우기 위해 다양한 음식들도 준비하여 제공합니다. <b>다양한 해산물부터 고객이 원하면 삼겹살에 파전까지 준비 가능</b>하며, 소주나 맥주, 음료 등도 시원하게 즐길 수 있도록 아이스박스로 제공하니 상품 신청 시 말씀하여 주시면 됩니다.<br /><br /><b>무인도에서 즐기는 호핑투어는 약 4시간 정도의 넉넉한 일정으로 진행합니다.</b> 조용하게 가족, 연인, 친구들끼리만 스노클링과 맛있는 음식으로 제공하는 수빅호핑투어는 저희 클락고고에서 적극으로 추천하는 상품입니다.<br /><br />사방이 바다로 둘러싸인 섬에서 즐기는 사색의 시간과 즐거운 물 속 액티비티 체험!<br /><br /> 여러분도 꼭 한번 체험해보길 추천합니다!",
        images: [
          "island/island1.jpg",
          "island/island2.jpg",
          "island/island3.jpg",
          "island/island4.jpg",
          "island/island5.jpg",
          "island/island6.jpg",
        ],
      },
      {
        id: 2,
        title: "푸닝온천",
        subTitle:
          "뜨거운 햇살 아래 골프와 코스 이동으로 지친 피로를 날려 줄 '푸닝온천' 체험!",
        summary:
          "푸닝온천은 특정 마을이나 지명이 아닌 온천 이름이며, 온천 위치는 오프로드 지프로 약 10여분 정도 정글 같은 곳으로 들어가게 되면 온천 찜질장 입구가 있습니다.<br />온천을 즐기기 전에 찜질방에서는 화산 모래로 모래찜질, 마사지, 머드팩으로 피로를 풉니다.<br/><br/>찜질 후 다시 지프를 타고 생동감 있는 오프로드 체험을 하며 온천으로 이동합니다.<br/>한폭의 그림 같은 경관으로 둘러 쌓여 있는 푸닝온천!<br/>또 하나의 팁! 50도 이상에서만 자란다는 ‘쿠사츠’라는 미생물이 몸의 노폐물을 제거해 줍니다.<br/>",
        images: [
          "puning/puning1.jpg",
          "puning/puning2.jpg",
          "puning/puning3.jpg",
          "puning/puning4.jpg",
          "puning/puning5.jpg",
          "puning/puning6.jpg",
        ],
      },
      {
        id: 3,
        title: "카트 레이싱 체험",
        subTitle:
          "운전면허 없이도 남녀노소 모두가 즐길 수 있는 카트 레이싱 체험",
        summary:
          "실제 F1 자동차 경주장에서 약 70cm 두께의 바퀴가 부착된 카트로 스피드를 즐길 수 있는 액티비티입니다.<br/>정식 명칭은 클락 인터네셔널 스피드 웨이로 레이싱 경기 없는 날엔 언제든지 이용이 가능합니다.",
        images: ["kart/kart1.jpg", "kart/kart2.jpg", "kart/kart3.jpg"],
      },
      {
        id: 4,
        title: "폰타나 워터파크",
        subTitle: "복잡한 워터파크가 아닌 조용하게 즐길 수 있는 고급 워터파크",
        summary:
          "가격도 저렴하며, 사람도 붐비지 않고 워터파크, 온천시설, 올림픽 규격 수영장 등 다양하게 물놀이를 즐길 수 있는 폰타나 워타파크입니다.",
        images: [
          "fontana/fontana1.jpg",
          "fontana/fontana2.jpg",
          "fontana/fontana3.jpg",
        ],
      },
      {
        id: 5,
        title: "SM 몰",
        subTitle: "국내 대형마트 건물 9개 정도 합친 크기의 대형 쇼핑몰",
        summary:
          "SM몰은 백화점, 레스토랑, 대형마트, 쇼핑몰등 모든 시설이 한 자리에 있으며, 국내 대형마트 건물 9개 정도 규모로 클락에서 쇼핑과 볼거리를 즐길 수 있는 곳 입니다.",
        images: ["sm/sm1.jpg", "sm/sm2.jpg", "sm/sm3.jpg"],
      },
    ],
  },
  japan: {
    name: "japan",
    title: "일본",
    subTitle1: "계절을 가리지 않는 힐링 관광지, 일본",
    subTitle2: "계절을 가리지 않는 힐링 관광지, 일본",

    package: [
      {
        id: 0,
        title: "프로 대회 코스 3색 골프 여행",
        subTitle:
          "아이와리조트+아이와CC+UMK CC(현 AXA JLPG)+하이비스커스 CC(구 리코컵 LPGA)",
        price: "1240000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "왕복항공권(유류할증료 및 TAX) 리조트(2인 1실), 조/석식, 그린피, 카트피",
        img: "package_01_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "프로 대회 코스 3색 골프 여행",
            subTitle:
              "썬밸리/프라데라/루이시타/뉴아시아/안바야/수빅/비버리 중 자유선택 3일 매일 18홀 선택 (총 54홀) 그린피",
            images: [],
            price: "1240000",
            unit: "원",
            optionTitle1: "미야자키 아이와 리조트 + 3색 대회 코스",
            optionTitle2: "프로 대회 코스 3색 골프 여행",
            include:
              "왕복항공권(유류할증료 및 TAX), 리조트(2인1실), 조/석식, 그린피, 카트피",
            exclude: "중식, 기타개인경비 등",
            itineraryDate: "2박 3일",
            itinerary: [
              "[인천공항] > [미야자키 국제공항] 오전 도착(비행시간 약 1시간 20분 소요)<br />가이드 미팅 및 리조트로 이동(약 1시간 소요), 아이와 C.C 18홀 라운딩, 석식 및 자유시간",
              "호텔 조식 후 골프장 이동/ UMK C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 체크아웃 골프장 이동 / 하이비스커스 C.C 18홀 라운딩 / 공항으로 이동<br />[미야자키 국제공항] 출발 > [인천공항]도착(비행시간 약 1시간 20분)",
            ],
            warnText: [
              "이용항공: 아시아나항공<br />",
              "호텔 1인 1실일 경우 30,000원 /박/1인 추가<br />",
              "골프장 사정에 따라 요일이 변경 될 수 있습니다.<br />",
            ],
          },
        ],
      },
      {
        id: 1,
        title: "미야자키 명품 4색 골프 여행",
        subTitle:
          "아이와리조트+아이와CC+UMK CC(현 AXA JLPG)+하이비스커스 CC(구 리코컵 LPGA) + 선샤인 CC + 오요도 CC 중 택 4 골프장",
        price: "1400000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "왕복항공권(유류할증료 및 TAX) 리조트(2인 1실), 조/석식, 그린피, 카트피",
        img: "package_01_01.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "미야자키 명품 4색 골프 여행",
            subTitle:
              "아이와리조트+아이와CC+UMK CC(현 AXA JLPG)+하이비스커스 CC(구 리코컵 LPGA) + 선샤인 CC + 오요도 CC 중 택 4 골프장",
            images: [],
            price: "1400000",
            unit: "원",
            optionTitle1: "미야자키 아이와 리조트 + 선택 골프장",
            optionTitle2: "명품 4색 골프 여행",
            include:
              "왕복항공권(유류할증료 및 TAX), 리조트(2인1실), 조/석식, 그린피, 카트피",
            exclude: "중식, 기타개인경비 등",
            itineraryDate: "3박 5일",
            itinerary: [
              "[인천공항] > [미야자키 국제공항] 오전 도착(비행시간 약 1시간 20분 소요)<br />가이드 미팅 및 리조트로 이동(약 1시간 소요), 아이와 C.C 18홀 라운딩, 석식 및 자유시간",
              "호텔 조식 후 골프장 이동/ 선택 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/ 선택 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/ 선택 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 체크아웃 / 공항으로 이동<br />[미야자키 국제공항] 출발 > [인천공항]도착(비행시간 약 1시간 20분)",
            ],
            warnText: [
              "이용항공: 아시아나항공<br />",
              "호텔 1인 1실일 경우 30,000원 /박/1인 추가<br />",
            ],
          },
        ],
      },
      {
        id: 2,
        title: "최고급 Vip 골프 피닉스/톰왓슨 CC 54홀",
        subTitle: "미야자키 쉐라톤 리조트 + 피닉스 CC 18홀 + 톰왓슨 CC 36홀",
        price: "1740000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "왕복항공권(유류할증료 및 TAX) 리조트(2인 1실), 조/석식, 그린피, 카트피",
        img: "package_01_02.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "최고급 Vip 골프 피닉스/톰왓슨 CC 54홀",
            subTitle:
              "미야자키 쉐라톤 리조트 + 피닉스 CC 18홀 + 톰왓슨 CC 36홀",
            images: [],
            price: "1740000",
            unit: "원",
            optionTitle1: "미야자키 쉐라톤 리조트 + 피닉스 CC 18홀 + 톰왓슨 CC",
            optionTitle2: "최고급 vip 골프 여행",
            include:
              "왕복항공권(유류할증료 및 TAX), 리조트(2인1실), 조/석식, 그린피, 카트피",
            exclude: "중식, 기타개인경비 등",
            itineraryDate: "2박 3일",
            itinerary: [
              "[인천공항] > [미야자키 국제공항] 오전 도착(비행시간 약 1시간 20분 소요)<br />가이드 미팅 및 리조트로 이동(약 20분 소요), 톰왓슨 C.C 18홀 라운딩, 석식 및 자유시간",
              "호텔 조식 후 골프장 이동/ 피닉스 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "[미야자키 국제공항] 출발 > [인천공항]도착(비행시간 약 1시간 20분)",
            ],
            warnText: [
              "이용항공: 아시아나항공<br />",
              "호텔 1인 1실일 경우 80,000원 /박/1인 추가<br />",
            ],
          },
        ],
      },
      {
        id: 3,
        title: "미야자키 3색 골프 특가상품",
        subTitle: "아이와 리조트 + 아이와 C.C, 오요도 C.C + 선샤인 C.C",
        price: "1070000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "왕복항공권(유류할증료 및 TAX) 리조트(2인 1실), 조/석식, 그린피, 카트피",
        img: "package_01_03.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "미야자키 아이와 리조트 + 3색 골프 특가상품",
            subTitle:
              "미야자키 아이와 리조트 + 아이와 C.C, 오요도 C.C, 선샤인 C.C",
            images: [],
            price: "1070000",
            unit: "원",
            optionTitle1: "미야자키 아이와 리조트 + 3색 골프",
            optionTitle2: "미야자키에서 아이와 리조트와 3색 C.C 특가 상품",
            include:
              "왕복항공권(유류할증료 및 TAX), 리조트(2인1실), 조/석식, 그린피, 카트피",
            exclude: "중식, 기타개인경비 등",
            itineraryDate: "2박 3일",
            itinerary: [
              "[인천공항] > [미야자키 국제공항] 오전 도착(비행시간 약 1시간 20분 소요)<br />가이드 미팅 및 아이와로 이동(약 40분 소요), 아이와 C.C 18홀 라운딩, 석식 및 자유시간",
              "호텔 조식 후 골프장 이동/ 오요도 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 체크아웃 골프장 이동 / 선샤인 C.C 18홀 라운딩 / 공항으로 이동<br />[미야자키 국제공항] 출발 > [인천공항]도착(비행시간 약 1시간 20분)",
            ],
            warnText: [
              "이용항공: 아시아나항공<br />",
              "호텔 1인 1실일 경우 30,000원 /박/1인 추가<br />",
            ],
          },
        ],
      },
      {
        id: 4,
        title: "미야자키 아이와 C.C 3일 특가상품",
        subTitle: "아이와 리조트 + 아이와 C.C 골프",
        price: "970000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "왕복항공권(유류할증료 및 TAX) 리조트(2인 1실), 조/석식, 그린피, 카트피",
        img: "package_01_04.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "미야자키 아이와 C.C 3일 특가상품",
            subTitle: "아이와 리조트 + 아이와 C.C 골프",
            images: [],
            price: "970000",
            unit: "원",
            optionTitle1: "미야자키 아이와 리조트 + 아이와 C.C 골프",
            optionTitle2:
              "코리아나 호텔에서 운영하는 아이와 리조트! 맛있는 음식과 여유롭게 즐기는 라운딩!",
            include:
              "왕복항공권(유류할증료 및 TAX), 리조트(2인1실), 조/석식, 그린피, 카트피",
            exclude: "중식, 기타개인경비 등",
            itineraryDate: "2박 3일",
            itinerary: [
              "[인천공항] > [미야자키 국제공항] 오전 도착(비행시간 약 1시간 20분 소요)<br />가이드 미팅 및 아이와로 이동(약 40분 소요), 아이와 C.C 18홀 라운딩, 석식 및 자유시간",
              "호텔 조식 후 골프장 이동/ 아이와 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 체크아웃 골프장 이동 / 아이와 C.C 18홀 라운딩 / 공항으로 이동<br />[미야자키 국제공항] 출발 > [인천공항]도착(비행시간 약 1시간 20분)",
            ],
            warnText: [
              "이용항공: 아시아나항공<br />",
              "호텔 1인 1실일 경우 30,000원 /박/1인 추가<br />",
            ],
          },
        ],
      },
      {
        id: 5,
        title: "미야자키 니치난 C.C 3일 특가상품",
        subTitle: "니치난 리조트 + 니치난 C.C 골프",
        price: "940000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "왕복항공권(유류할증료 및 TAX) 리조트(2인 1실), 조/석식, 그린피, 카트피",
        img: "package_01_05.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "미야자키 니치난 C.C 3일 특가상품",
            subTitle: "미야자키 니치난에서 즐기는 골프 여행",
            images: [],
            price: "970000",
            unit: "원",
            optionTitle1: "미야자키 니치난 리조트 + 니치난 C.C 골프",
            optionTitle2:
              "온천과 함께 즐기는 미야자키 니치난 리조트 C.C 골프 여행",
            include:
              "왕복항공권(유류할증료 및 TAX), 리조트(2인1실), 조/석식, 그린피, 카트피",
            exclude: "중식, 기타개인경비 등",
            itineraryDate: "2박 3일",
            itinerary: [
              "[인천공항] > [미야자키 국제공항] 오전 도착(비행시간 약 1시간 20분 소요)<br />가이드 미팅 및 아이와로 이동(약 1시간 소요), 니치난 C.C 18홀 라운딩, 석식 및 자유시간",
              "호텔 조식 후 골프장 이동/ 니치난 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 체크아웃 골프장 이동 / 니치난 C.C 18홀 라운딩 / 공항으로 이동<br />[미야자키 국제공항] 출발 > [인천공항]도착(비행시간 약 1시간 20분)",
            ],
            warnText: [
              "이용항공: 아시아나항공<br />",
              "호텔 1인 1실일 경우 30,000원 /박/1인 추가<br />",
            ],
          },
        ],
      },
    ],
    golf: [
      {
        id: 0,
        name: "미야자키 UMK C.C (Miyazaki UMK Country Club)",
        location: "큐슈 미야자키현 (미야자키 공항에서 35분 거리",
        holeNumbers: "18H/ Par 72",
        facility: "",
        summary:
          "일본 JLPGA가 열리는 대회 코스로 전략성이 뛰어나 코스레이아웃과 스릴 넘치는 그린이 특징이다. 아웃 코스는 평탄하여 플레이 하기 무난하며 인코스는 기복이 심하고 전략적인 테크닉 샷이 필요한 코스이다. 미야자키 최대 방송국인 UMK가 운영하며 20타석에 260야드 연습장을 보유하고 있다. ",
        images: [
          "umk/umk1.jpg",
          "umk/umk2.jpg",
          "umk/umk3.jpg",
          "umk/umk4.jpg",
          "umk/umk5.jpg",
          "umk/umk6.jpg",
          "umk/umk7.jpg",
        ],
      },
      {
        id: 1,
        name: "미야자키 하이비스커스 C.C (Miyazaki Hibiscus Country Club)",
        location: "큐슈 미야자키현 (미야자키 공항에서 35분 거리)",
        holeNumbers: "18H/ Par 72",
        facility: "",
        summary:
          "LPGA 리코컵을 개최한 코스로 인코스와 아웃 코스가 전혀 다른 분위기로 조성 되어 있다. 웅대한 페어웨이, 휴가나다와 기리시마 산봉우리들의 웅대한 전망, 언제든지 골퍼를 유혹하는 워터 해저드, 마음의 여유와 전략적인 플레이를 해야하는 골프 코스다.",
        images: [
          "hibiscus/hibiscus1.jpg",
          "hibiscus/hibiscus2.jpg",
          "hibiscus/hibiscus3.jpg",
          "hibiscus/hibiscus4.jpg",
          "hibiscus/hibiscus5.jpg",
          "hibiscus/hibiscus6.jpg",
        ],
      },
      {
        id: 1,
        name: "미야자키 아이와 C.C (Miyazaki Aiwa Country Club)",
        location: "큐슈 미야자키현 (미야자키 공항에서 35분 거리)",
        holeNumbers: "18H/ Par 72/ 7,196y",
        facility: "",
        summary:
          "넓고 긴 코스가 특색인 챔피언 코스이고, 유쾌하게 칠 수 있는 쾌적감, 미묘하게 조형된 그린코스에 도전하는 설렘, 골프에 필요한 요소들이 두루 갖추어져있어 완벽한 플레이를 할 수 있는 코스로 정평이 나 있습니다. 코스 안쪽에 서 있는 나무들은 아웃 코스에 워싱턴 골프장을 연상케 하는 양목으로, 인코스에는 일본의 분위기를 느낄 수 있는 화목으로 심어 놓아서 마치 두개의 다른 코스에서 플레이를 하는 것 같은 인상을 주고, 골퍼가 계속 도전하고 싶은 분위기를 조성합니다. 아름다움과 그 안에서 발휘하는 전략성은 일본의 리조트 코스의 집대성임을 확신합니다. ",
        images: [
          "aiwa/aiwa1.jpg",
          "aiwa/aiwa2.jpg",
          "aiwa/aiwa3.jpg",
          "aiwa/aiwa4.jpg",
          "aiwa/aiwa5.jpg",
          "aiwa/aiwa6.jpg",
        ],
      },
      {
        id: 2,
        name: "미야자키 선샤인 C.C (Miyazaki Sunshine Country Club)",
        location: "큐슈 미야자키현 (미야자키 공항에서 25분 거리)",
        holeNumbers: "18H/ Par 72/ 7,122y",
        facility: "",
        summary:
          "규슈의 동남쪽 해발 1700m 고원에 위치한 선샤인 베어스타운 CC는 7, 8월을 제외하고는 계절의 구분이 없다. 바람도 없고 습도도 없는 천혜의 기후조건을 갖고 있다. 1996년 9월 프로 골프 토너먼트 개최를 목적으로 전장 7122야드, 18홀 규모의 챔피언십 정규 코스로 만들어졌다. 이 골프장을 2005년 국내 리조트 기업이자 베어스 타운을 운영하고 있는 예지 실업(대표 석두성)이 인수하면서 새바람을 일으키고 있다.",
        images: ["sunshine/sunshine1.jpg"],
      },
      {
        id: 3,
        name: "미야자키 오요도 C.C (J's C.C Ohyodo Course)",
        location: "큐슈 미야자키현 (미야자키 공항에서 20분 거리)",
        holeNumbers: "18H/ Par 72/ 6,679y",
        facility: "",
        summary:
          "대자연을 만끽하며 우아하고 전략적이며 누구라도 쉽게 즐길 수 있는 고급 골프장입니다. 규슈, 골프 연맹 가입, 전 일본 시니어 프로 아마추어 토너먼트 개최지로서 명성을 더합니다. 페어웨이는 굴곡이 심하고 경사도 심한 편입니다.",
        images: ["ohyodo/ohyodo1.jpg"],
      },
      {
        id: 4,
        name: "피닉스 C.C (PHEONIX Country Club)",
        location: "",
        holeNumbers: "",
        facility: "",
        summary:
          "세계에서 100위 일본에서 3대 명문 코스로 손꼽히며, 1974 이후 매년 11월 타이거 우즈, 세르지오 가르시아, 루크 도널드가 우승해서 유명한 일본 마스터즈 대회라 불리는 던롭 대회가 열리는 27홀 명문 골프 코스이다. 시미요시코스, 타카치호코스, 니치난코스 3개로 나누어 지며 시미요시코스, 타카치호코스 던롭 대회 코스이다. 피닉스 CC의 페어웨이와 러프는 구하기도 힘들다는 '페레니얼 라이그래스'로 매년 대회 한 달여 전 새 옷을 입는다. 삼나무로 둘려 쌓인 페어웨이는 사계절 내내 푸른 양잔디를 사용한다. 300야드가 넘는 거리의 드라이빙 레인지는 천연 잔디에서 완벽에 가까운 연습환경을 제공한다. 캐디사용을 의무로 하며 카트없이 워킹 라운딩을 제공한다.",
        images: [
          "pheonix/pheonix1.jpg",
          "pheonix/pheonix2.jpg",
          "pheonix/pheonix3.jpg",
          "pheonix/pheonix4.jpg",
          "pheonix/pheonix5.jpg",
          "pheonix/pheonix6.jpg",
        ],
      },
      {
        id: 4,
        name: "톰왓슨 C.C (TOM WATSON CC)",
        location: "",
        holeNumbers: "",
        facility: "",
        summary:
          "유명한 골프선수인 톰왓슨이 설계한 코스로 일본 전통의 삼나무 둘러 쌓인 골프코스는 절대 옆 홀을 볼 수가 없다. 일단 페어웨이 놓치면 보기는 기본으로 감수 해야 할 정도로 어려운 코스이다. 2014년 가을부터 야간 플레이도 가능하다. 셀프 플레이가 가능하며 페어웨이까지 카트 진입이 가능 한 골프장이다.",
        images: [
          "tomwatson/tomwatson1.jpg",
          "tomwatson/tomwatson2.jpg",
          "tomwatson/tomwatson3.jpg",
          "tomwatson/tomwatson4.jpg",
          "tomwatson/tomwatson5.jpg",
          "tomwatson/tomwatson6.jpg",
          "tomwatson/tomwatson7.jpg",
          "tomwatson/tomwatson8.jpg",
        ],
      },
      {
        id: 5,
        name: "제이스컨트리클럽 니치난 코스 (J's C.C Nichinan Course)",
        location: "큐슈 미야자키현 니치난시 (미야자키 공항에서 40분 거리)",
        holeNumbers: "18H/ Par 72/ 7,012y",
        facility: "",
        summary:
          "숙소와 스타팅테라스가 바로 연결되어 아주 편리합니다. 라운딩 후 온천을 이용할 수 있어 피로회복이 빠릅니다. 연중 초록의 페어웨이에서 쾌적한 라운딩을 즐기실 수 있습니다. 라운딩 후 즐길 수 있는 다양한 부대시설이 있습니다. 주변에 다양한 관광코스가 많이 있어 또 다른 재미가 있습니다.",
        images: [
          "nichinan/nichinan1.jpg",
          "nichinan/nichinan2.jpg",
          "nichinan/nichinan3.jpg",
          "nichinan/nichinan4.jpg",
          "nichinan/nichinan5.jpg",
          "nichinan/nichinan6.jpg",
        ],
      },
      {
        id: 6,
        name: "제이스컨트리클럽 고바야시 코스 (J's C.C Kobayashi Course)",
        location: "큐슈 미야자키현 니치난시 (미야자키 공항에서 1시간 거리)",
        holeNumbers: "18H/ Par 72/ 6,633y",
        facility: "",
        summary:
          "이코마고원의 수려한 자연경관이 녹아있는 고바야시코스는 하늘을 향해 높이 솟은 아름드리의 삼나무와 노송나무가 장관을 이루는 골프코스입니다. 37만여평의 드넓은 고원 위에 펼쳐진 코스는 사계절 쾌적한 플레이가 가능할 만큼 빼어난 입지조건을 갖추고 있으며, 자연 그대로를 살려 만든 로하스 골프코스 입니다. 골프장 인근의 온천과 에비노고원에서는 몸의 피로회복과 휴식을 즐길 수 있고 옛날 날씨가 맑은 날엔 한국이 보였다고 하여 이름 붙여진 한국악(韓國岳-카라쿠니다케)의 트래킹 코스는 색다른 재미를 제공해 드립니다. ",
        images: [
          "kobayashi/kobayashi1.jpg",
          "kobayashi/kobayashi2.jpg",
          "kobayashi/kobayashi3.jpg",
          "kobayashi/kobayashi4.jpg",
          "kobayashi/kobayashi5.jpg",
          "kobayashi/kobayashi6.jpg",
        ],
      },
    ],
    hotel: [
      {
        id: 0,
        mainTitle: "아이와 미야자키 리조트",
        localeTitle: "Aiwa Miyazaki Resort",
        subTitle:
          "미야자키현 미야자키시 사도와라쵸 히가시우에 105번지 (히가시큐슈 자동차도 서도IC 에서 차로 약 15분/ JR 닛포선 사도와라역 하차 후 차로 약 10분)",
        summary:
          "클락 중심에서 가장 즐길 거리가 많은 곳에서 8.5km 거리에 있는 이 4성 호텔은 좋은 위치와 도시의 가장 큰 관광지 내에 위치합니다. 편한 위치와 함께, 호텔은 도심의 필수 관광지에 손쉽게 접근할 수 있는 곳에 있습니다.",
        mainImage: "aiwa/aiwa1.jpg",
        images: [
          "aiwa/aiwa2.jpg",
          "aiwa/aiwa3.jpg",
          "aiwa/aiwa4.jpg",
          "aiwa/aiwa5.jpg",
        ],
      },
      {
        id: 1,
        mainTitle: "미야자키 쉐라톤 리조트",
        localeTitle: "Sheraton Grande Ocean Resort",
        subTitle: "",
        summary:
          "미야자키 최고급 호텔로 154 미터에 달한다. 753 전 객실이 오션뷰이다. 크게 수페리어 트윈, 트리플, 디럭스 트윈, 스위트 네가지 형태의 객실이 있다. 바닷가와 송림지역의 가운데에 위치하여 온천과 실내외 수영장, 식물원, 동물원, 해변이 이용 가능하다.",
        mainImage: "sheraton/sheraton1.jpg",
        images: [
          "sheraton/sheraton2.jpg",
          "sheraton/sheraton3.jpg",
          "sheraton/sheraton4.jpg",
          "sheraton/sheraton5.jpg",
        ],
      },
      {
        id: 2,
        mainTitle: "J's C.C 니치난 리조트",
        localeTitle: "Nichnan Resort",
        subTitle: "",
        summary:
          "창밖으로 드넓게 펼쳐지는 사계절의 경관이 여러분의 휴식을 더욱 아늑하게 해 드립니다. 일본 전통 다다미로 이뤄진 화실과 아늑한 양실, 101개의 넉넉한 객실은 여러분에게 최고의 휴식공간을 제공해 드립니다. 모든 객실은 미야자키의 웅장한 자연과 아름다운 골프코스를 직접 느낄 수 있으며, 일본 특유의 아기자기한 객실 구조는 여러분들께 색다른 체험의 기회글ㄹ 제공해 드립니다. 또한 풍부한 미네랄과 다양한 효능을 지닌 탄산수소온천은 숲과 해안의 경치를 제공하고 심신의 피로를 말끔히 풀어드립니다. 골프 투어 고객은 횟수와 관계없이 온천욕이 가능합니다.",
        mainImage: "nichnan/nichnan1.jpg",
        images: [
          "nichnan/nichnan2.jpg",
          "nichnan/nichnan3.jpg",
          "nichnan/nichnan4.jpg",
          "nichnan/nichnan5.jpg",
        ],
      },
    ],
    enjoy: [
      {
        id: 0,
        title: "아오시마",
        subTitle: "미야자키현 니치난 해안도로 인접 지역",
        summary:
          "니치난 해안 국정공원 북단에 위치한 둘레 1.5Km의 작은 섬으로, 아열대 식물인 비로야자나무가 3,000그루 이상 자라고 있고, 226종의 아열대 식물이 섬 전체를 뒤덮고 있으며 파도의 침식 작용으로 만들어진 빨래판 모양의 희귀한 바위가 섬을 둥글게 감싼 모습이 이색적입니다.",
        images: ["aoshima/aoshima1.jpg"],
      },
      {
        id: 1,
        title: "선멧세 니치난",
        subTitle: "미야자키현 니치난 해안도로 인접 지역",
        summary:
          "태양의 메시지를 받는 곳이라는 뜻으로 '선멧세'라는 이름이 붙여진 이곳은 니치난 해안에 자리잡고 있는 테마파크로 세계에서 유일하게 이스트 섬의 거석상을 그대로 복원한 모아이상과 목장, 파빌리온, 공원과 태양을 주제로 한 각종 시설로 구성되어 있습니다.",
        images: ["nichinan/nichinan1.jpg"],
      },
      {
        id: 2,
        title: "우도 신궁",
        subTitle: "미야자키현 니치난 해안도로 인접 지역",
        summary:
          "일본 최고의 경치를 자랑하는 신사로 주제신은 일본 초대천황인의 신무천황의 아버지, 우가야후키아에즈노미코트입니다. 동굴 안에 신당이 자리잡고 있어 이색적이며, 이 곳을 찾으면 좋은 인연이 생긴다는 전설이 있습니다.",
        images: ["udo/udo1.jpg"],
      },
      {
        id: 3,
        title: "이코마 고원",
        subTitle:
          "미야자키현 제이스 C.C 니치난 리조트 및 고바야시 코스 인접 지역",
        summary:
          "이코마 고원은 키리시마의 완만한 산맥에 퍼지는 해발 490m의 고원으로 배후에는 가라쿠니다케등 키리시마의 산들과 코스모스지대가 전방에는 분지가 한 눈에 보이며, 멀리는 큐슈 산맥을 볼 수 있습니다. 코스모스의 미로, 고백의 소경, 두 사람만의 전망대 등 화단 안에 들어가 기념 사진을 찍을 수 있습니다.",
        images: ["ikoma/ikoma1.jpg"],
      },
      {
        id: 4,
        title: "에비노 고원과 한국악(韓國岳 - 가라쿠니다케)",
        subTitle:
          "미야자키현 제이스 C.C 니치난 리조트 및 고바야시 코스 인접 지역",
        summary:
          "에비노 고원에 있는 산으로부터 분출하는 연기 안의 유황산의 황산 가스로 인해 가을이 되면 고원의 참억새가 '새우색'이 되어 '새우의'라고 하는 이름이 되었으며 가라쿠니다케(韓國岳)로 올라가는 길은 울창한 나무들로 가득하여 키리시마 산맥의 최고봉(해발 1,700m)으로 멀리 한국땅이 보인다고 해서 붙여진 이름이라고 합니다.  ",
        images: ["garakunidake/garakunidake1.jpg"],
      },
      {
        id: 5,
        title: "칸고노 온천",
        subTitle:
          "미야자키현 제이스 C.C 니치난 리조트 및 고바야시 코스 인접 지역",
        summary:
          "고바야시 골프장에 가장 가까운 온천으로 이동시간은 차량으로 약 10분 정도 소요되며, 100% 원천이 뿜어져 나오는 온천으로 피부미용과 근육통에 효과가 뛰어납니다. 분당 1.3톤의 유량이 뿜어져 나오는 것이 특징입니다.",
        images: ["kangono/kangono1.jpg"],
      },
      {
        id: 6,
        title: "테루하대적교",
        subTitle: "미야자키현 제이스 C.C 고겐코스 36 및 휴가코스 인접지역",
        summary:
          "고겐코스에서 35분 거리, 세계 최대급의 보도 현수교로서 길이 250m, 높이 142m에서 바라보는 조엽수림은 큐슈 산지 국정공원으로 지정되었습니다.",
        images: ["teruhade/teruhade1.jpg"],
      },
      {
        id: 8,
        title: "타카치호 협곡",
        subTitle: "미야자키현 제이스 C.C 고겐코스 36 및 휴가코스 인접지역",
        summary:
          "아소의 화산에서 분출된 용암의 침식으로 만들어진 협곡으로, 최고 100m, 평균 80m의 높이에 길이는 약 7km에 달하는 주상절리 협곡입니다. 일본 폭포 백선에 뽑힌 '마나이 폭포'가 자리잡고 있고, 봄과 여름에는 녹음이 우거지고 가을에는 단풍으로 물드는 모습이 아름답기로 소문나 미야자키 최고의 관광 명소로 손꼽힐 정도 입니다.",
        images: ["takachiho/takachiho1.jpg"],
      },
      {
        id: 9,
        title: "백제마을",
        subTitle: "미야자키현 제이스 C.C 고겐코스 36 및 휴가코스 인접지역",
        summary:
          "백제의 수도였던 부여의 왕궁터에 세워진 국립박물관의 객사를 모델로 한국의 명공들에 의해 복원되었습니다. 서쪽에 자리한 정창원은 나라에 위치한 정창원의 설계도대로 노송나무를 사용해 똑같이 만들어졌으며, 내부에는 백제의 유물인 청동거울 등이 전시되어 있고 백제 왕족의 역사와 전설을 소개하고 있습니다.",
        images: ["bakje/bakje1.jpg"],
      },
      {
        id: 10,
        title: "센간엔(이소정원)",
        subTitle: "가고시마현 제이스 C.C 가고야코스 인접지역",
        summary:
          "센간엔은 사쿠라지마를 석가산으로 하고 가고시마만을 연못으로 가정하여 조성한 차경정원으로 1658년 시마즈 가문의 제 19대 영주인 시마즈 미쓰히사의 별장으로 건축되었습니다. 일본 전통 정원미를 주제로 하고 있으나 중국과 류쿠문화가 어울러져 있습니다.",
        images: ["senganen/senganen1.jpg"],
      },
      {
        id: 11,
        title: "사쿠라지마",
        subTitle: "가고시마현 제이스 C.C 가고야코스 인접지역",
        summary:
          "예전에는 문자 그대로 섬이었지만 1914년 분화에 의해 오스미 반도와 연결되어 육지가 됩니다. 현재도 계속 활동을 하고 있는 활화산으로 페리를 이용해 15분이면 섬으로 이동할 수 있으며, 전망대 및 족욕탕 등의 관광코스가 있습니다.",
        images: ["sakurajima/sakurajima1.jpg"],
      },
      {
        id: 12,
        title: "후루사토 온천",
        subTitle: "가고시마현 제이스 C.C 가고야코스 인접지역",
        summary:
          "사쿠라지마 내에 있는 온천으로 바닷가 바로 옆에 노천온천이 있어 전망이 뛰어나고 이색적입니다. 나트륨이 풍부한 수질로 위장장애 및 신경통, 요통에 특효가 있습니다.",
        images: ["hurusato/hurusato1.jpg"],
      },
    ],
  },
  thailand: {
    name: "thailand",
    title: "태국",
    subTitle1: "보석과 같은 숨겨진 골프장이 있는 만능 여행지, 태국",
    subTitle2: "보석과 같은 숨겨진 골프장이 있는 만능 여행지, 태국",

    package: [
      {
        id: 0,
        title: "태국 비수기 골프상품(2022.04.01~2022.10.31)",
        subTitle: "<br />5성급호텔 3박5일+매일 18홀 선택",
        price: "800000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "리조트(2인1실, 룸타입-어번 딜럭스), 미팅/샌딩비, 그린피18홀, 카트피, 캐디피, 조식, 신속항원검사비용.",
        img: "package_thailand_00_04_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle:
              "태국 비수기 골프상품(2022.04.01~2022.10.31) <br />5성급호텔 3박5일+매일 18홀 선택",
            subTitle:
              "<br />1) 5성플러스 골프장 - 시암 올드/뉴 플랜테이션 / 워터사이드 / 롤링힐즈 중 1곳 <br />2) 5성 골프장 - 람차방 / 치짠 골프장 중 1곳 <br />3) 4성 골프장 - 피닉스 / 세인트앤드류 / 부라파 / 라용그린밸리 중 1곳",
            images: [],
            price: "800000",
            unit: "원",
            optionTitle1: "미트 호텔 혹은 동급 + 3색 골프",
            optionTitle2: "파타야 3색 골프 여행",
            include:
              "리조트(2인1실, 룸타입-어번 딜럭스), 미팅/샌딩비, 그린피18홀, 카트피, 캐디피, 조식, 신속항원검사비용.",
            exclude:
              "왕복항공권, 캐디팁, 전일정 중식, 석식 자유식, 기타 개인경비, 에티켓팁, 주말추가요금(시암/치짠/람차방-3만원/1인, 그외-2만원/1인)",
            itineraryDate: "3박 5일",
            itinerary: [
              "인천공항 출발 -> 수와나품 공항 도착 후 가이드 미팅 및 파타야 숙소로 이동(1시간 20분소요)",
              "호텔 조식 후 골프장 이동/선택 라운딩/석식 후 휴식",
              "일차 호텔 조식 후 골프장 이동/선택 라운딩/석식 후 휴식",
              "일차 호텔 조식 후 골프장 이동/선택 라운딩/방콕으로 이동 석식 후 공항 이동",
              "인천 공항 도착",
            ],
            warnText: [
              "호텔 싱글차지 6만원/1인,1박.<br />",
              "9홀 추가비용(그린피+전동차+캐디피) : 5성 - 100불, 4성- 70불.<br />",
              "상기금액은 환율 37로 적용(환율의 급격한 변동시 가격 변동이 있을 수 있음).<br />",
              "차량서비스(일반 일정 외 차량 이용시 별도의 차량이용료가 있음).<br />",
              "상기금액은 4인기준 1인금액입니다. 2인출발시 5만원/1인, 3인출발시 2만원/1인 추가요금이 있습니다.<br />",
            ],
          },
        ],
      },
      {
        id: 1,
        title: "태국 비수기 골프상품(2022.04.01~2022.10.31)",
        subTitle: "<br />4성급 호텔 3박5일 + 매일 18홀 선택",
        price: "700000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "리조트(2인1실), 미팅/샌딩비, 그린피18홀, 카트피, 캐디피, 조식, 신속항원검사비용.",
        img: "package_thailand_00_05_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle:
              "태국 비수기 골프상품(2022.04.01~2022.10.31) <br />4성급 호텔 3박5일 + 매일 18홀 선택",
            subTitle:
              "<br />1) 5성 골프장 - 람차방 1곳 <br />2) 4성 골프장 - 피닉스 / 세인트앤드류 / 부라파 / 라용그린밸리 중 2곳",
            images: [],
            price: "700000",
            unit: "원",
            optionTitle1: "아덴호텔 앤 레지던스 혹은 동급 + 3색 골프",
            optionTitle2: "파타야 3색 골프 여행",
            include:
              "리조트(2인1실), 미팅/샌딩비, 그린피18홀, 카트피, 캐디피, 조식, 신속항원검사비용.",
            exclude:
              "왕복항공권, 캐디팁, 전일정 중식, 석식 자유식, 기타 개인경비 에티켓팁, 주말추가요금(시암/치짠/람차방-3만원/1인, 그외-2만원/1인)",
            itineraryDate: "3박 5일",
            itinerary: [
              "인천공항 출발 -> 수와나품 공항 도착 후 가이드 미팅 및 파타야 숙소로 이동(1시간 20분소요)",
              "호텔 조식 후 골프장 이동/선택 라운딩/석식 후 휴식",
              "일차 호텔 조식 후 골프장 이동/선택 라운딩/석식 후 휴식",
              "일차 호텔 조식 후 골프장 이동/선택 라운딩/방콕으로 이동 석식 후 공항 이동",
              "인천 공항 도착",
            ],
            warnText: [
              "호텔 싱글차지 4만원/1인,1박.<br />",
              "9홀 추가비용(그린피+전동차+캐디피) : 5성 - 100불, 4성- 70불.<br />",
              "상기금액은 환율 37로 적용(환율의 급격한 변동시 가격 변동이 있을 수 있음).<br />",
              "차량서비스(일반 일정 외 차량 이용시 별도의 차량이용료가 있음).<br />",
              "상기금액은 4인기준 1인금액입니다. 2인출발시 5만원/1인, 3인출발시 2만원/1인 추가요금이 있습니다.<br />",
            ],
          },
        ],
      },
      {
        id: 2,
        title: "태국 비수기 골프상품(2022.04.01~2022.10.31)",
        subTitle: "<br />풀빌라 3박5일 + 매일 18홀 선택",
        price: "950000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "리조트(4베드룸), 미팅/샌딩비, 그린피18홀, 카트피, 캐디피, 조식, 신속항원검사비용.",
        img: "package_thailand_00_06_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle:
              "태국 비수기 골프상품(2022.04.01~2022.10.31) <br />풀빌라 3박5일 + 매일 18홀 선택",
            subTitle:
              "<br />1) 5성플러스 골프장 - 시암 올드/뉴 플랜테이션 / 워터사이드 / 롤링힐즈 중 1곳 <br />2) 5성 골프장 - 람차방 / 치짠 골프장 중 1곳 <br />3) 4성 골프장 - 피닉스 / 세인트앤드류 / 부라파 / 라용그린밸리 중 1곳",
            images: [],
            price: "950000",
            unit: "원",
            optionTitle1: "다빈치 풀빌라 혹은 동급 + 3색 골프",
            optionTitle2: "파타야 3색 골프 여행",
            include:
              "리조트(4베드룸), 미팅/샌딩비, 그린피18홀, 카트피, 캐디피, 조식, 신속항원검사비용.",
            exclude:
              "왕복항공권, 캐디팁, 전일정 조식, 중식, 석식 자유식, 기타 개인경비, 에티켓팁, 주말추가요금(시암/치짠/람차방-3만원/1인, 그외-2만원/1인)",
            itineraryDate: "3박 5일",
            itinerary: [
              "인천공항 출발 -> 수와나품 공항 도착 후 가이드 미팅 및 파타야 숙소로 이동(1시간 20분소요)",
              "호텔 조식 후 골프장 이동/선택 라운딩/석식 후 휴식",
              "일차 호텔 조식 후 골프장 이동/선택 라운딩/석식 후 휴식",
              "일차 호텔 조식 후 골프장 이동/선택 라운딩/방콕으로 이동 석식 후 공항 이동",
              "인천 공항 도착",
            ],
            warnText: [
              "9홀 추가비용(그린피+전동차+캐디피) : 5성 - 100불, 4성- 70불.<br />",
              "상기금액은 환율 37로 적용(환율의 급격한 변동시 가격 변동이 있을 수 있음).<br />",
              "차량서비스(일반 일정 외 차량 이용시 별도의 차량이용료가 있음).<br />",
              "상기금액은 4인기준 1인금액입니다. 2인출발시 5만원/1인, 3인출발시 2만원/1인 추가요금이 있습니다.<br />",
              "풀빌라 상품은 조식이 불포함입니다.<br />",
            ],
          },
        ],
      },
      {
        id: 3,
        title: "파타야 최고급 시암 54홀 골프 여행",
        subTitle:
          "빌라 와니다 리조트 + 시암컨트리 뉴/워터사이드/올드 코스(LPGA HONDA CLASSIC COURSE)",
        price: "810000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "리조트(2인 1실), 미팅/샌딩비, 그린피 54홀, 카트피, 캐디피, 캐디팁, 조식, 석식 포함, 한국인 가이드",
        img: "package_thailand_00_00_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "파타야 최고급 시암 54홀 골프 여행",
            subTitle:
              "빌라 와니다 리조트 + 시암컨트리 뉴/워터사이드/올드 코스(LPGA HONDA CLASSIC COURSE): 총 54홀",
            images: [],
            price: "810000",
            unit: "원",
            optionTitle1: "빌라 와니다 리조트 + 시암컨트리 54홀",
            optionTitle2: "파타야 명문 시암 54홀",
            include:
              "리조트(2인 1실), 미팅/샌딩비, 그린피 18홀, 카트피, 캐디피, 캐디팁, 조식, 석식 포함, 한국인 가이드",
            exclude: "왕복항공권, 여행자 보험, 중식, 에티켓 팁, 개인경비",
            itineraryDate: "3박 5일",
            itinerary: [
              "[인천/부산 공항] 출발 > [방콕 수완나폼 국제공항] 도착 ('B'게이트 4번출구에서 미팅) 파타야 리조트로 이동(약 1시간 10분), 호텔 투숙(빌라 와니다 리조트)",
              "호텔 조식 후 골프장 이동/ 시암컨트리 뉴 코스 C.C 18홀 라운딩/ 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/ 시암컨트리 워터사이드 코스 C.C 18홀 라운딩/ 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/ 시암컨트리 올드코스 C.C (LPGA HONDA CLASSIC COURSE) 18홀 라운딩 / 석식(현지식 씨푸드) 후 공항으로 이동",
              "인천/부산 국제공항 도착 후 해산",
            ],
            warnText: [
              "상기일정은 항공, 현지사정에 따라 다소 변경될 수 도 있습니다.<br />",
              "호텔 싱글차지 - (30,000원/1인, 1박)<br />",
              "차량 서비스 (일반 일정 외 추가 이동 시 거리정산에 따른 유류대 별도 추가)<br />",
              "상기 금액은 환율 35로 적용(환율의 급격한 변동 시 변동이 있을 수 있음)<br />",
              "상기금액은 4인기준 1인 금액입니다.<br />",
            ],
          },
        ],
      },
      {
        id: 4,
        title: "파타야 명문 3색 54홀 골프 여행",
        subTitle:
          "빌라 와니다 리조트 + 시암컨트리 뉴/워터사이드/올드 코스(LPGA HONDA CLASSIC COURSE) + 람차방 인터내셔날 + 피닉스 골드",
        price: "750000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "리조트(2인 1실), 미팅/샌딩비, 그린피 54홀, 카트피, 캐디피, 캐디팁, 조식, 석식 포함, 한국인 가이드",
        img: "package_thailand_00_01_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "파타야 명문 3색 54홀 골프 여행",
            subTitle:
              "빌라 와니다 리조트 + 시암컨트리 뉴/워터사이드/올드 코스(LPGA HONDA CLASSIC COURSE) + 람차방 인터내셔날 + 피닉스 골드 C.C",
            images: [],
            price: "750000",
            unit: "원",
            optionTitle1: "빌라 와니다 리조트 + 3색 골프 54홀",
            optionTitle2: "파타야 명문 3색",
            include:
              "리조트(2인 1실), 미팅/샌딩비, 그린피 18홀, 카트피, 캐디피, 캐디팁, 조식, 석식 포함, 한국인 가이드",
            exclude: "왕복항공권, 여행자 보험, 중식, 에티켓 팁, 개인경비",
            itineraryDate: "3박 5일",
            itinerary: [
              "[인천/부산 공항] 출발 > [방콕 수완나폼 국제공항] 도착 ('B'게이트 4번출구에서 미팅) 파타야 리조트로 이동(약 1시간 10분), 호텔 투숙(빌라 와니다 리조트)",
              "호텔 조식 후 골프장 이동/ 시암컨트리 올드코스 C.C (LPGA HONDA CLASSIC COURSE) 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/ 람차방 인터내셔날 골프 C.C 18홀 라운딩/ 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/ 피닉스 골드 코스 C.C 18홀 라운딩 / 석식(현지식 씨푸드) 후 공항으로 이동",
              "인천/부산 국제공항 도착 후 해산",
            ],
            warnText: [
              "상기일정은 항공, 현지사정에 따라 다소 변경될 수 도 있습니다.<br />",
              "호텔 싱글차지 - (30,000원/1인, 1박)<br />",
              "차량 서비스 (일반 일정 외 추가 이동 시 거리정산에 따른 유류대 별도 추가)<br />",
              "상기 금액은 환율 35로 적용(환율의 급격한 변동 시 변동이 있을 수 있음)<br />",
              "상기금액은 4인기준 1인 금액입니다.<br />",
            ],
          },
        ],
      },
      {
        id: 5,
        title: "파타야 3색 54홀 골프 여행",
        subTitle:
          "빌라 와니다 리조트 + 피닉스 골드 + 파타나 골프 + 부라파 골프",
        price: "750000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "리조트(2인 1실), 미팅/샌딩비, 그린피 54홀, 카트피, 캐디피, 캐디팁, 조식, 석식 포함, 한국인 가이드",
        img: "package_thailand_00_02_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "파타야 명문 3색 54홀 골프 여행",
            subTitle:
              "빌라 와니다 리조트 + 피닉스 골드 C.C + 파타나 골프 C.C + 부라파 골프 C.C",
            images: [],
            price: "720000",
            unit: "원",
            optionTitle1: "빌라 와니다 리조트 + 3색 골프",
            optionTitle2: "파타야 3색 골프 여행",
            include:
              "리조트(2인 1실), 미팅/샌딩비, 그린피 18홀, 카트피, 캐디피, 캐디팁, 조식, 석식 포함, 한국인 가이드",
            exclude: "왕복항공권, 여행자 보험, 중식, 에티켓 팁, 개인경비",
            itineraryDate: "3박 5일",
            itinerary: [
              "[인천/부산 공항] 출발 > [방콕 수완나폼 국제공항] 도착 ('B'게이트 4번출구에서 미팅) 파타야 리조트로 이동(약 1시간 10분), 호텔 투숙(빌라 와니다 리조트)",
              "호텔 조식 후 골프장 이동/  피닉스 골드 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/  파타나 골드 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/  부라파 골드 C.C 18홀 라운딩 / 석식(현지식 씨푸드) 후 공항으로 이동",
              "인천/부산 국제공항 도착 후 해산",
            ],
            warnText: [
              "상기일정은 항공, 현지사정에 따라 다소 변경될 수 도 있습니다.<br />",
              "호텔 싱글차지 - (30,000원/1인, 1박)<br />",
              "차량 서비스 (일반 일정 외 추가 이동 시 거리정산에 따른 유류대 별도 추가)<br />",
              "상기 금액은 환율 35로 적용(환율의 급격한 변동 시 변동이 있을 수 있음)<br />",
              "상기금액은 4인기준 1인 금액입니다.<br />",
            ],
          },
        ],
      },
      {
        id: 6,
        title: "파타야 초특가 3색 54홀 골프 여행",
        subTitle:
          "빌라 와니다 리조트 + 파타나 골프 + 세인트앤드류 2000 + 부라파 골프",
        price: "690000",
        unit: "원",
        perPrice: "4인기준 1인요금",
        summary:
          "리조트(2인 1실), 미팅/샌딩비, 그린피 54홀, 카트피, 캐디피, 캐디팁, 조식, 석식 포함, 한국인 가이드",
        img: "package_thailand_00_03_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle: "파타야 초특가 3색 54홀 골프 여행",
            subTitle:
              "빌라 와니다 리조트 + 파타나 골프 C.C + 세인트앤드류 2000 C.C + 부라파 골프 C.C",
            images: [],
            price: "690000",
            unit: "원",
            optionTitle1: "빌라 와니다 리조트 + 초특가 3색 골프 54홀",
            optionTitle2: "파타야 초특가 3색",
            include:
              "리조트(2인 1실), 미팅/샌딩비, 그린피 18홀, 카트피, 캐디피, 캐디팁, 조식, 석식 포함, 한국인 가이드",
            exclude: "왕복항공권, 여행자 보험, 중식, 에티켓 팁, 개인경비",
            itineraryDate: "3박 5일",
            itinerary: [
              "[인천/부산 공항] 출발 > [방콕 수완나폼 국제공항] 도착 ('B'게이트 4번출구에서 미팅) 파타야 리조트로 이동(약 1시간 10분), 호텔 투숙(빌라 와니다 리조트)",
              "호텔 조식 후 골프장 이동/  파타나 골드 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/  세인트앤드류 2000 C.C 18홀 라운딩 / 석식 후 리조트 투숙 및 휴식",
              "호텔 조식 후 골프장 이동/  부라파 골드 C.C 18홀 라운딩 / 석식(현지식 씨푸드) 후 공항으로 이동",
              "인천/부산 국제공항 도착 후 해산",
            ],
            warnText: [
              "상기일정은 항공, 현지사정에 따라 다소 변경될 수 도 있습니다.<br />",
              "호텔 싱글차지 - (30,000원/1인, 1박)<br />",
              "차량 서비스 (일반 일정 외 추가 이동 시 거리정산에 따른 유류대 별도 추가)<br />",
              "상기 금액은 환율 35로 적용(환율의 급격한 변동 시 변동이 있을 수 있음)<br />",
              "상기금액은 4인기준 1인 금액입니다.<br />",
            ],
          },
        ],
      },
    ],
    golf: [
      {
        id: 0,
        name: "라용 그린밸리 (Rayong) Green Valley Country Club",
        location: "방콕에서 차량으로 약 1시간 45분, 파타야에서 약 20분 소요.",
        holeNumbers: "18H/ Par 72/ 7,022y",
        facility: "드라이빙 레인지, 수영장, 마사지, 프로샵",
        summary:
          "라용그린밸리CC는 1992년에 개장한 골프장으로 다양한 나무들로 조경이 잘 되어 있고 뛰어난 경관으로 한국에서도 인기있는 골프 코스중의 하나입니다. 라용그린밸리는 매력적인 시골에 위치하여 아름다운 조경과 훌륭한 클럽하우스, 폴로클럽, 승마트랙이 있습니다.  ",
        images: [
          "rayong/rayong1.jpg",
          "rayong/rayong2.jpg",
          "rayong/rayong3.jpg",
          "rayong/rayong4.jpg",
          "rayong/rayong5.jpg",
          "rayong/rayong6.jpg",
          "rayong/rayong7.jpg",
          "rayong/rayong8.jpg",
        ],
      },
      {
        id: 1,
        name: "치짠 Chee Chan Golf Resort",
        location: "파타야 시내에서 남쪽으로 20km(약 35분 소요)",
        holeNumbers: "18H/ Par 72/ 6,928y",
        facility: "드라이빙 레인지, 퍼팅 그린, 치핑 그린, 프로샵",
        summary:
          "2018년 11월 오픈한 치짠 골프 리조트는 파타야의 고급 골프 클럽으로 파타야의 명문 골프장으로 자리 잡아가고 있는 중입니다. 태국의 유명한 볼거리인 KHAO CHEE CHAN BUDDHA가 내려다 보이는 완벽한 골프장으로 호수, 사원 및 바다의 절경을 한 눈에 볼 수 있는 다른 골프장에서는 감히 엄두도 못 낼 해방감을 줍니다.",
        images: [
          "cheechan/cheechan1.jpg",
          "cheechan/cheechan2.jpg",
          "cheechan/cheechan3.jpg",
          "cheechan/cheechan4.jpg",
          "cheechan/cheechan5.jpg",
          "cheechan/cheechan6.jpg",
          "cheechan/cheechan7.jpg",
          "cheechan/cheechan8.jpg",
        ],
      },
      {
        id: 2,
        name: "시암 플랜테이션 (Siam Country Club -Plantation)",
        location: "파타야 시내에서 약 20분, 방콕 시내에서 한시간 30분",
        holeNumbers: "27H/ Par 108/ 7,404y",
        facility: "",
        summary:
          "2008년에 18홀을 먼저 개장한 후 추후 9홀을 건설하여 총 27홀 규모를 갖추고 있는 골프장입니다. 시암 컨트리클럽의 new course로서 방콕 최고의 골프장답게 페어웨이 및 그린 상태가 좋으며 화려하면서도 깔끔한 클럽 하우스로 다양한 만족감을 드립니다. SIAM PLANTATION이라는 이름은 사탕수수, 타피오카 그리고 파인애플 농장의 이름에서 기인하였고 오픈 후 1년이 지난 2009년 2월에 혼다 LPGA 타일랜드 2009를 유치하여 뛰어난 골프코스로서의 서비스와 질을 인정받았으며, 이후 아시아, 태평양 지역 최고의 골프 코스로 선정되었습니다.",
        images: [
          "siamplantation/siamplantation1.jpg",
          "siamplantation/siamplantation2.jpg",
          "siamplantation/siamplantation3.jpg",
          "siamplantation/siamplantation4.jpg",
          "siamplantation/siamplantation5.jpg",
          "siamplantation/siamplantation6.jpg",
          "siamplantation/siamplantation7.jpg",
        ],
      },
      {
        id: 3,
        name: "시암 컨트리 클럽 - 워터사이드 (Siam Country Club - Waterside)",
        location: "방콕에서는 차로 약 1시간 30분",
        holeNumbers: "18H/ Par 72/ 7,407y",
        facility: "",
        summary:
          "2014년 5월 파타야 시암 플렌테이션 바로 옆에 새로 지어진 18홀 규모의 골프 코스입니다. 시암컨트리 클럽의 명성에 걸맞게 최신 시설과 환경으로 가장 최근에 지어진 클럽하우스와 식당은 모던함이 엿보이고 이름대로 물이 많은 골프코스 레이아웃과 알맞은 자리에 위치한 벙커 등이 재미와 묘미를 배가 시킬 수 있다는 평가를 받고 있고 아름다운 전경으로 마치 호수 한 중간에서 라운딩 하는 듯한 느낌을 라운딩 내내 받을 수 있습니다.",
        images: [
          "siamwaterside/siamwaterside1.jpg",
          "siamwaterside/siamwaterside2.jpg",
          "siamwaterside/siamwaterside3.jpg",
          "siamwaterside/siamwaterside4.jpg",
          "siamwaterside/siamwaterside5.jpg",
          "siamwaterside/siamwaterside6.jpg",
          "siamwaterside/siamwaterside7.jpg",
        ],
      },
      {
        id: 4,
        name: "시암 컨트리 클럽 - 올드코스 (Siam Country Club - Old Course)",
        location:
          "파타야 시내에서 약 25분 / 방콕 수완나폼 공항에서 한시간 20분",
        holeNumbers: "18H/ Par 72/ 7,162y",
        facility: "",
        summary:
          "시암 컨트리클럽 올드코스는 1971년 개장한 태국 최초의 회원제 골프클럽이었는데 2007년 대대적인 리노베이션을 거친 후 old course로 새롭게 개장하였으며, 2007년 12월 LPGA 경기를 개최하면서 명실상부 파타야 최고의 골프장으로 발돋움하였고 지금까지 매년 혼다 LPGA 타이랜드 대회를 개최해 오고 있고 클럽하우스도 최근에 지어 더욱 매력적인 골프장입니다.",
        images: [
          "siamold/siamold1.jpg",
          "siamold/siamold2.jpg",
          "siamold/siamold3.jpg",
          "siamold/siamold4.jpg",
          "siamold/siamold5.jpg",
          "siamold/siamold6.jpg",
          "siamold/siamold7.jpg",
          "siamold/siamold8.jpg",
        ],
      },
      {
        id: 5,
        name: "피닉스 골드 C.C (Phoenix Gold Golf & Country Club)",
        location: "시내로부터 30분, 공항으로부터 약 1시간 40분",
        holeNumbers: "27H/ Par 108/ 9,658y",
        facility: "",
        summary:
          "피닉스 골프클럽은 1993년에 오픈한 총 27홀 골프장으로 각각 9홀로 이루어진 오션, 레이크, 마운틴 3개의 특색 있는 코스를 갖추고 있습니다. 산, 호수, 시원한 바다 경관이 잘 어우러져 있는 골프장으로 페어웨이가 좁긴 하지만 그린은 넓은 편이며 레이크 코스는 수많은 워터헤저드를 갖춰 굉장히 어려운 코스로 꼽힙니다. 새로 지은 클럽 하우스와 깔끔한 관리 상태, 수려한 경관이 더해져 동급의 다른 골프장에 비교해 충분히 경쟁력이 있고 오션 코스의 마지막 홀에서는 파타야의 바다도 내려 다 볼 수 있습니다.",
        images: ["phoenix/phoenix1.jpg", "phoenix/phoenix2.jpg"],
      },
      {
        id: 6,
        name: "파타나 골프 C.C (Pattana Golf Club & Resort)",
        location: "파타야 시내에서 40분, 방콕 수완나폼 공항에서 약 한시간 30분",
        holeNumbers: "27H/ Par 145/ 14,500y",
        facility: "",
        summary:
          "파타나 골프클럽은 2004년에 오픈한 총 27홀 골프장으로 파3 전체 6개홀이 아이랜드 홀인 것이 파타나 골프장의 가장 큰 특징 중의 하나입니다. 숲과 워터헤저드로 둘러싸인 레이아웃으로 자연속에서 라운드를 할 수 있도록 설계되어 있으며 페어웨이 업다운이 많아 한국 골프장을 연상케 합니다.  지리적으로 산에 둘러 쌓여 있으며 구릉과 시원한 바람의 조화로 인해 인근 골프장에 비해 다소 시원한 기후를 제공합니다.",
        images: [
          "pattana/pattana1.jpg",
          "pattana/pattana2.jpg",
          "pattana/pattana7.jpg",
          "pattana/pattana8.jpg",
          "pattana/pattana9.jpg",
          "pattana/pattana10.jpg",
          "pattana/pattana11.jpg",
          "pattana/pattana12.jpg",
          "pattana/pattana13.jpg",
        ],
      },
      {
        id: 7,
        name: "세인트앤드류2000 C.C (St.Andrews 2000)",
        location: "방콕에서 약 1시간 45분",
        holeNumbers: "18H/ Par 74/ 7,686y",
        facility: "",
        summary:
          "세인트앤드류CC는 수완나품 국제공항에서 약 1시간 20분 거리에 위치해 있으며 세계적으로 유명한 스코트랜드의 세인트 앤드류 골프장을 모티브로 재현된 코스로 태국의 대표 골프장입니다. 뛰어난 경관과 자연스럽게 굽이치는 기복이 많은 자연적 지형, 차별화된 페어웨이, 까다로운 지형지물로 어느 골프장에서도 볼 수 없었던 남성적인 매력을 가지고 있고 난이도가 있어 진정한 골퍼들을 위한 골프장입니다.",
        images: [
          "standrews/standrews1.jpg",
          "standrews/standrews2.jpg",
          "standrews/standrews3.jpg",
          "standrews/standrews4.jpg",
          "standrews/standrews5.jpg",
          "standrews/standrews6.jpg",
          "standrews/standrews7.jpg",
          "standrews/standrews8.jpg",
        ],
      },
      {
        id: 8,
        name: "부라파 골프 C.C (Burapha Golf Club)",
        location: "방콕에서 차로 약 1시간 30분",
        holeNumbers: "36H/ Par 74/ 6,948y",
        facility: "",
        summary:
          "1995년에 개장한 부라파GC는 아름답고 평화로운 전경의 전형적인 태국 골프장인 부라파 골프 클럽은 방콕 수완나품 공항에서 70분정도 소요되는 지역에 위치하고 있습니다. PGA와 TPC등 메이저대회를 개최한 부라파GC는 동서쪽으로 코스가 나뉘어져 있어 색다른 매력을 가지고 있으며 총 36홀의 대규모로 다양한 코스가 구성되어 있어 지루하지 않고 자연지형을 최대한 살려 코스 전체가 도전적이고 주변 경관이 수려하고 자연 친화적인 골프장으로 태국을 대표하는 부라파GC는 100만평 규모의 최고의 자연지에 위치한 정규 36홀 골프장입니다.",
        images: [
          "burapha/burapha1.jpg",
          "burapha/burapha2.jpg",
          "burapha/burapha3.jpg",
          "burapha/burapha4.jpg",
          "burapha/burapha5.jpg",
          "burapha/burapha6.jpg",
          "burapha/burapha7.jpg",
          "burapha/burapha8.jpg",
          "burapha/burapha9.jpg",
          "burapha/burapha10.jpg",
          "burapha/burapha11.jpg",
        ],
      },
      {
        id: 9,
        name: "람차방 인터내셔날 골프 C.C (Laem Chabang International Country Club)",
        location: "방콕에서 차로 약 1시간 30분",
        holeNumbers: "27H/ Par 108/ 6,865y",
        facility: "",
        summary:
          "1995년에 개장한 람차방CC는 골프황제 잭 니클라우스가 설계하고 1995년에 개장한 이후 지금까지 태국 내 TOP10 베스트 코스에 늘 선정되는 골프장으로 태국 골퍼 뿐만 아니라 한국 골퍼들에게도 사랑받는 골프장입니다. 람차방CC는 총 27홀 골프장으로 바위, 호수, 연못, 계곡 등 자연의 지형적 특징을 잘 살린 코스로 골퍼들에게 도전심을 불러일으키는 코스로 정평이 나 있으며 태국 내에서도 재미있고 어려운 코스로 오래전부터 많은 인기가 있는 명문 골프장입니다.",
        images: [
          "laemchabang/laemchabang1.jpg",
          "laemchabang/laemchabang2.jpg",
          "laemchabang/laemchabang3.jpg",
          "laemchabang/laemchabang4.jpg",
          "laemchabang/laemchabang5.jpg",
          "laemchabang/laemchabang6.jpg",
          "laemchabang/laemchabang7.jpg",
          "laemchabang/laemchabang8.jpg",
        ],
      },
    ],
    hotel: [
      {
        id: 0,
        mainTitle: "아덴호텔 앤 레지던스",
        localeTitle: "Arden Hotel and Residence",
        subTitle: "(구, 앳마인드 익스클루시브) 센트럴 파타야에 위치",
        summary:
          "센트럴 파타야에 위치하고 2010년에 오픈한 4성급 레지던스형 호텔인 Arden Hotel & Residence는 총 141개의 객실을 갖추고 있으며 가성비로 보나 위치로 보나 여러 면에서 평가가 좋은 4성급 호텔입니다. 이 호텔은 레지던스답게 모든 객실에는 조리가 가능한 인덕션, 전자레인지 그리고 세탁기 등 주방시설이 비교적 잘 갖춰져 있고 모던하고 심플하게 설계가 되어 있어서 가족, 친구들과 여행 그리고 골프팀 등이 많이 이용하는 호텔이며 가격대비 만족도가 높은 호텔입니다.",
        mainImage: "arden/arden1.jpg",
        images: [
          "arden/arden2.jpg",
          "arden/arden3.jpg",
          "arden/arden4.jpg",
          "arden/arden5.jpg",
        ],
      },
      {
        id: 1,
        mainTitle: "미트 비치 호텔",
        localeTitle: "Mytt Beach Hotel Pattaya",
        subTitle: "파타야 비치로드 초입",
        summary:
          "에이원 호텔 그룹에서 2017년에 새로 런칭한 5성급 호텔로 좋은 위치와 모던한 시설, 5성급 대비 비교적 저렴한 호텔이고 파타야 비치로드에서 여행하기 편리한 호텔을 찾고 있다면 미트 비치 호텔이 가성비 대비 가장 좋을 것으로 생각됩니다. 그리고 요즘 로컬들과 파타야 관광객 사잉레서 한창 뜨고 있는 미트 비치 호텔 루프탑바 PIPPA RESTAUANT는 꼭 가야할 곳으로 인기몰이 중입니다. 또한 파타야 비치 초입에 있어서 위치가 좋은 편이고 알카자쇼나 티피니쇼 공연장이 인접해 있고 터미널21 쇼핑몰도 가까워 다 도보로 이용가능해서 교통이 편리한 편입니다.",
        mainImage: "mytt/mytt1.jpg",
        images: [
          "mytt/mytt3.jpg",
          "mytt/mytt4.jpg",
          "mytt/mytt5.jpg",
          "mytt/mytt8.jpg",
        ],
      },
      {
        id: 2,
        mainTitle: "풀만 방콕 그랜드 수쿰빗",
        localeTitle: "Pullman Bangkok Grande Sukhumvit",
        subTitle: "방콕 스쿰윗 아속 도심속, 아속역과 수쿰윗역 사이",
        summary:
          "방콕 스쿰윗 아속에 자리한 풀만 방콕 그랜드 스쿰윗은 아트리움과 컨셉있는 층을 갖춘 5성급 호텔입니다. 총 325개의 객실을 보유하고 있으며 여유로운 공간에 현대적인 감각의 디자인과 최신 설비의 편의시설로 꾸며져 있습니다. 호텔에서 도보로 아속 사거리까지 5분정도가 걸려 지상철 BTS 아속역과 지하철 MRT 수쿰윗역을 이용하기가 매우 편리하고 근처에 터민얼21, 한인타운인 스쿰윗 플라자가 위치하여 여행 초보자에게 추천할 만한 곳입니다. 전번적으로 타 5성 호텔보다 저렴한 가격의 5성 호텔을 원하시는 분들이 사용하기에 좋은 호텔입니다.",
        mainImage: "pullman/pullman1.jpg",
        images: [
          "pullman/pullman2.jpg",
          "pullman/pullman7.jpg",
          "pullman/pullman8.jpg",
          "pullman/pullman9.jpg",
        ],
      },
      {
        id: 3,
        mainTitle: "샤마 레이크뷰 아속 방콕",
        localeTitle: "Shama Lakeview Asoke Bangkok",
        subTitle: "(구 서머셋 레이크포인트 스쿰윗) 아속역 인근",
        summary:
          "샤마 레이크뷰 아속 방콕은 아속역 인근에 위치한 애스콧 계열의 4성급 레지던스로 객실이 비교적 크고 주방시설이 완비되어 있어서 편리할 뿐 아니라 위치도 좋은 편이어서 가격대비 만족도가 높은 호텔 중의 하나입니다. BTS 아속역 또는 MRT 수쿰윗 역에서 도보로 약 10분이 소요되어 교통도 편리하고 셔틀버스를 이용하여 손쉽게 역까지 이동할 수 있어 다른 지역으로 이동이 용이합니다.  주변에 터미널21, 엠포리엄, 엠쿼티어 백화점 등이 있어 쇼핑하기에 편리합니다.  ",
        mainImage: "shama/shama2.jpg",
        images: [
          "shama/shama6.jpg",
          "shama/shama7.jpg",
          "shama/shama9.jpg",
          "shama/shama10.jpg",
        ],
      },
      {
        id: 4,
        mainTitle: "다빈치 풀빌라",
        localeTitle: "Davinci Pool Villa",
        subTitle: "Pattaya City, Bang Lamung District, Chon Buri 20150",
        summary:
          "파타야 중심가에 있는 한국인이 운영하는 신축 풀빌라. 매일 오후에 수영장물 체크까지 해주시며, 시암 CC가 가까워 골프휴양에 좋습니다.",
        mainImage: "davinci/davinci1.jpg",
        images: [
          "davinci/davinci2.jpg",
          "davinci/davinci3.jpg",
          "davinci/davinci4.jpg",
          "davinci/davinci5.jpg",
        ],
      },
      {
        id: 5,
        mainTitle: "맨하탄 풀빌라",
        localeTitle: "Manhattan Pool Villa",
        subTitle: "Pattaya City, Chaloem Phrakiat 1, 20150",
        summary:
          "파타야 비치에서 14분 거리의 2015년 오픈한 파타야최대 쇼핑몰 터미널 21과 가까운 관광과 휴양을 동시에 즐길 수 있는 풀빌라입니다.",
        mainImage: "manhattan/manhattan1.jpg",
        images: [
          "manhattan/manhattan2.jpg",
          "manhattan/manhattan3.jpg",
          "manhattan/manhattan4.jpg",
          "manhattan/manhattan5.jpg",
        ],
      },
    ],
    enjoy: [
      {
        id: 0,
        title: "반 수카와디(천국의 집)",
        subTitle:
          "사하그룹 초대회장 개인소유의 대저택으로 사후세계에 대한 꿈과 이상, 천국을 생각하며 건축하였다고 한다.",
        summary:
          "이곳은 태국의 치킨사업을 하는 사업가가 수많은 닭들의 희생을 기리기 위해 만들었다는 설이 있습니다.<br /> 아무튼 사후 세계에 대한 꿈과 이상이 담긴 궁전 같은 공간입니다.<br /> 입구부터 스케일이 웅장하고 멋들어진 동상들이 줄을 이어 서 있고 예쁘게 꾸며 놓은 공원이 정말 멋있습니다.",
        images: [
          "yacht/yacht1.jpg",
          "yacht/yacht2.jpg",
          "yacht/yacht3.jpg",
          "yacht/yacht4.jpg",
          "yacht/yacht5.jpg",
        ],
      },
      {
        id: 1,
        title: "진리의 성전(Sanctuary of Truth)",
        subTitle: "1981년부터 파타야에 건설 중인 거대 목조 건축물",
        summary:
          "나무로만 지어진 높이 105미터, 넓이 100미터의 거대한 성전으로<br /> 기둥은 170개로 오로지 나무로만 만들고 쇠못하나 사용하지 않고 전부 손으로 직접 만들어 나가는 20층 규모의 사원입니다.<br /> 3개의 출입문을 통해 성전의 안으로 들어갈 수 있는데 크메르관, 힌두관, 태국관, 중국 대승 불교관으로 불리는 건물들은 각각의 종교관과 건축 양식을 반영하고 있습니다.",
        images: [
          "island/island1.jpg",
          "island/island2.jpg",
          "island/island3.jpg",
          "island/island4.jpg",
          "island/island5.jpg",
          "island/island6.jpg",
          "island/island7.jpg",
          "island/island8.jpg",
          "island/island9.jpg",
          "island/island10.jpg",
          "island/island11.jpg",
          "island/island12.jpg",
          "island/island13.jpg",
          "island/island14.jpg",
        ],
      },
      {
        id: 2,
        title: "농눅 빌리지",
        subTitle:
          "아시아에서 손꼽히는 식물원으로 코끼리쇼를 비롯해 세계 각지의 정원을 볼 수 있는 곳",
        summary:
          "농눅이라는 태국인 할머니의 개인 사유지로 500에이커의 부지를 잘 정돈하여 놓은 예쁜 공원입니다.<br /> 파타야에서 20분 거리에 위치해 있고 태국 전통 민속 공연과 코끼리쇼도 보여줍니다.<br /> 인공 정원이지만 꽃과 나무들이 어우러져 있고 산책로와 육교처럼 만들어 놓은 길이 있어서 걸어 다니면서 사진을 찍고 전망을 즐길 수가 있습니다.",
        images: [
          "puning/puning1.jpg",
          "puning/puning2.jpg",
          "puning/puning3.jpg",
          "puning/puning4.jpg",
          "puning/puning5.jpg",
          "puning/puning6.jpg",
          "puning/puning7.jpg",
          "puning/puning8.jpg",
          "puning/puning9.jpg",
          "puning/puning10.jpg",
        ],
      },
      {
        id: 3,
        title: "수파트라 과일 농장",
        subTitle: "라용 최대규모의 과일농장",
        summary:
          "파타야에서 한시간 정도 떨어진 라용에 위치한 수파트라 랜드 과일 농장은 엄청나게 큰 단일 과일 농장입니다.<br /> 각종 열대과일 따기, 고무 농장 체험과 벌꿀 농장 등 갖가지 체험과 무제한 과일 시식이 가능하고 마지막 코스로는 솜땀(태국식 매운 샐러드)을 만들고 시식까지 합니다.",
        images: [
          "kart/kart1.jpg",
          "kart/kart2.jpg",
          "kart/kart3.jpg",
          "kart/kart4.jpg",
          "kart/kart5.jpg",
          "kart/kart6.jpg",
          "kart/kart7.jpg",
          "kart/kart8.jpg",
        ],
      },
      {
        id: 4,
        title: "백만년 바위 공원과 악어농장",
        subTitle: "수억 년 된 웅장한 왕국 및 자연 조각품",
        summary:
          "악어 그리고 태국에서 찾아 보기 힘든 화석이란 두 가지 주제를 가진 테마 파크입니다.<br /> 공원 내부에는 1억년 이상 된 규화목을 볼 수 있고 신기하게 생긴 바위가 있는 지역에는 68,000kg가 넘는 바위도 있고 또한 악어 쇼에서는 악어와 사람이 입맞춤을 하는 곳으로 시작해 다양한 악어 쇼를 보여주고 있습니다.",
        images: [
          "croco/croco1.jpg",
          "croco/croco2.jpg",
          "croco/croco3.jpg",
          "croco/croco4.jpg",
          "croco/croco5.jpg",
          "croco/croco6.jpg",
          "croco/croco7.jpg",
          "croco/croco8.jpg",
        ],
      },
      {
        id: 5,
        title: "알카자 쇼",
        subTitle: "세계 3대쇼로 꼽히는 트렌스젠더쇼",
        summary:
          "미스 알카자 선발대회에서 선발된 트랜스젠더를 무대에 세워 다양한 노래와 춤을 선보입니다.<br /> 태국, 중국, 한국, 일본, 베트남, 말레이시아, 페르시아, 러시아 등 여러 국가의 전통 의상을 입고 나와 노래와 춤을 공연합니다.",
        images: [
          "alkasa/alkasa1.jpg",
          "alkasa/alkasa2.jpg",
          "alkasa/alkasa3.jpg",
          "alkasa/alkasa4.jpg",
          "alkasa/alkasa5.jpg",
          "alkasa/alkasa6.jpg",
        ],
      },
      /*   {
        id: 6,
        title: "파타야 수상시장",
        subTitle: "10만 제곱미터 규모의 세계 최대 수상시장",
        summary:
          "태국의 대표적인 수상시장은 담넌 사두억, 암퍼와, 그리고 파타야 수상시장입니다.<br /> 몇 년전 러닝맨에 나와서 인기가 많아진 곳이기도 합니다.<br /> 파타야 수상 시장은 2008년 11월에 오픈한 총 3천여평의 부지에 관광객 전용으로 인공 수로를 만들어 놓은 곳입니다.<br /> 배를 타고 한바퀴 돌아보면서 태국 전통음식과 기념품을 구경하는 특별한 경험을 할 수있는 곳입니다.",
        images: ["sm/sm1.jpg", "sm/sm2.jpg", "sm/sm3.jpg"],
      }, */
      {
        id: 6,
        title: "파타야 요트 크루즈 호핑 투어",
        subTitle: "요트 크루즈 호핑 투어",
        summary:
          "청정 비치에서 요트를 탑승하여 즐기는 여유로운 럭셔리 요트 크루즈 여행입니다.<br /> 에메랄드빛 바다 한가운데 요트를 정박해 놓고 줄낚시 체험과 호핑투어를 즐기고 물속에서 만나는 알록달록한 열대어를 볼 수 있는 스노클링 체험으로 특별한 추억을 만들 수 있습니다.<br /> 원숭이 섬에 가서 원숭이에게 먹이를 주기도 하고섬 주변에서 바다 스포츠를 즐기기도 합니다.<br /> 그리고 저녁에는 요트 선상에서 저녁 노을을 보면서 씨푸드 파티를 벌입니다.",
        images: [
          "cruz/cruz1.jpg",
          "cruz/cruz2.jpg",
          "cruz/cruz3.jpg",
          "cruz/cruz4.jpg",
          "cruz/cruz5.jpg",
          "cruz/cruz6.jpg",
          "cruz/cruz7.jpg",
          "cruz/cruz8.jpg",
        ],
      },
    ],
  },
  uae: {
    name: "United Arab Emirates",
    title: "두바이",
    subTitle1: "작렬하는 사막 태양 아래 이제 골프의 환상도 즐겨 보자, 두바이",
    subTitle2: "상상할 수 없었던 상쾌함은 뭐지, 두바이",

    package: [
      {
        id: 0,
        title: "두바이 성수기 패키지(2022.09.01~2023.04.30)",
        subTitle: "<br />전 일정 5성 호텔",
        price: "6900000",
        unit: "원 (VAT 포함)",
        perPrice: "1인요금",
        summary:
          "왕복 항공료, 5성 호텔, 전용차량 및 가이드, 여행자보험, 가이드 및 기사 팁 포함",
        img: "package_01_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle:
              "두바이 성수기 패키지(2022.09.01~2023.04.30, 전 일정 5성 호텔) <br />",
            subTitle:
              "<br />인천 두바이 왕복 항공료, 5성호텔 5박, 전용차량 및 가이드, 골프 라운딩 5회, 일정에 따른 관광, 여행자 보험, 가이드 및 기사 팁 포함",
            images: [],
            price: "6900000",
            unit: "원 (VAT 포함)",
            optionTitle1: "5성 호텔 5박 7일",
            optionTitle2: "두바이 골프 여행",
            include:
              "인천 두바이 왕복 항공료, 5성호텔 5박, 전용차량 및 가이드, 골프 라운딩 5회, 일정에 따른 관광, 여행자 보험, 가이드 및 기사 팁 포함",
            exclude: "",
            itineraryDate: "5박 7일",
            itinerary: [
              "인천공항 출발",
              "04:25 - 두바이 국제공항 도착(EK 323)후 가이드 미팅 <br />호텔 이동(Hilton Dubai AI Habtoor City Class) 체크인 및 휴식 <br />호텔 조식 후 Dubai Creek Golf Club 이동 <br />중식 후 휴식",
              "호텔 조식 후 골프장(Jumeirah Golf Estates - The Earth Course) 이동<br />15:00 - Desert Safari<br />21:00 - 두바이 귀환하여 휴식",
              "호텔 조식 후 골프장(Montgomerie Golf) 이동<br />오후 - 두바이 City Tour : 버즈알아랍, 머디나주메이라, 팜주메이라 아틀란티스, 아인두바이<br />21:00 - 석식 후 호텔 투숙",
              "호텔 조식 후 골프장(Abu Dhabi Golf) 이동 / 약 2시간 소요<br />오후 - 아부다비 City Tour : Etihad Tower 전망대, Emirates Palace호텔카페, 쉐이크자에드 모스크<br />21:00 - 두바이 귀환, 석식 후 투숙",
              "호텔 체크아웃 후 골프장(Emirates Golf Club - Faldo Course) 이동<br />오후 - 두바이 City Tour : 스키두바이(에미리트몰), 아인두바이(대관람차), 미래박물관, 두바이몰 투어<br />23:30 공항이동",
              "03:45 두바이 출발 -> 인천공항",
            ],
            warnText: [
              "중동 특성상 일반 캐디는 없고 2인 1조 카트를 직접 운전<br />",
              "카트는 페어웨이 진입가능(보수공사기간 제외)<br />",
              "인천 두바이 왕복 항공료, 5성 호텔 5박 (2인 1실 기준)<br />",
              "전용차량 및 가이드, 골프 라운딩 5회, 일정에 따른 관광 여행자 보험, 가이드 및 기사 팁 포함<br />",
            ],
          },
        ],
      },
      {
        id: 1,
        title: "두바이 비수기 패키지(2022.05.01~2023.08.30)",
        subTitle: "<br />전 일정 5성 호텔",
        price: "6300000",
        unit: "원 (VAT 포함)",
        perPrice: "1인요금",
        summary:
          "왕복 항공료, 5성 호텔, 전용차량 및 가이드, 여행자보험, 가이드 및 기사 팁 포함",
        img: "package_02_00.jpg",
        detailData: [
          {
            id: 0,
            isSale: true,
            mainTitle:
              "두바이 비수기 패키지(2022.05.01~2022.08.31, 전 일정 5성 호텔) <br />",
            subTitle:
              "<br />인천 두바이 왕복 항공료, 5성호텔 5박, 전용차량 및 가이드, 골프 라운딩 5회, 일정에 따른 관광, 여행자 보험, 가이드 및 기사 팁 포함",
            images: [],
            price: "6300000",
            unit: "원 (VAT 포함)",
            optionTitle1: "5성 호텔 5박 7일",
            optionTitle2: "두바이 골프 여행",
            include:
              "인천 두바이 왕복 항공료, 5성호텔 5박, 전용차량 및 가이드, 골프 라운딩 5회, 일정에 따른 관광, 여행자 보험, 가이드 및 기사 팁 포함",
            exclude: "",
            itineraryDate: "5박 7일",
            itinerary: [
              "인천공항 출발",
              "04:25 - 두바이 국제공항 도착(EK 323)후 가이드 미팅 <br />호텔 이동(Hilton Dubai AI Habtoor City Class) 체크인 및 휴식 <br />호텔 조식 후 Dubai Creek Golf Club 이동 <br />중식 후 휴식",
              "호텔 조식 후 골프장(Jumeirah Golf Estates - The Earth Course) 이동<br />15:00 - Desert Safari<br />21:00 - 두바이 귀환하여 휴식",
              "호텔 조식 후 골프장(Montgomerie Golf) 이동<br />오후 - 두바이 City Tour : 버즈알아랍, 머디나주메이라, 팜주메이라 아틀란티스, 아인두바이<br />21:00 - 석식 후 호텔 투숙",
              "호텔 조식 후 골프장(Abu Dhabi Golf) 이동 / 약 2시간 소요<br />오후 - 아부다비 City Tour : Etihad Tower 전망대, Emirates Palace호텔카페, 쉐이크자에드 모스크<br />21:00 - 두바이 귀환, 석식 후 투숙",
              "호텔 체크아웃 후 골프장(Emirates Golf Club - Faldo Course) 이동<br />오후 - 두바이 City Tour : 스키두바이(에미리트몰), 아인두바이(대관람차), 미래박물관, 두바이몰 투어<br />23:30 공항이동",
              "03:45 두바이 출발 -> 인천공항",
            ],
            warnText: [
              "중동 특성상 일반 캐디는 없고 2인 1조 카트를 직접 운전<br />",
              "카트는 페어웨이 진입가능(보수공사기간 제외)<br />",
              "인천 두바이 왕복 항공료, 5성 호텔 5박 (2인 1실 기준)<br />",
              "전용차량 및 가이드, 골프 라운딩 5회, 일정에 따른 관광 여행자 보험, 가이드 및 기사 팁 포함<br />",
            ],
          },
        ],
      },
    ],
    golf: [
      {
        id: 0,
        name: "두바이 크릭 GC",
        location: "두바이 구도심격인 데이라 지역, 두바이 공항에서 5분 거리",
        holeNumbers: "18H/ Par 71/ 6,967y",
        facility:
          "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 드라이빙레인지 등 일반 시설 외 식당가 및 요트클럽",
        summary:
          "PGA Desert Classic Tour 를 유치했었던 명문 챔피언쉽 골프장으로 두바이 해수로를 끼고 있어 경관이 훌륭합니다. 세계 최초의 골프아카데미가 시작된 곳으로도 유명하며, 클럽하우스가 시드니의 오페라 하우스 모형으로 지어져 골퍼들에게 이색적인 재미를 더합니다 ",
        images: [
          "dubai_creek/dubai_creek1.jpg",
          "dubai_creek/dubai_creek2.jpg",
          "dubai_creek/dubai_creek3.jpg",
          "dubai_creek/dubai_creek4.jpg",
          "dubai_creek/dubai_creek5.jpg",
          "dubai_creek/dubai_creek6.jpg",
          "dubai_creek/dubai_creek7.jpg",
          "dubai_creek/dubai_creek8.jpg",
        ],
      },
      {
        id: 1,
        name: "주메이라 골프 에스테이츠(The Earth Course)",
        location:
          "두바이 스포츠 시티 내에 위치, 실내스키장이 있는 에미레이트몰에서 20분 거리",
        holeNumbers: "18H/ Par 71/ 7,705y",
        facility:
          "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 드라이빙레인지 등",
        summary:
          "Race to Dubai 의 정점을 찍는 DP World Tour Championship Course로 각 티마다 다양한 각도로 변화하는 자연을 즐기실 수 있습니다.",
        images: [
          "estates/estates1.jpg",
          "estates/estates2.jpg",
          "estates/estates3.jpg",
          "estates/estates4.jpg",
          "estates/estates5.jpg",
          "estates/estates6.jpg",
          "estates/estates7.jpg",
          "estates/estates8.jpg",
        ],
      },
      {
        id: 2,
        name: "몽고메리 GC",
        location:
          "두바이 힐스 지역, 실내스키장이 있는 에미레이트몰에서 20분 거리",
        holeNumbers: "18H/ Par 72/ 7,446y",
        facility:
          "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 드라이빙레인지 등",
        summary:
          "Colin Montgomerie와 Desmond Muirhead 가 공동 디자인한 골프장 14개의 레이크, 72개의 벙커를 갖추고 있습니다.",
        images: [
          "mogo/mogo1.jpg",
          "mogo/mogo2.jpg",
          "mogo/mogo3.jpg",
          "mogo/mogo4.jpg",
          "mogo/mogo5.jpg",
          "mogo/mogo6.jpg",
          "mogo/mogo7.jpg",
          "mogo/mogo8.jpg",
        ],
      },
      {
        id: 3,
        name: "에미레이츠 GC(Faldo Course)",
        location:
          "교민이 많이 거주하는 알바샤 하이츠 인근, 실내스키장이 있는 에미레이트몰에서 15분 거리",
        holeNumbers: "18H/ Par 72/ 7,348y",
        facility:
          "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 드라이빙레인지 등",
        summary:
          "매년 European PGA Desert Classic Tour가 개최되는 곳으로 타이거우즈 등 세계적인 골퍼들의 라운딩으로 유명해진 곳입니다. 베두인 캠프를 재현해 놓은 것 같은 클럽하우스가 사막에서의 라운딩을 한층 색다르게 합니다.",
        images: [
          "emiraz/emiraz1.jpg",
          "emiraz/emiraz2.jpg",
          "emiraz/emiraz3.jpg",
          "emiraz/emiraz4.jpg",
          "emiraz/emiraz5.jpg",
          "emiraz/emiraz6.jpg",
          "emiraz/emiraz7.jpg",
          "emiraz/emiraz8.jpg",
        ],
      },
      {
        id: 4,
        name: "두바이 힐스 GC",
        location:
          "두바이 신흥 주거 단지인 두바이 힐스 에스테이츠 내에 위치, 에미레이트몰에서 15분 거리",
        holeNumbers: "18H/ Par 72/ 7,220y",
        facility:
          "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 드라이빙레인지 등",
        summary:
          "사막 자연 지형을 활용해 디자인된 골프장으로 넓은 페어웨이, 뜻밖의 호수, 전략적 벙커링 등은 모든 수준의 골퍼들에게 다양한 전략적 옵션을 제공합니다. 두바이 고층빌딩들의 스카이라인을 한눈에 감상하실 수 있기도 합니다.",
        images: [
          "dubai_hills/dubai_hills1.jpg",
          "dubai_hills/dubai_hills2.jpg",
          "dubai_hills/dubai_hills3.jpg",
          "dubai_hills/dubai_hills4.jpg",
          "dubai_hills/dubai_hills5.jpg",
          "dubai_hills/dubai_hills6.jpg",
          "dubai_hills/dubai_hills7.jpg",
          "dubai_hills/dubai_hills8.jpg",
        ],
      },
      {
        id: 5,
        name: "아부다비 GC",
        location: "아부다비 공항 인근, 두바이 도심에서 1시간 30분 거리",
        holeNumbers: "18H/ Par 72/ 7,333y",
        facility:
          "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 드라이빙레인지 등",
        summary:
          "Peter Harradine 설계로 2000년 개장, 2006년부터 매년 HSBC Championship 개최 7개 호수와 90개 벙커를 갖춘 162 헥터 규모입니다.",
        images: [
          "abudhabi/abudhabi1.jpg",
          "abudhabi/abudhabi2.jpg",
          "abudhabi/abudhabi3.jpg",
          "abudhabi/abudhabi4.jpg",
          "abudhabi/abudhabi5.jpg",
          "abudhabi/abudhabi6.jpg",
          "abudhabi/abudhabi7.jpg",
          "abudhabi/abudhabi8.jpg",
        ],
      },
      // {
      //   id: 6,
      //   name: "아부다비 야스 링크 GC",
      //   location:
      //     "아부다비 야스 아일랜드 내에 위치, 두바이 도심에서 1시간 30분 거리",
      //   holeNumbers: "18H/ Par 72/ 7,413y",
      //   facility:
      //     "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 드라이빙레인지 등",
      //   summary:
      //     "중동 최초의 링크스 코스이긴 하지만 기존 환경을 골프 감각에 맞춰 결합시킨 형태의 개발로 더 풍요롭고 인상적인 경험을 제공합니다. 세계 골프장 랭킹 48위에 올라 있으며 2022년 Abu Dhabi HSBC Championship을 유치하기도 했습니다.",
      //   images: ["phoenix/phoenix1.jpg", "phoenix/phoenix2.jpg"],
      // },
      // {
      //   id: 7,
      //   name: "알가잘 GC",
      //   location: "아부다비 공항 인근, 두바이 도심에서 1시간 30분 거리",
      //   holeNumbers: "18H/ Par 71/ 6,711y",
      //   facility:
      //     "프로샵, 클럽하우스, 바, 라운지, 락커, 샤워룸, 드라이빙레인지 등",
      //   summary:
      //     "중동 특유의 전통 사막골프장입니다.  사막 위에 페어웨이를 구분해 만들어 놓았고 그린은 브라운으로 대체되어 있습니다. 고급스러움과는 거리가 있어도 사막 골프의 독특함을 체험해 보실 수 있습니다.",
      //   images: ["phoenix/phoenix1.jpg", "phoenix/phoenix2.jpg"],
      // },
    ],
    hotel: [
      {
        id: 0,
        mainTitle: "힐튼 두바이 알 합투르 시티",
        localeTitle: "Hilton Dubai Al Habtoor City class",
        subTitle: "",
        summary:
          "두바이 시내와 불과 2Km 떨어진 아름다운 두바이 운하가 보이는 강변에 위치해 있는 세계적인 호텔 체인인 힐튼 호텔의 5성 호텔로서, 세계에서 가장 높은 빌딩인 부르즈 칼리파(Burj Khalifa) ,세계에서 가장 큰 몰인 두바이 몰(Dubai Mall), 세계에서 가장 큰 액자인 두바이 프레임과 차로 불과 몇 분 거리에 있습니다. 멋진 야경은 보너스랍니다.",
        mainImage: "hilton/hilton_main.jpg",
        images: [
          "hilton/hilton1.jpg",
          "hilton/hilton2.jpg",
          "hilton/hilton3.jpg",
          "hilton/hilton4.jpg",
        ],
      },
      {
        id: 1,
        mainTitle: "보코 두바이",
        localeTitle: "Voco Dubai",
        subTitle: "",
        summary:
          "세계적인 호텔 체인중에 하나인 IHG HOTELS&RESORTS 중 하나인 VOCO Dubai Hotel 은 471개의 모든 객실은 최근에 리모델링 되어 깨끗하고 편안한 숙박을 위한 최적의 조건으로 업그레이드 되었다. 도시 중심부에 위치하여 접근성이 뛰어날뿐만 아니라 천장부터 바닥까지 내려오는 탁 트인 창문 덕분에 객실에서는 두바이의 탁 트인 전망을 즐기실 수 있다. 출장, 가족과의 즐거운 여행, 개인 업무 등 무엇을 하시든 넓은 회의실 또한 마련되어 있어. 업무와 여행을 병행 할 수도 있는 최적의 호텔이다.",
        mainImage: "boco/boco_main.jpg",
        images: [
          "boco/boco1.jpg",
          "boco/boco2.jpg",
          "boco/boco3.jpg",
          "boco/boco4.jpg",
        ],
      },
      {
        id: 2,
        mainTitle: "JW 메리어트 마르퀴스 호텔 두바이",
        localeTitle: "JW Marriott Marquis Hotel Dubai",
        subTitle: "",
        summary:
          "두바이 시내에서 즐기는 5성급 호텔의 여유로움- 세계에서 가장 높은 5성 호텔인 JW 메리어트 마르퀴스 호텔 두바이는 현대적인 럭셔리함과 12개 이상의 수상 경력에 빛나는 서비스를 제공하는 도시의 도심 지역 중심부로 당신의 시선을 끌어 모은다. 셰이크 자이드 로드(Sheikh Zayed Road) 위에 우뚝 솟은 5성급 호텔은 세계에서 가장 높은 호텔 중 하나이며 물과 두바이 도시 경관의 경외심을 불러일으키는 전망을 자랑한다. 정통 태국요리,현대식 인도요리, 품격있는 이탈리아 요리, 최고급 스테이크는 또 다른 맛의 럭셔리함을 제공할 것이다.",
        mainImage: "jw/jw_main.jpg",
        images: ["jw/jw1.jpg", "jw/jw2.jpg", "jw/jw3.jpg", "jw/jw4.jpg"],
      },
      {
        id: 3,
        mainTitle: "풀만 두바이 크릭 시티 센터",
        localeTitle: "Pullman Dubai Creek City Centre",
        subTitle: "",
        summary:
          "풀만 두바이 크릭 시티 센터(Pullman Dubai Creek City Centre)는 유서 깊은 두바이 크릭(Dubai Creek)과 두바이 골프 및 요트 클럽(Dubai Golf and Yacht Club)의 완만한 녹색 페어웨이를 내려다보고 있다. 두바이 국제공항 및 시티 센터 데이라 쇼핑몰과 가까운 뛰어난 위치 덕분에 이 고급스러운 5성급 호텔의 많은 자산 중 하나인 도시의 본질을 포착할 수 있다. 두바이 지하철역에서 몇 분 거리에 있어 두바이를 더 쉽게 탐험하고 부르즈 칼리파, 두바이 프레임, 두바이 수로, 두바이 마리나, 팜 주메이라 등 세계적으로 유명한 건축물이 가까이에 있어, 쉽게 방문이 가능한 탁월한 접근성을 자랑한다.",
        mainImage: "creek/creek_main.jpg",
        images: [
          "creek/creek1.jpg",
          "creek/creek2.jpg",
          "creek/creek3.jpg",
          "creek/creek4.jpg",
        ],
      },
      {
        id: 4,
        mainTitle: "미디어 로타나",
        localeTitle: "Media Rotana",
        subTitle: "",
        summary:
          "Barsha Heights의 번화한 구역에 위치한 Media Rotana 는 두바이의 고급 5성급 호텔 로, 두바이 인터넷 시티 및 두바이 미디어 시티를 포함한 주요 비즈니스 지구뿐만 아니라 많은 흥미진진한 레저 명소와 쇼핑몰과 가깝다. 두바이 인터넷 시티 지하철역은 5성급 호텔에서 가까운 도보 거리에 있으며  두바이 국제공항 과 알 막툼 국제 공항, 에미리트의 두 공항은 Media Rotana에서 차로 단 20분 거리에 위치에 있다.  537개의 넓고 현대적인 5성급 호텔 객실과 편리한 편의 시설은 안목 있는 고객에게 고급스러운 혜택과 최고의 맞춤형 환대 서비스를 제공하여 두바이에서 진정으로 기억에 남는 호텔로 기억될 것이다.",
        mainImage: "media/media_main.jpg",
        images: [
          "media/media1.jpg",
          "media/media2.jpg",
          "media/media3.jpg",
          "media/media4.jpg",
        ],
      },
    ],
    enjoy: [
      {
        id: 0,
        title: "버즈 칼리파 (Burj Khalifa)",
        subTitle:
          "버즈 칼리파는 글로벌 랜드마크가 된 세계 최고층 빌딩입니다. 최고의 건축 기술로 완성된 이 건축물은 두바이의 심장이자 상징과 같습니다.",
        summary: `828m의 놀라운 건축물은 두바이 몰 옆에 위치하며, 2010년 개장 이후 전 세계의 수많은 분들이 방문하고 있습니다. 버즈 칼리파는 두 곳의 전망대에서 보이는 독보적인 전망을 자랑합니다. 124층 및 125층에 걸쳐진 앳 더 탑(At the Top) 전망대와 148층에 위치한 세계에서 가장 높은 전망대(555m)를 방문해 보세요. 온라인과 현장에서 두 전망대 방문 티켓을 구입하실 수 있습니다.<br />
        새롭고 특별한 경험을 원하신다면 122층에 위치한 앳모스피어(At.mosphere) 레스토랑에서 멋진 요리를 맛보거나, 152층에서 154층까지 이어지는 세계에서 가장 높은 더 라운지 버즈 칼리파(The Lounge, Burj Khalifa)에서 아찔한 높이를 느끼며 음료를 즐길수 있습니다.<br />
        거미 백합(스파이더 릴리)으로 불리는 히메노칼리스(Hymenocallis) 꽃잎을 형상화한 버즈 칼리파는 가운데를 중심으로 세 구역으로 나뉘어져 있습니다. 우뚝 솟은 이 타워를 하늘에서 내려다보면 이슬람 건축물에서 자주 보이는 양파 모양의 돔 형상을 하고 있습니다.<br />
        버즈 칼리파 주위에는 다채로운 엔터테인먼트 시설과 레스토랑이 밀집해 있습니다. 두바이 몰과 음악에 맞춰 춤추는 두바이 분수 등 여러 관광명소가 있어 타워 방문 후 쇼핑, 관광, 식사를 다양하게 즐기실 수 있습니다.<br />`,
        images: ["bujk/bujk1.jpg", "bujk/bujk2.jpg", "bujk/bujk3.jpg"],
      },
      {
        id: 1,
        title: "버즈 알 아랍 주메이라 (Burj Al-Arab Jumeirah)",
        subTitle:
          "세계 최초의 7성급 호텔인 버즈 알 아랍 주메이라는 현대적인 아라비아 럭셔리를 재정한 두바이의 랜드마크입니다.",
        summary: `해발 321m 높이의 버즈 알 아랍은 세계에서 가장 높은 올 스위트룸 호텔입니다. 이곳은 독특한 건축 디자인으로 두바이 스카이라인을 화려하게 장식하는 놀라운 랜드마크입니다. 아라비아 다우(dhow) 배의 역동적인 돛 모양에서 영감을 얻은 이 호텔은 두바이의 자랑스러운 해양 전통과 현대 건축의 진수를 보여줍니다. `,
        images: [
          "buja/buja1.jpg",
          "buja/buja2.jpg",
          "buja/buja3.jpg",
          "buja/buja4.jpg",
        ],
      },
      {
        id: 2,
        title: "앞으로 펼쳐질 미래를 보는 곳, 미래 박물관",
        subTitle:
          "두바이의 중심 도로, 셰이크 자이드 로드(Sheikh Zayed Road) 옆에 있는 미래 박물관(Museum of the Future)은 미래에 일어날 수 있는 기술 발전을 미리 알아보는 흥미로운 전시관이다.",
        summary: `미래 박물관의 또 다른 관람포인트는 독특한 건물 외관에 있다.<br>
          도넛 모양의 스테인리스 스틸로 설계된 건축 표면에는 아름다운 아랍 서예가 새겨져 있어 이국적인 분위기를 물씬 풍긴다.<br>
          이 때문에 미래 박물관은 완공 전부터 내셔널 지오 그래픽이 선정한 세계에서 가장 아름다운 박물관 14선에 선정되었다.`,
        images: [
          "future_musium/future_musium1.jpg",
          "future_musium/future_musium2.jpg",
          "future_musium/future_musium3.jpg",
        ],
      },
      {
        id: 3,
        title: "사막에서 베두인 체험하기",
        subTitle:
          "두바이 여행을 떠올렸을 때 빼놓을 수 없는 것이 바로 사막 체험이다.",
        summary: `두바이의 사막에서 아랍 에미레이트의 원주민 또는 국민을 지칭하는 에미라티(Emirati)의 전통을 살펴보고 사막 사파리를 즐길 수 있다.<br>
          사막 위를 자유로이 노니는 아라비아 오릭스, 사막 가젤 등 토종 야생동물들과 조우할 수도 있다.<br>
          아랍에미리트 전통 요리를 즐기고, 두바이 사막 보존 보호구역에서 하룻밤 캠핑을 통해 독보적이고 잊지 못할 나만의 아라비안 나이트를 그려보는 건 어떨까.<br>
          사막 사파리 상품은 상품 구성에 따라 가격이 상이한데 대략 6만~ 20만원대까지 다양하다.`,
        images: [
          "bedouin/bedouin1.jpg",
          "bedouin/bedouin2.jpg",
          "bedouin/bedouin3.jpg",
        ],
      },
      {
        id: 4,
        title: "두바이의 눈, 세계에서 가장 큰 관람차 아인 두바이(Ain Dubai)",
        subTitle:
          "작년 10월, 세계 최대 규모를 자랑하는 관람차이자 두바이의 떠오르는 명소가 된 아인 두바이(Ain Dubai)가 문을 열었다.",
        summary: `아인 두바이의 ‘아인(Ain)’은 아랍어로 사물을 보는 ‘눈’이라는 뜻이다.<br>
          최고 높이 250ｍ의 관람차는 약 38분에 걸쳐 한 바퀴를 도는데, 그 동안 캐빈에 탑승한 승객들은 버즈 알 아랍(Burj Al Arab), 팜 주메이라(Palm Jumeirah), 버즈 칼리파(Burj Khalifa) 등<br>
          두바이의 유명 랜드마크와 멋진 스카이라인을 360도 파노라마로 감상할 수 있다.<br>
          아인 두바이에서는 캐빈을 사전 예약하여 식사, 생일 파티, 결혼식, 프러포즈 등 개인 맞춤 이벤트도 진행할 수 있다.`,
        images: ["ain/ain1.jpg", "ain/ain2.jpg", "ain/ain3.jpg"],
      },
      {
        id: 5,
        title: "두바이 몰",
        subTitle:
          "세계에서 가장 큰 몰로 기록되어 있는 두바이몰은 버즈 칼리파 앞에서 펼쳐지는 분수쇼가 일품으로 볼거리와 세계의 명품 집합소라 할만큼 다양한 명품들로 가득하다.",
        summary: `까르띠에와 해리 윈스턴 같은 명품 매장으로도 유명하다.<br>
          또한 수족관, 아이스링크도 갖춰져 있으며 세계에서 가장 높은 건물 버즈 칼리파에서 360도로 바라보는 도시 전망으로도 유명하다.<br>
          세계 최대의 쇼핑, 엔터테인먼트, 레저 복합 공간인 두바이 몰은 세계 최고층 빌딩인 버즈 칼리파 옆에 위치해 있습니다.<br>
          1,200개 이상의 매장, 대형 백화점 2곳, 수백 개의 레스토랑과 카페가 있는 두바이 몰은 축구 경기장 200개를 합친 것과 같은 100만m2 이상의 초대형 규모를 자랑합니다.<br>
          중동 최초의 블루밍데일스 및 갤러리 라파예트 백화점과 70여 개 시그니처 리테일 브랜드에서 트렌디한 하이패션을 경험해 보세요.<br>
          오트쿠튀르 패션을 좋아하신다면 패션 애비뉴(Fashion Avenue)에서 베르사체, 버버리 등 세계적인 명품 브랜드의 최신 컬렉션을 만나실 수 있습니다.<br>
          최고급 골드 주얼리를 선보이는 골드 수크(Gold Souk)와 최신 스타일의 신발을 구매할 수 있는 레벨 슈즈(Level Shoes)도 둘러보세요.<br>
          쇼핑 외에도 두바이 몰에는 두바이 아쿠아리움 및 언더워터 주(The Dubai Aquarium and Underwater Zoo), 올림픽 경기장 규모의 두바이 아이스링크(Dubai Ice Rink), 어린이 '에듀테인먼트' 시설인 키자니아와 초대형 멀티플렉스 영화관이 있어 모든 분들이 하루 종일 다양하게 즐길 수 있습니다.<br>
          실내 분수 (두바이 몰 최고의 사진 명소)와 겨울에 사각형의 천장을 개방하여 야외 분위기를 연출하는 더 빌리지(The Village)도 놓치지 마세요.<br>
          두바이 몰에는 5성 호텔인 어드레스 두바이 몰(Address Dubai Mall)이 있어 쇼핑 후에 호텔에서 편안한 휴식을 즐기실 수 있습니다.`,
        images: [
          "dubai_mall/dubai_mall1.jpg",
          "dubai_mall/dubai_mall2.jpg",
          "dubai_mall/dubai_mall3.jpg",
          "dubai_mall/dubai_mall4.jpg",
        ],
      },
      {
        id: 6,
        title: "팜주메이라",
        subTitle: `두바이는 세계 최초의 혁신적인 프로젝트로 유명하며, 대표적인 프로젝트가 바로 팜 주메이라입니다.<br>
        바다를 매립해 조성한 인공섬을 하늘에서 내려다보면 여러 섬이 야자수 모양을 이루고 있습니다.`,
        summary: `팜 주메이라에는 아틀란티스 더 팜, 주메이라 자빌 사라이(Jumeirah Zabeel Saray), 원&온리 더 팜(One&Only The Palm)을 포함해 두바이 최고의 럭셔리 리조트가 모여 있습니다.
          <br>리조트에 투숙하지 않더라도 노부(Nobu), 101 다이닝 라운지(101 Dining Lounge) 등 팜 주메이라에 위치한 최고의 레스토랑에서 멋진 식사를 즐기실 수 있습니다.<br>
          팜 주메이라는 도로와 터널은 물론이고 대중교통이 잘 갖춰져 있어 섬의 다양한 호텔, 레스토랑과 관광명소로 편리하게 이동하실 수 있습니다.<br>
          팜 주메이라를 대표하는 아틀란티스 더 팜 리조트에 갈 때는 팜 모노레일(Palm Monorail)을 이용해 보세요.<br>
          바다에서 요트나 스피드보트를 타고 팜 주메이라를 둘러보실 수 있으며, 다양한 개인 투어 업체에서 인기 있는 세일링 투어 프로그램을 운영하고 있습니다.`,
        images: ["palm/palm1.jpg", "palm/palm2.jpg", "palm/palm3.jpg"],
      },
    ],
  },
};

export const testmap = [
  {
    philippines: { title: "philippines" },
    japan: { title: "japan" },
    thailand: { title: "thailand" },
  },
];

const noticeList = [
  {
    id: 3,
    title: "[필독] 상품견적 문의 관련건 33",
    author: "은솔여행사",
    date: "14.07.02",
    viewCount: "0",
    isNew: true,

    badge: "공지",
    text: "편안하고 행복한 골프여행! 골프고고!, 은솔 여행사에 오신 여러분을환영합니다.<br /><br />다름이 아니라 필리핀 현지에서 허가 없이 활동 하는 가이드들이<br />은솔 여행사 카페에 들어와 견적 요청 하시는 고객님께 쪽지로 호객행위를 하고 있습니다..<br />당분간 은솔 여행사는 메일과 카톡 전화 문의만 받도록 하겠습니다<br />번거롭게 해드려 죄송 합니다<br /><br /><br />clarkgogo@naver.com<br />dretry03@naver.co</<p className='center'><img src='/assets/images/package/package_2_2.jpg' /> </p><p>- 연락처 : 현지)0917-648-2222 / 한국)02-6223-9415<br />- 메신저 : 카카오톡ID)clarkgogo(필리핀폰),clarkgo(한국폰) / 이메일)ohgod0429@naver.com<br /><br />감사합니다.<br />-은솔여행사",
  },
  {
    id: 2,
    title: "[필독] 상품견적 문의 관련건 111112211111",
    author: "은솔여행사",
    date: "14.07.02",
    viewCount: "0",
    isNew: true,

    badge: "공지",
    text: "편안하고 행복한 골프여행! 골프고고!, 은솔 여행사에 오신 여러분을환영합니다.<br /><br />다름이 아니라 필리핀 현지에서 허가 없이 활동 하는 가이드들이<br />은솔 여행사 카페에 들어와 견적 요청 하시는 고객님께 쪽지로 호객행위를 하고 있습니다..<br />당분간 은솔 여행사는 메일과 카톡 전화 문의만 받도록 하겠습니다<br />번거롭게 해드려 죄송 합니다<br /><br /><br />clarkgogo@naver.com<br />dretry03@naver.co</<p className='center'><img src='/assets/images/package/package_2_2.jpg' /> </p><p>- 연락처 : 현지)0917-648-2222 / 한국)02-6223-9415<br />- 메신저 : 카카오톡ID)clarkgogo(필리핀폰),clarkgo(한국폰) / 이메일)ohgod0429@naver.com<br /><br />감사합니다.<br />-은솔여행사",
  },
  {
    id: 1,
    title: "[필독] 상품견적 문의 관련건 11",
    author: "은솔여행사",
    date: "14.07.02",
    viewCount: "0",
    isNew: false,

    badge: "공지",
    text: "편안하고 행복한 골프여행! 골프고고!, 은솔 여행사에 오신 여러분을환영합니다.<br /><br />다름이 아니라 필리핀 현지에서 허가 없이 활동 하는 가이드들이<br />은솔 여행사 카페에 들어와 견적 요청 하시는 고객님께 쪽지로 호객행위를 하고 있습니다..<br />당분간 은솔 여행사는 메일과 카톡 전화 문의만 받도록 하겠습니다<br />번거롭게 해드려 죄송 합니다<br /><br /><br />clarkgogo@naver.com<br />dretry03@naver.com<p className='center'><img src='/assets/images/package/package_2_2.jpg'/> </p><p>- 연락처 : 현지)0917-648-2222 / 한국)02-6223-9415<br />- 메신저 : 카카오톡ID)clarkgogo(필리핀폰),clarkgo(한국폰) / 이메일)ohgod0429@naver.com<br /><br />감사합니다.<br />-은솔여행사",
  },
  {
    id: 0,
    title: "[필독] 상품견적 문의 관련건 00",
    author: "은솔여행사",
    date: "14.07.02",
    viewCount: "0",
    isNew: false,

    badge: "공지",
    text: "편안하고 행복한 골프여행! 골프고고!, 은솔 여행사에 오신 여러분을환영합니다.<br /><br />다름이 아니라 필리핀 현지에서 허가 없이 활동 하는 가이드들이<br />은솔 여행사 카페에 들어와 견적 요청 하시는 고객님께 쪽지로 호객행위를 하고 있습니다..<br />당분간 은솔 여행사는 메일과 카톡 전화 문의만 받도록 하겠습니다<br />번거롭게 해드려 죄송 합니다<br /><br /><br />clarkgogo@naver.com<br />dretry03@naver.co</<p className='center'><img src=<img src='/assets/images/package/package_2_2.jpg' /> </p><p>- 연락처 : 현지)0917-648-2222 / 한국)02-6223-9415<br />- 메신저 : 카카오톡ID)clarkgogo(필리핀폰),clarkgo(한국폰) / 이메일)ohgod0429@naver.com<br /><br />감사합니다.<br />-은솔여행사",
  },
];

export const getCountry = () => {
  return countryMap;
};

export const getNotice = () => {
  return noticeList;
};

export const topNavMenuList = [
  { id: 1, name: "재단소개", path: "/intro" },
  { id: 2, name: "기부금출연&장학금지급 활동", path: "/sponsor" },
  { id: 3, name: "후원안내", path: "/guide" },
  { id: 4, name: "공지사항", path: "/notice" },
];

export const dropMenuList = [
  {
    id: 1,
    title: "재경군산중고장학회",
    subTitle: "재단소개",
    subMenu: [
      { name: "재단소개", path: "/intro" },
      { name: "이사장인사말", path: "/intro" },
      { name: "연혁", path: "/history" },
      { name: "임원명단", path: "/member" },
      { name: "운영정관", path: "/operation" },
      { name: "오시는길", path: "/direction" },
    ],
  },
  {
    id: 2,
    title: "재경군산중고장학회",
    subTitle: "기부금출연 활동 내역",
    subMenu: [
      { name: "기부금출연 내역", path: "/sponsor" },
      { name: "기부금출연 내역", path: "/sponsor" },
      { name: "장학금지급 내역", path: "/donation" },
    ],
  },
];
