import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";

const QuickMenu = () => {
  const [qmenuList, setQmenuList] = useState([
    {
      url: "/intro",
      imgSrc: "../assets/images/sub/quickIco08_n.png",
      urlText: "이사장 인사말",
      active: "quick-list-on",
    },
    {
      url: "/history",
      imgSrc: "../assets/images/sub/quickIco10_n.png",
      urlText: "연혁",
      active: "",
    },
    {
      url: "/member",
      imgSrc: "../assets/images/sub/quickIco04_n.png",
      urlText: "임원명단",
      active: "",
    },
    {
      url: "/operation",
      imgSrc: "../assets/images/sub/quickIco07_n.png",
      urlText: "운영정관",
      active: "",
    },
    {
      url: "/direction",
      imgSrc: "../assets/images/sub/quickIco09_n.png",
      urlText: "오시는 길",
      active: "",
    },
  ]);

  const location = useLocation();

  useEffect(() => {
    const copyArr = [...qmenuList];

    copyArr.map((item) => {
      if (item.url == location.pathname) {
        item.active = "quick-list-on";
      } else {
        item.active = "";
      }
    });

    setQmenuList(copyArr);
  }, [location.pathname]);

  return (
    <div className="subQTline">
      <ul className="quickMenu">
        {qmenuList.map((item, index) => (
          <li key={index} className={item.active}>
            <Link to={item.url}>
              <div className="ico">
                <img src={item.imgSrc} />
              </div>
              <div className="text">{item.urlText}</div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuickMenu;
