import React from "react";
import Slick from "react-slick";
import { Link } from "react-router-dom";
import { mainJs } from "@/assets/js/common";

const MainPromotionSlider = () => {
  return (
    <div className="promotion">
      <div className="thum">
        {/* <img src="assets/images/main/members.png" alt="" /> */}
      </div>
      <div className="textArea">
        <div className="subj">재경군산중고장학회 연혁</div>
        <div className="subs">
          <Link to="/history">
            <button className="btn">바로가기</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainPromotionSlider;
