import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { cls } from "@/helpers/helper";
import { useRecoilValue } from "recoil";
import { userInfoState } from "@/recoil/userStore";
import useAxiosFunction from "@/hooks/useAxios";
import moment from "moment";
import "./notice.css";

function NoticeList() {
  const userInfo = useRecoilValue(userInfoState);
  const [noticeResult, setNoticeResult] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const { apiUrls, axiosFetch } = useAxiosFunction();

  const getNoticeInfo = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.getNoticeList,
      requestConfig: { currentNavPage: reqData },
    })
      .then((res) => {
        if (res.success) {
          setNoticeResult(res.result);
          setNoticeList(res.noticeList);
          setPageList(res.pageList);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    getNoticeInfo(null);
  }, []);

  return (
    <>
      <div className="total">
        <span>총 {noticeResult.totalrows}건</span>
        {userInfo.userInfo && (
          <Link to={`/notice/write`}>
            <button className="write-btn">작성하기</button>
          </Link>
        )}
      </div>

      <ul className="boardList">
        <li className="head">
          <div className="num">No.</div>
          <div className="subj">제목</div>
          <div className="writer">작성자</div>
          <div className="date">작성일</div>
        </li>
        {noticeList?.map((item, index) => (
          <li key={index}>
            <Link to={`/notice/detail/${item.board_id}`}>
              <div className="num">{item.board_id}</div>
              <div className="subj">
                <div className="subject">{item.title}</div>
                {item.isNew ? (
                  <div className="new">
                    <img src="../../assets/images/sub/newIco.png" />
                  </div>
                ) : null}
              </div>
              <div className="writer">관리자</div>
              <div className="date">
                {moment(item.board_date).format("YYYY/MM/DD")}
              </div>
            </Link>
          </li>
        ))}
      </ul>

      <ul className="paging">
        <li>
          <a
            onClick={() =>
              getNoticeInfo(
                noticeResult.currentPage - 10 < 1
                  ? 1
                  : noticeResult.currentPage - 10,
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="../../assets/images/sub/pagingPrev.png" />
          </a>
        </li>
        {pageList?.map((item, index) => (
          <li
            key={index}
            className={item == noticeResult.currentPage ? "on" : null}
          >
            <a
              onClick={() => getNoticeInfo(item)}
              style={{ cursor: "pointer" }}
            >
              {item}
            </a>
          </li>
        ))}

        <li>
          <a
            onClick={() =>
              getNoticeInfo(
                pageList[pageList.length - 1] + 1 < noticeResult.maxPage
                  ? pageList[pageList.length - 1] + 1
                  : pageList[pageList.length - 1],
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img src="../../assets/images/sub/pagingNext.png" />
          </a>
        </li>
      </ul>
    </>
  );
}

export default NoticeList;
